const storeList = `
Aadorf
Aarau City
Aarau Telli
Aarberg 
Adligenswil
Aesch
Affoltern am Albis
Airolo
Allaman 
Allschwil Letten Center
Altdorf
Amriswil
Appenzell
Arbon Novaseta
Arosa Valsana
Avenches Milavy
Baar Gotthard
Bachenbülach Provisorium
Bad Ragaz
Baden Bahnhof
Baden City 
Balsthal
Basel Aeschenplatz
Basel Bachletten
Basel Bäumlihof
Basel City Am Marktplatz 
Basel City Pfauen 
Basel Elsässerstrasse
Basel Erlenmatt
Basel Europe
Basel Gundeli
Basel Klybeck
Basel Spalemärt
Basel SüdPark
Basel Volta Zentrum
Bassecourt
Bassersdorf
Bätterkinden
Bellach
Bellinzona Stazione
Bellmund
Belp
Berikon
Bern Bahnhof SBB
Bern Bethlehem
Bern Breitenrain
Bern Bümpliz
Bern City Ryfflihof
Bern Europaplatz
Bern Freudenberg
Bern Länggasse
Bern Marktgasse
Bern Postparc
Bern Schauplatzgasse
Bern Schönburg
Bern Wankdorf
Biasca
Biberist
Biel Bahnhof
Biel Boujean
Biel Bözingen
Biel Mett
Biel Nidaugasse
Biel/Bienne City 
Biglen
Bioggio
Birsfelden
Bischofszell
Bolligen
Bottmingen
Bremgarten
Brienz
Brig
Brig Postzentrum
Brunnen
Bubendorf
Buchs SG
Bülach im Guss
Bulle Le Câro
Buochs
Büren
Burgdorf Bahnhof
Burgdorf Schützenmatte
Burgdorf Stadt
Canobbio Ipermercato Resega
Carouge Acacias
Carouge Mermillod
Castione
Cham Seehof
Château-d'Oex
Châtel Saint-Denis
Chaux-de-Fonds Entilles
Chaux-de-Fonds Eplatures
Chêne Bougeries Montagne
Chur City
Chur Kurfirsten
Chur Quader
Chur Steinbock
Chur Tittwiesen
Chur West
Collombey 
Colombier
Conthey Bassin
Couvet
Crissier
Dagmersellen
Davos
Deisswil
Delémont Le Ticle Neu
Derendingen
Dielsdorf
Dietikon Silbern
Dietlikon Center
Disentis
Domat-Ems
Dornach
Dübendorf Center
Düdingen
Dulliken
Ecublens
Egerkingen Gäupark
Egg
Einsiedeln Wissmüli Markt
Emmen
Emmenbrücke Sprengi
Epalinges
Eyholz Center
Feldmeilen
Feuerthalen Rhymarkt
Flamatt
Flims Stenna
Fraubrunnen
Frauenfeld Allmendcenter
Frauenfeld Fallengatter
Frauenfeld Schlosspark
Frenkendorf
Fribourg City 
Frick
Frutigen
Fully
Gelterkinden
Genève Augustins
Genève Champel
Genève City Fusterie 
Genève City Plainpalais 
Genève Eaux-Vives
Genève Florissant
Genève Montbrillant
Genève Servette
Giffers
Giubiasco
Glattfelden
Gossau SG Gerbhof
Grand-Lancy La Praille
Granges-Paccot Agy
Grenchen Zentrum
Grindelwald Eigershop
Grosshöchstetten 
Gstaad
Gümligen Moos
Güttingen
Haag
Hägendorf
Hasle-Rüegsau
Heerbrugg
Heiden
Heimberg Aarestrasse
Heimberg Center
Herisau
Herzogenbuchsee
Hinwil Center
Hitzkirch
Hochdorf
Horgen Waldegg
Huttwil Neu
Ilanz
Ins
Interlaken Bahnhof
Interlaken Ost
Interlaken Post
Ittigen
Jegenstorf
Jona Eisenhof
Kaiseraugst
Kerzers
Kirchberg
Kleinandelfingen
Kleindöttingen
Klosters Platz
Köniz Stapfenmärit
Köniz Steinhölzli
Konolfingen
Kreuzlingen Karussell
Kriegstetten
Kriens City 
Kriens Pilatusmarkt
Kriens Schappe
Küsnacht Dorf
Küssnacht am Rigi
La Tour-de-Peilz
Lachen
Landquart
Langenthal Bäregg
Langenthal Tell
Langnau Ilfiscenter
Laufen
Laupen 
Lausanne  Prélaz
Lausanne Caroline
Lausanne City Au Centre 
Lausanne City St.François
Lausanne Grancy
Lenzburg
Liestal Bücheli Center
Liestal Stabhof
Locarno Piazza Grande
Losone Ipermercato Cattori
Lugano City
Lugano Nord
Lugano Sud
Lugano Viganello
Lupfig Seebli Center
Lutry Corniche
Lutry les Moulins
Luzern City
Luzern Löwencenter
Lyss Stigli
Magden
Maggia
Malters
Marly
Martigny Cristal
Martigny Grand Saint-Bernard
Martigny Ville
Matran Centre
Meilen
Meisterschwanden
Mellingen
Mels Pizolcenter
Mendrisio
Meyrin City 
Möhlin
Montagny-près-Yverdon
Montreux
Mörel
Morges Charpentiers
Morges La Gottaz
Moudon
Moutier
Münchenbuchsee
Münchenstein Gartenstadt
Münchwilen
Münsingen
Münster
Muri
Muri AG
Murten Löwenberg 
Muttenz Dorf
Muttenz Freidorf
Netstal Wiggispark
Neuchâtel City
Neuchâtel Maladière
Neunkirch
Nussbaumen
Oberriet
Oberwil Bahnhofstrasse
Oberwil Mühlematt
Oensingen
Oftringen Perry Center 
Olten City 
Olten Sälihof
Olten Wilerfeld
Onex
Orbe
Oron-la-Ville
Ostermundigen Bahnhofstrasse
Payerne
Penthalaz Village Neu
Peseux Parc du Château
Pfäffikon SZ Seedamm
Pfäffikon ZH
Plaffeien
Plan les Ouates Tourbillon
Porrentruy Ajoie
Poschiavo
Pratteln Bahnhof
Prilly Centre
Reconvilier
Regensdorf Zentrum
Reiden
Reinach AG
Reinach BL Dorf
Renens Centre
Rennaz Prov
Rheinfelden Salmen Center
Richterswil Obermatt
Rickenbach
Riehen Rauracher
Riehen Schmiedgasse
Riggisberg
Romanshorn
Romont
Rorschach
Rotkreuz
Rümlang Riedmatt
Rüti
S. Antonino
Saas-Fee Postplatz
Saignelégier Rangiers
Samedan
Sarnen
Savièse
Schaan
Schaffhausen City
Schaffhausen Fronwagplatz
Schattdorf Tellpark
Schenkon
Schlieren Lilie
Schlieren Rietpark
Schmitten
Schönbühl Center
Schönenwerd
Schüpfheim
Schwarzenburg 
Scuol
Seewen Seewenmarkt
Sempach
Sentier Le
Serfontana
Siebnen
Sierre Rossfeld
Signy
Sion City Place du Midi 
Sissach
Solothurn Bahnhof
Solothurn Westring
Spiez
St. Gallen Bahnhof
St. Gallen City
St. Gallen Gallus Markt 
St. Gallen Lerchenfeld
St. Gallen Shopping Arena
St. Moritz Bellevue
Stäfa
Stans
St-Aubin
Steckborn
Steffisburg
Stein AG
Stein am Rhein 
Steinhausen
St-Imier
St-Prex
Sursee Surseepark
Tann-Dürnten
Tenero
Thalwil
Thônex
Thun City Kyburg 
Thun Rexmax
Thun Strättligen Markt
Thusis
Toffen
Uetendorf
Uettligen
Unterägeri Chilematt
Unterentfelden
Unterkulm
Unterseen
Uster Winterthurerstrasse
Utzenstorf
Uvrier
Uzwil
Vaduz Mühleholz
Vernier Blandonnet Neu
Versoix
Vésenaz
Vevey Rue du Simplon
Vich
Villars-sur-Glâne
Visp Centerpark
Volketswil City 
Volketswil Volkiland
Wabern
Wädenswil Zugerstrasse
Wallisellen Bahnhofplatz
Wattenwil
Wattwil
Weinfelden Thurmarkt 
Wettingen Tägipark
Wetzikon
Wil City 
Wil Stadtmarkt
Willisau
Winterthur City 
Winterthur Grüze Markt
Winterthur Lokwerk
Winterthur Oberi
Winterthur Stadtgarten
Winterthur Stadttor
Winterthur Wülflingen
Wohlen
Worb
Worblaufen
Würenlingen
Yverdon Bel-Air
Zermatt
Zizers
Zofingen
Zollikofen
Zuchwil
Zug City 
Zug Neustadt
Zumikon Morgental
Zürich Albisrieden A-Park
Zürich Altstetten
Zürich Bahnhofbrücke
Zürich City Bellevue 
Zürich City Oerlikon 
Zürich City Sihlcity 
Zürich City St. Annahof 
Zürich Eleven
Zürich Hönggermarkt
Zürich Kreuzplatz
Zürich Letzipark
Zürich Lochergut
Zürich Precisa
Zürich Scheffelstrasse
Zürich Schwamendingerhaus
Zürich Seefeld
Zürich Sihlcity 
Zürich Stauffacher
Zürich Wiedikon
Zürich Wollishofen Kalchbühl
Zweisimmen
`;

export const STORE_OPTIONS = storeList.trim().split("\n");
