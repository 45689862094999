import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import aspLogo from "../../assets/images/aspLogo.png";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.css";
import NavBar from "../NavBar";
import LanguageBar from "../../Shared/LanguageBar";
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogin: null,
    };
  }

  componentDidMount() {
    const isLogin = document.querySelector(".App-main .loginScreen") !== null;

    this.setState({
      isLogin,
    });
  }

  render() {
    return (
      <div className="_header">
        <AppBar position="sticky" elevation={0}>
          <Toolbar></Toolbar>
        </AppBar>
        <AppBar position="fixed" elevation={2}>
          <div className="navbar_wrapper">
            <Backdrop
              className="loadingBackdrop"
              open={this.props.state.loading ? true : false}
            >
              {window.location.pathname.includes("/fr") ? (
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Nous traitons vos données.
                  <br />
                  Veuillez patienter un instant.
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Elaboriamo i vostri dati.
                  <br />
                  Vi prego di avere pazienza.
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  We are processing your data.
                  <br />
                  Please wait a moment.
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Wir verarbeiten Ihre Daten.
                  <br />
                  Bitte haben Sie einen Moment Geduld.
                </Typography>
              )}
            </Backdrop>
            <Toolbar className="_navbar">
              <img
                src={aspLogo}
                className="aspLogoNav"
                width="54px"
                alt="Logo"
              />

              <div>
                {this.state.isLogin && <LanguageBar color="#fff" />}
                {this.props.state.page !== "Login" &&
                this.props.state.page !== "Connexion" ? (
                  <NavBar
                    state={this.props.state}
                    toMainScreen={this.props.toMainScreen}
                  />
                ) : null}
              </div>
            </Toolbar>
          </div>
        </AppBar>
      </div>
    );
  }
}

export default Header;
