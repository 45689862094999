/* Attention Code Duplication: 
This code is an adjusted version of the "Startort" Component. 
It was copied over from Abesians commit f3a7f7 (latest commit on master branch) and adjusted by Jonas Wolter.
However this code (like the other dropdown components) is not clean and contains unused code and might be prone to bugs. 
(Other duplicated dropdown components are "Zielort" and "NewConnectingPoint")
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import "./styles.css";
import { STORE_OPTIONS } from "./storeOptions";
import fuzzysort from 'fuzzysort'

class StoreDropdown extends Component {
  constructor(props) {
    super(props);

    let screenWidth = window.screen.width ? window.screen.width : null;
    this.containerRef = React.createRef();

    this.state = {
      dialog: window.localStorage.dialogStartort,
      stations: [],
      tripsData: [],
      uniqueFavs: null,
      unknownStation: true,
      searchField: "",
      selectedName: null,
      code: null,
      display: "hide",
      lat: "",
      lon: "",
      nearbyStation: "",
      popupDisplay: false,
      screenWidth,
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  componentWillUnmount() {
    this.setState({ code: null, selectedName: null });
    document.removeEventListener("click", this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    setTimeout(() => {
      this.keyNavigation();
      this.keyEnter();
    }, 500);
    if (this.state.screenWidth < 620 && window.localStorage.firstTimeLogin && !window.localStorage.startortDialog) {
      this.togglePopup();
    }
  }

  keyEnter = () => {
    document.addEventListener("keydown", function (event) {
      console.log("🚀 ~ Main ~ event:", event);
      if (event.key === "Enter") {
        const selectedItem =
          document.getElementsByClassName("selected")[0] || document.querySelector(".searchResults li");
        if (selectedItem) selectedItem.click();
      }
    });
  };

  keyNavigation = () => {
    var ul = document.getElementById("list");
    var liSelected;
    var index = -1;
    var next;

    document.addEventListener(
      "keydown",
      function (event) {
        var len = ul.getElementsByTagName("li").length - 1;
        if (event.key === "ArrowDown") {
          index++;
          //down
          if (liSelected) {
            removeClass(liSelected, "selected");
            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index <= len) {
              liSelected = next;
            } else {
              index = 0;
              liSelected = ul.getElementsByTagName("li")[0];
            }
            addClass(liSelected, "selected");
            // console.log(index);
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[0];
            addClass(liSelected, "selected");
          }
        } else if (event.key === "ArrowUp") {
          //up
          if (liSelected) {
            removeClass(liSelected, "selected");
            index--;
            // console.log(index);
            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index >= 0) {
              liSelected = next;
            } else {
              index = len;
              liSelected = ul.getElementsByTagName("li")[len];
            }
            addClass(liSelected, "selected");
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[len];
            addClass(liSelected, "selected");
          }
        }
      },
      false
    );

    function removeClass(el, className) {
      if (!el) return;
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(
          new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
      }
    }

    function addClass(el, className) {
      if (!el) return;
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  };

  dynamicTextSize = () => {
    var button;
    var that;
    var textLength;
    this.state.uniqueFavs.slice(0, 8).forEach((station) => {
      if (station[0]) {
        // eslint-disable-next-line
        (button = document.getElementById("departure" + station[1])),
          (that = document.getElementById("departure" + station[1]).innerHTML),
          // console.log('THAT: ', that),
          (textLength = that.length),
          // console.log('TEXT LENGTH DEPARTURES: ', textLength),
          textLength > 30
            ? button.classList.add("font5")
            : textLength > 20
            ? button.classList.add("font10")
            : textLength > 10
            ? button.classList.add("font14")
            : null;
      }
    });
  };

  getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      this.initGeolocation();
    } else {
      console.log("Location Not Available");
    }
  };

  initGeolocation = () => {
    var latitude = "";
    var longitude = "";
    let currentComponent = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      window.localStorage.removeItem("Latitude");
      window.localStorage.removeItem("Longitude");
      window.localStorage.setItem("Latitude", latitude);
      window.localStorage.setItem("Longitude", longitude);
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Station/SearchStations?`;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", window.localStorage.AccessToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        proxyUrl +
          targetUrl +
          "longitude=" +
          window.localStorage.Longitude +
          "&latitude=" +
          window.localStorage.Latitude,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          result.Message === "Stations are not found for the provided geo-location!"
            ? currentComponent.setState({ stations: [] })
            : currentComponent.setState({ stations: result });
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
    this.setState({
      lat: window.localStorage.Latitude,
      lon: window.localStorage.Longitude,
    });
  };

  //Capitalize first letter
  toUpperCaseFn(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  searchHandler(event) {
    let keyword = event.target.value;
    keyword = this.toUpperCaseFn(keyword);
    this.setState({
      [event.target.name]: keyword,
      selectedName: null,
      code: null,
      // unknownStation: true,
    });
    if (event.target.value.length > 0) {
      this.passSelectedValueToPointOfSale(event.target.value);

      const fakeStationsList = this.getFakeStationsList(event.target.value);

      this.setState({ stations: fakeStationsList }, () => {
        this.checkStationExistence();
      });
    } else {
      this.passSelectedValueToPointOfSale("");
    }
  }

  //Handle click on one of the searched Cities
  handleClickEvent = (e) => {
    let value = e.currentTarget.getAttribute("value");
    let code = e.currentTarget.getAttribute("code");
    value = this.toUpperCaseFn(value);

    this.setState({
      selectedName: value,
      searchField: value,
      stations: [],
      code: code,
    });

    this.passSelectedValueToPointOfSale(value);
  };

  //Check whether the typed station exist in the db
  checkStationExistence = () => {
    this.setState({ unknownStation: true });
    this.state.stations.forEach((station) => {
      if (
        station.Name.toLowerCase().replace(/\s+/g, "").trim() ===
        this.state.searchField.toLowerCase().replace(/\s+/g, "").trim()
      ) {
        this.setState({ unknownStation: false });
        return;
      }
    });
  };

  passSelectedValueToPointOfSale = (value) => {
    this.props.updateSelectedValue(value);
  };

  handleClickOutside = (event) => {
    if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
      this.setState({ selectedName: "filled" });
    }
  };

  // Very hacky method to generate suggestions in a fake station object format
  // in order to comply with the existing code in this component
  getFakeStationsList(searchString) {
    return fuzzysort.go(searchString.trim(), STORE_OPTIONS, { 
      threshold: -Infinity, limit: 9 
    }).map((result) => {
      return { Code: 0, Longitude: 0, Latitude: 0, Name: result.target };
    });
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        {/* Info overlay */}
        <div ref={this.containerRef} className="_container container-full">
          <Container className="StartortScreen" disableGutters={true}>
            <Container maxWidth="false" disableGutters>
              <TextField
                autoComplete="off"
                value={this.state.searchField}
                name="searchField"
                id="von"
                label={
                  window.location.pathname.includes("/fr")
                    ? "Sélection d'une filiale Coop / Interdiscount"
                    : window.location.pathname.includes("/it")
                    ? "Selezione di una filiale Coop / Interdiscount"
                    : window.location.pathname.includes("/en")
                    ? "Selection of Coop / Interdiscount store"
                    : "Auswahl Filiale Coop / Interdiscount"
                }
                fullWidth
                className="searchField"
                onChange={this.searchHandler}
              />
              <ul id="list" className="searchResults">
                {!this.state.selectedName && this.state.searchField?.length > 0 && this.state.unknownStation ? (
                  <li onClick={(e) => this.handleClickEvent(e)} value={this.state.searchField} code={-1}>
                    {this.state.searchField}
                  </li>
                ) : null}
                {this.state.selectedName === null &&
                  this.state.searchField?.length > 0 &&
                  this.state.stations?.slice(0, 10).map((stations, index) => {
                    return (
                      <li
                        key={index}
                        value={stations.Name}
                        code={stations.Code}
                        onClick={(e) => this.handleClickEvent(e)}
                      >
                        {stations.Name}
                      </li>
                    );
                  })}
              </ul>
              <Typography style={{ paddingLeft: "10px" }} variant="body2" className="favouriteCopy">
                {window.location.pathname.includes("/fr")
                  ? "Propositions"
                  : window.location.pathname.includes("/it")
                  ? "Proposte"
                  : window.location.pathname.includes("/en")
                  ? "Suggestions"
                  : "Vorschläge"}
              </Typography>
              <div className="connSuggestionsButton connSuggestionsButtonCOOP">
                {this.state.uniqueFavs === null ? null : this.state.uniqueFavs.length > 0 ? (
                  this.state.uniqueFavs
                    .slice(0, 8)
                    .reverse()
                    .map((station) => {
                      if (station[0]) {
                        return (
                          <Button
                            onClick={() => this.props.handleStartPoint(station[0], station[1])}
                            variant="contained"
                            className="stationButton"
                            disableElevation
                            size="large"
                          >
                            <span id={"departure" + station[1]}>{station[0]}</span>
                          </Button>
                        );
                      }
                      return "";
                    })
                ) : (
                  <Skeleton animation="wave" height={42} className="stationSkeleton" />
                )}
              </div>
            </Container>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(StoreDropdown);
