import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import expandButton from "../../../assets/icons/expandPlusIcon.svg";
import collapseButton from "../../../assets/icons/collapseMinusIcon.svg";
import "./styles.css";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #DCDCDC",
    marginBottom: -1,
    minHeight: 68,
    "&$expanded": {
      minHeight: 68,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function CustomizedExpansionPanels() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="_container-three-borders" style={{ paddingBottom: "5rem" }}>
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Quel est le but de l'enquête?"
              : window.location.pathname.includes("/it")
              ? "Qual è lo scopo del sondaggio?"
              : window.location.pathname.includes("/en")
              ? "What is the purpose of this survey?"
              : "Welchen Zweck verfolgt die Umfrage?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel1" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Votre carte journalière vous permet d'emprunter les transports publics. Dans le cadre de la présente enquête, nous cherchons à savoir quels trajets vous avez effectués. Vos indications nous servent à répartir les recettes entre les quelque 160 entreprises de transport participantes."
              : window.location.pathname.includes("/it")
              ? "Con la carta giornaliera viaggia con i trasporti pubblici. Nel quadro del presente sondaggio desideriamo sapere quali tratte ha percorso. I dati servono per ripartire gli introiti tra le oltre 160 imprese di trasporto."
              : window.location.pathname.includes("/en")
              ? "The Day Pass entitles you to use the route networks of different public transport companies all over Switzerland. The aim of this survey is to find out what journeys you made. By taking part in this survey, you are helping us to distribute the income to the 160 transport companies fairly."
              : "Mit der Tageskarte sind Sie im öffentlichen Verkehr unterwegs. Im Rahmen dieser Umfrage möchten wir erfahren, welche Strecken Sie gefahren sind. Die Angaben werden benötigt, um die Einnahmen aus dem Verkauf von Tageskarten auf die über 160 beteiligten Transportunternehmen zu verteilen. "}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Sur quelle base procédez-vous à l'enquête et au traitement des données?"
              : window.location.pathname.includes("/it")
              ? "Su quale base viene realizzato il rilevamento e trattamento dei dati?"
              : window.location.pathname.includes("/en")
              ? "On what authority are you carrying out the survey and analysing the results?"
              : "Auf welcher Grundlage führen Sie die Erhebung und Datenbearbeitung durch?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel2" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="dataExpand">
          {window.location.pathname.includes("/fr") ? (
            <Typography className="infoCardContent">
              L'organe de gestion de l'Alliance SwissPass, géré par ch-integral,
              assume le mandat légal défini par la loi suisse sur le transport
              de voyageurs de collecter des données de voyage en vue de répartir
              correctement les recettes. L'organe de gestion agit en tant que
              mandataire au nom des entreprises de transport pour la répartition
              des recettes dans le Service direct national. Cette tâche est
              définie dans la Convention de collaboration au sein du Service
              direct national (SDN) et entre le SDN et les communautés de
              transports publics (C500). Dans ce cadre, les données personnelles
              et de produits des clients nécessaires à l'enquête sont
              transmises. Cette transmission des données à l'organe de gestion
              et à son prestataire mandaté aux fins de répartition des recettes
              est mentionnée dans les déclarations de protection des données des
              entreprises de transport. Vous trouverez davantage d'informations
              dans notre{" "}
              <a href="https://www.allianceswisspass.ch/fr/asp/Downloadsindex.php?section=downloads&download=19456">
                déclaration de protection des données
              </a>
              .
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography className="infoCardContent">
              Il segretariato dell'Alliance SwissPass, gestito da ch-integral,
              si assume il mandato legale previsto dalla legge svizzera sul
              trasporto di viaggiatori di rilevare i dati dei viaggi per la
              corretta ripartizione degli introiti. Il segretariato funge da
              mandatario per la ripartizione degli introiti nel Servizio diretto
              nazionale su incarico delle imprese di trasporto. Tale compito è
              ancorato nella Convenzione C500 sulla collaborazione all'interno
              del Servizio diretto nazionale (SDN) e tra il SDN e le comunità
              tp. In tale contesto vengono trasmessi i dati dei prodotti e
              personali dei clienti necessari per il rilevamento. Tale
              trasmissione di dati al segretariato e ai fornitori di servizi da
              esso incaricati ai fini della ripartizione degli introiti è
              indicata nelle informative sulla protezione dei dati delle imprese
              di trasporto. Per ulteriori informazioni consultare la nostra{" "}
              <a href="https://www.allianceswisspass.ch/it/asp/Downloadsindex.php?section=downloads&download=19456">
                informativa sulla protezione dei dati
              </a>
              .
            </Typography>
          ) : window.location.pathname.includes("/en") ? (
            <Typography className="infoCardContent">
              The head office of the Alliance SwissPass, managed by ch-integral,
              has a statutory mandate under Swiss law governing the carriage of
              passengers (PBG) to collect travel information needed to
              distribute the income from ticket sales equitably. The office
              operates as a mandate for the distribution of revenues received by
              National Direct Service on behalf of the transport companies. The
              mandate is embodied in the C500 agreement on the cooperation
              between National Direct Service and the public transport
              associations. The agreement stipulates that the necessary product
              and personal information should be obtained from customers by
              means of a survey. The data privacy statements of the transport
              companies state that data may be shared with our office and our
              appointed agents for the purpose of revenue distribution. For more
              information, please see our{" "}
              <a href="https://www.allianceswisspass.ch/en/asp/Downloadsindex.php?section=downloads&download=19457">
                privacy policy.
              </a>
            </Typography>
          ) : (
            <Typography className="infoCardContent">
              Die Geschäftsstelle der Alliance SwissPass, geführt durch
              ch-integral, nimmt den gemäss Schweizer Personenbeförderungsgesetz
              bestehenden gesetzlichen Auftrag wahr, Reisedaten für die korrekte
              Einnahmenverteilung zu erheben. Die Geschäftsstelle fungiert dabei
              als Mandatsnehmerin für die Einnahmenverteilung im Nationalen
              Direkten Verkehr im Auftrag der Transportunternehmen. Diese
              Aufgabe ist verankert im Ue500 Übereinkommen über die
              Zusammenarbeit innerhalb des Nationalen Direkten Verkehrs (NDV)
              und zwischen NDV und den öV-Verbünden. In diesem Rahmen werden die
              für die Erhebung erforderlichen Produkt- und Personendaten von
              Kundinnen und Kunden übermittelt. Diese Weitergabe der Daten an
              die Geschäftsstelle und deren beauftragte Dienstleister zum Zweck
              der Einnahmenverteilung ist in den Datenschutzerklärungen der
              Transportunternehmen ausgewiesen. Weitere Informationen erhalten
              Sie in unserer{" "}
              <a
                href="https://www.allianceswisspass.ch/de/asp/Downloadsindex.php?section=downloads&download=19456"
                // eslint-disable-next-line
                target="_blank"
              >
                Datenschutzerklärung
              </a>
              .
            </Typography>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Les données de voyage sont-elles évaluées de façon anonyme?"
              : window.location.pathname.includes("/it")
              ? "I dati dei viaggi sono analizzati in forma anonima?"
              : window.location.pathname.includes("/en")
              ? "Will my travel data by anonymised?"
              : "Werden die Reiseangaben anonymisiert ausgewertet?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel3" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {window.location.pathname.includes("/fr") ? (
            <Typography className="infoCardContent">
              Oui. Vos données de voyage sont évaluées sans votre nom. Les
              données sont agrégées pour tirer des conclusions générales
              anonymisées au sujet de la consommation du titre de transport.
              Nous avons besoin de vos coordonnées dans le cadre du concours
              afin de vous faire parvenir un éventuel prix. En outre, nous
              soulignons que le respect des dispositions de protection des
              données en vigueur est particulièrement important pour nous. Pour
              davantage d'informations, veuillez consulter notre{" "}
              <a href="https://www.allianceswisspass.ch/fr/asp/Downloadsindex.php?section=downloads&download=19456">
                déclaration de protection des données
              </a>
              .
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography className="infoCardContent">
              Sì. I dati dei suoi viaggi sono analizzati senza il suo nome. I
              dati sono raccolti per formulare affermazioni impersonali, di
              carattere generale, sul consumo del titolo di trasporto. Abbiamo
              bisogno dei suoi dati di contatto nell'ambito del concorso per
              inviarle un eventuale premio. Segnaliamo inoltre che per noi è
              estremamente importante il rispetto delle prescrizioni pertinenti
              sulla protezione dei dati. Trova ulteriori informazioni sotto{" "}
              <a href="https://www.allianceswisspass.ch/it/asp/Downloadsindex.php?section=downloads&download=19456">
                informativa sulla protezione dei dati
              </a>
              .
            </Typography>
          ) : window.location.pathname.includes("/en") ? (
            <Typography className="infoCardContent">
              Yes. Your travel data will be processed without reference to your
              name. The data is consolidated into general, impersonal
              information about the usage of the ticket. We need your contact
              data in connection with the competition to enable us to deliver
              your prize if you win. We would also like to mention that
              compliance with the applicable data protection regulations is
              extremely important to us. For more information, please see our{" "}
              <a href="https://www.allianceswisspass.ch/en/asp/Downloadsindex.php?section=downloads&download=19457">
                privacy policy
              </a>
              .
            </Typography>
          ) : (
            <Typography className="infoCardContent">
              Ja. Ihre Reiseangaben werden ohne Ihren Namen ausgewertet. Die
              Daten werden zu allgemeinen, unpersönlichen Aussagen zum Konsum
              des Fahrausweises zusammengefasst. Ihre Kontaktdaten benötigen wir
              im Rahmen des Wettbewerbs für die Zustellung eines allfälligen
              Gewinnes. Im Übrigen weisen wir darauf hin, dass die Einhaltung
              der einschlägigen Datenschutzvorschriften für uns eminent wichtig
              ist. Weitere Informationen erhalten Sie in unserer{" "}
              <a
                href="https://www.allianceswisspass.ch/de/asp/Downloadsindex.php?section=downloads&download=19456"
                // eslint-disable-next-line
                target="_blank"
              >
                Datenschutzerklärung
              </a>
              .
            </Typography>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/* <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Pouvez-vous m'attribuer une autre période de relevé?"
              : window.location.pathname.includes("/it")
              ? "Potete assegnarmi un altro periodo per la registrazione dei viaggi?"
              : window.location.pathname.includes("/en")
              ? "Can you allocate me a different week for entering my travel information?"
              : "Können Sie mir einen anderen Zeitraum für die Erfassung der Reisen zuweisen?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel4" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Non, ce n'est malheureusement pas possible du fait de notre méthodologie."
              : window.location.pathname.includes("/it")
              ? "No, per motivi legati al metodo di rilevamento ciò non è possibile."
              : window.location.pathname.includes("/en")
              ? "No. Unfortunately, the rules of our survey don't allow us to do that."
              : "Nein, das ist leider aus Gründen der Erhebungsmethodik nicht möglich."}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Pourquoi ne puis-je pas me connecter?"
              : window.location.pathname.includes("/it")
              ? "Perché non riesco ad accedere al sondaggio?"
              : window.location.pathname.includes("/en")
              ? "Why can I not login?"
              : "Warum kann ich mich nicht anmelden?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel5" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Deux semaines après la date de voyage, le login est automatiquement bloqué pour des raisons de traitement. Il n'est alors plus possible de saisir de données. Si, pendant la période donnée, vous ne parvenez pas à vous connecter, merci de vous adresser à contact@allianceswisspass.ch ou au (+41) 031 359 22 50."
              : window.location.pathname.includes("/it")
              ? "Due settimane dopo la data del viaggio l'accesso viene automaticamente bloccato per motivi di elaborazione. In seguito non è più possibile registrare alcun dato. Se nel periodo stabilito non riesce ad accedere al sondaggio, può contattarci all'indirizzo contact@allianceswisspass.ch o al numero (+41) 031 359 22 50."
              : window.location.pathname.includes("/en")
              ? "For administrative reasons, logging in is automatically blocked two weeks after your travel date and you will not be able to make entries in your diary. If you are unable to log in during the specified period, please contact us at contact@allianceswisspass.ch or (+41) 031 359 22 50."
              : "Zwei Wochen nach dem Reisedatum wird das Login aus Verarbeitungsgründen automatisch gesperrt. Danach ist keine Datenerfassung mehr möglich. Wenn Sie sich während des vorgegebenen Zeitraums nicht anmelden können, wenden Sie sich an contact@allianceswisspass.ch oder (+41) 031 359 22 50"}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel6d-content"
          id="panel6d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Je n'ai pas utilisé ma carte journalière. Puis-je quand même participer au tirage au sort?"
              : window.location.pathname.includes("/it")
              ? "Non ho utilizzato la mia carta giornaliera. Posso comunque partecipare al concorso?"
              : window.location.pathname.includes("/en")
              ? "I did not use my Day Pass. Can I still participate in the prize draw?"
              : "Ich habe meine Tageskarte nicht genutzt. Kann ich trotzdem am Wettbewerb teilnehmen?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel6" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Oui. Pour ce faire, terminez l'enquête en cliquant sur le bouton prévu à cet effet."
              : window.location.pathname.includes("/it")
              ? "Sì. A tale scopo concluda il sondaggio con il rispettivo pulsante."
              : window.location.pathname.includes("/en")
              ? "Yes. To do this, end the survey with the corresponding button."
              : "Ja. Beenden Sie hierfür die Umfrage mit der entsprechenden Schaltfläche."}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel7d-content"
          id="panel7d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Un membre du personnel d'accompagnement m'a récemment questionné quant à l'utilisation de mon titre de transport. Ne pouvez-vous pas utiliser ces données?"
              : window.location.pathname.includes("/it")
              ? "Di recente il personale dei treni mi ha sottoposto un questionario sull'utilizzo del titolo di trasporto. Non potete utilizzare questi dati?"
              : window.location.pathname.includes("/en")
              ? "I was recently asked questions by train staff about the use of my ticket. Can't you use this information?"
              : "Neulich wurde ich vom Zugpersonal über die Benützung des Fahrausweises befragt. Können Sie nicht diese Angaben verwenden?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel7" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Non, les relevés du personnel de train remplissent d'autres objectifs. La répartition des recettes des titres de transport forfaitaires (AG, carte journalière, Swiss Travel Pass, etc.) est déterminée au niveau national sur la base des données d'enquête de l'Alliance SwissPass."
              : window.location.pathname.includes("/it")
              ? "No. I sondaggi realizzati dal personale dei treni hanno obiettivi diversi. La ripartizione degli introiti dei titoli di trasporto forfetari (AG, carta giornaliera, Swiss Travel Pass ecc.) viene calcolata con dati rilevati dall'Alliance SwissPass a livello nazionale."
              : window.location.pathname.includes("/en")
              ? "No. Interviews are carried out by customer service personnel for other purposes. The income from National Direct Transport travel passes is distributed on the basis of data obtained by the Alliance SwissPass from nationwide surveys."
              : "Nein. Die Befragungen durch das Zugpersonal erfüllen andere Zwecke. Die Verteilung der Einnahmen von Pauschalfahrausweisen (GA, Tageskarte, Swiss Travel Pass etc.) wird mit Erhebungsdaten der Alliance SwissPass auf nationaler Ebene berechnet. "}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/* <ExpansionPanel
        className="infoCard"
        square
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel8d-content"
          id="panel8d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Combien de personnes interrogez-vous?"
              : window.location.pathname.includes("/it")
              ? "Quante persone partecipano al sondaggio?"
              : window.location.pathname.includes("/en")
              ? "How many people take part in these surveys?"
              : "Wie viele Personen werden befragt?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel8" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Afin de garantir une base de calcul pertinente, 52 000 titulaires d'un abonnement général sont invités chaque année à participer à notre enquête."
              : window.location.pathname.includes("/it")
              ? "Per garantire una base di calcolo significativa, ogni anno vengono invitati a partecipare al sondaggio 52 000 clienti AG, selezionati casualmente, provenienti da tutta la Svizzera."
              : window.location.pathname.includes("/en")
              ? "To ensure high-quality data for calculation purposes, approximately 52,000 TK travelcard customers from every region are invited to take part in our survey each year. These customers are randomly selected using proven methods."
              : "Um eine aussagekräftige Berechnungsgrundlage zu gewährleisten, werden jährlich 52'000 zufällig ausgewählte TK-Kund*innen aus der ganzen Schweiz zur Umfrage eingeladen."}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}
      <ExpansionPanel
        className="infoCard lastInfoCard"
        square
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel8d-content"
          id="panel9d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Quelles sont les conditions de participation au tirage au sort?"
              : window.location.pathname.includes("/it")
              ? "Quali sono le condizioni del concorso?"
              : window.location.pathname.includes("/en")
              ? "What are the conditions of the prize draw?"
              : "Welches sind die Wettbewerbsbedingungen?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel9" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Les tirages au sort ne sont pas publics et ne s'effectuent pas en présence d'un notaire. Les gagnants sont informés directement. Les participants sont responsables de transmettre leurs coordonnées correctes. L'Alliance SwissPass n'assume aucune responsabilité pour la remise incorrecte de prix qui résulterait de coordonnées erronées. Les prix mensuels sont tirés au sort chaque mois. Le tirage au sort du prix principal s'effectuera en été 2024 après la fin du traitement des données. Aucune correspondance n'est échangée au sujet des tirages au sort. En participant au concours, vous acceptez ces conditions. La version allemande des conditions de participation fait foi."
              : window.location.pathname.includes("/it")
              ? "I sorteggi tra i partecipanti avvengono a porte chiuse senza la supervisione di un notaio. I vincitori vengono informati direttamente. I partecipanti sono responsabili della corretta trasmissione dei loro dati di contatto. Non ci assumiamo alcuna responsabilità per il conseguente invio non corretto del premio. I premi mensili vengono sorteggiati mensilmente. L'estrazione del premio principale avverrà al termine dell'elaborazione dei dati, nell'estate 2024. Sui sorteggi non si tiene alcuna corrispondenza. È escluso il ricorso alle vie legali. Con la partecipazione al concorso lei accetta le presenti condizioni. Fa stato il testo in lingua tedesca delle condizioni di partecipazione."
              : window.location.pathname.includes("/en")
              ? "The prize draw takes place in private and is not notarially certified. Winners are notified directly. The winners are responsible for the accuracy of the contact details supplied. We cannot accept any liability for incorrect delivery of prizes resulting from incorrect contact details. The draw for the monthly prizes takes place once a month. The draw for the main prize will take place in the summer of 2024 once the data has been processed. No correspondence will be entered into about the prize draw. The organisers' decision is final. By taking part in the prize draw you accept these Terms and Conditions. The German version of the Terms and Conditions is the legally binding one."
              : "Die Verlosungen unter den Teilnehmenden finden unter Ausschluss der Öffentlichkeit statt. Die Verlosung wird nicht notariell beaufsichtigt. Die Gewinner werden direkt benachrichtigt. Die Teilnehmenden sind für die korrekte Übermittlung Ihrer Kontaktdaten verantwortlich. Für daraus entstehende nicht korrekte Gewinnzustellung wird keine Haftung übernommen. Die Monatspreise werden monatlich verlost. Die Ziehung des Hauptpreises erfolgt nach Abschluss der Datenverarbeitung im Sommer 2024. Über die Verlosungen wird keine Korrespondenz geführt. Der Rechtsweg ist ausgeschlossen. Mit der Teilnahme am Wettbewerb akzeptieren Sie diese Bedingungen. Massgebend ist der Deutsche Text der Teilnahmebedingungen."}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        className="infoCard lastInfoCard"
        square
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <ExpansionPanelSummary
          className="infoCardHeader"
          aria-controls="panel8d-content"
          id="panel10d-header"
        >
          <Typography className="infoCardHeaderTitle">
            {window.location.pathname.includes("/fr")
              ? "Comment puis-je saisir des parcours circulaires (croisières, circuit des cols, etc.)?"
              : window.location.pathname.includes("/it")
              ? "Come posso inserire viaggi circolari (crociere, tour dei passi, ecc.)?"
              : window.location.pathname.includes("/en")
              ? "How can I record round trips (cruises, mountain pass tours, etc.)?"
              : "Wie kann ich Rundreisen (Schiffsrundreisen, Pässefahrten etc.) erfassen?"}
          </Typography>
          <Typography className="expandButton">
            {expanded === "panel10" ? (
              <img src={collapseButton} alt="Collapse" />
            ) : (
              <img src={expandButton} alt="Expand" />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="infoCardContent">
            {window.location.pathname.includes("/fr")
              ? "Sélectionnez l'arrêt de départ de votre parcours circulaire comme point de départ. Sur destination, vous pouvez saisir 'Croisière' ou 'Circuit des cols'."
              : window.location.pathname.includes("/it")
              ? "Seleziona la fermata di partenza del viaggio circolare come luogo di partenza. Per la destinazione puoi inserire 'Crociera' o 'Tour die passi'."
              : window.location.pathname.includes("/en")
              ? "Select the starting stop of the round trip as the starting location. For the destination you can enter 'Cruise' or 'Mountain Pass Tour'."
              : "Wählen Sie als Startort die Start-Haltestelle der Rundreise. Beim Zielort können Sie 'Rundreise' oder 'Pässefahrt' eintragen."}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {window.location.pathname.includes("/fr") ? (
        <Typography className="cookieLink">
          Notre déclaration de protection des données est disponible{" "}
          <a
            href="https://www.allianceswisspass.ch/fr/asp/Downloadsindex.php?section=downloads&download=19456"
            className="policyButton"
            disableElevation
            size="large"
            fullWidth
          >
            ici.
          </a>
        </Typography>
      ) : window.location.pathname.includes("/it") ? (
        <Typography className="cookieLink">
          Trova la nostra informativa sulla protezione dei dati{" "}
          <a
            href="https://www.allianceswisspass.ch/it/asp/Downloadsindex.php?section=downloads&download=19456"
            className="policyButton"
            disableElevation
            size="large"
            fullWidth
          >
            qui.
          </a>
        </Typography>
      ) : window.location.pathname.includes("/en") ? (
        <Typography className="cookieLink">
          You can find our privacy policy{" "}
          <a
            href="https://www.allianceswisspass.ch/en/asp/Downloadsindex.php?section=downloads&download=19457"
            className="policyButton"
            disableElevation
            size="large"
            fullWidth
          >
            here.
          </a>
        </Typography>
      ) : (
        <Typography className="cookieLink">
          Unsere Datenschutzerklärung finden Sie{" "}
          <a
            href="https://www.allianceswisspass.ch/de/asp/Downloadsindex.php?section=downloads&download=19456"
            // eslint-disable-next-line
            className="policyButton"
            // eslint-disable-next-line
            target="_blank"
            disableElevation
            size="large"
            fullWidth
          >
            hier.
          </a>
        </Typography>
      )}
    </div>
  );
}
