import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import * as moment from "moment";
import axios from "axios";
import querystring from "querystring";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import BottomNavigation from "@material-ui/core/BottomNavigation";
// import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Contest from "../Home/FinishJourney/Contest";
// import NavBar from "../../Shared/NavBar";
import AppBar from "../../Shared/AppBar";
import "moment/locale/de";
import BottomNav from "../../Shared/BottomNav";
// import AppBar from "../../Shared/AppBar";
class EndSurvey extends Component {
  constructor() {
    super();
    this.state = {
      page: "ABC",
      loading: false,
      surveyClosed: false,
      reason: null,
      dialogError: "failed",
      fade: "out",
      fetchingData: true,
    };
  }
  componentDidMount() {
    let params = querystring.parse(this.props.location.search);
    let searchParams = this.props.location.search;

    if (params !== null) {
      this.setState({ wrongTNC: false });
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/token`,
          querystring.stringify({
            TNC: params["?TNC"],
            grant_type: "password",
          })
        )
        .then((response) => {
          window.localStorage.setItem(
            "AccessToken",
            "Bearer " + response.data.access_token
          );
        })
        .then(() => {
          this.getUserInfo();
        })

        .catch((error) => {
          console.log("ERROR:", error.message);
          if (error.message === "Request failed with status code 400") {
            window.location.pathname.includes("/fr")
              ? this.setState({
                  dialogError:
                    "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                  fade: "in",
                })
              : window.location.pathname.includes("/it")
              ? this.setState({
                  dialogError:
                    "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                  fade: "in",
                })
              : window.location.pathname.includes("/en")
              ? this.setState({
                  dialogError:
                    "The entry code is invalid. Please check your entry and try again.",
                  fade: "in",
                })
              : this.setState({
                  dialogError:
                    "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
                  fade: "in",
                });
          }
          this.setState({ wrongTNC: true });

          window.location.pathname.includes("/fr")
            ? window.location.replace(`/fr/${searchParams}`)
            : window.location.pathname.includes("/it")
            ? window.location.replace(`/it/${searchParams}`)
            : window.location.pathname.includes("/en")
            ? window.location.replace(`/en/${searchParams}`)
            : window.location.replace(`/de/${searchParams}`);
          window.localStorage.clear();
        });
    } else {
      this.setState({ wrongTNC: true });
    }
  }
  startClosingProcess = () => {
    this.finishJourney();
  };
  getUserInfo = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            userProfile: result.Preload,
            resultsData: result,
            fetchingData: false,
          },
          () => {
            this.getJourneys();
          }
        );
      })
      .catch((error) => console.log("error", error));
  };
  finalizeJourney = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Finalize`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(() => {
        this.setState({ surveyClosed: true });
      })
      .catch((error) => console.log("error", error));

    localStorage.clear();
    window.location.pathname.includes("/fr")
        ? this.props.history.push("/fr/vielendank")
        : window.location.pathname.includes("/it")
        ? this.props.history.push("/it/vielendank")
        : window.location.pathname.includes("/en")
        ? this.props.history.push("/en/vielendank")
        : this.props.history.push("/de/vielendank");
  };
  finishJourney = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Close`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({
      Journeys: this.state.tripsData,
      Notes: null,
      Reason: this.state.reason,
      ReasonFreetext: "Andere",
      HeaderTitle: null,
      CopyOverride: null,
      PeriodStart: this.state.userProfile.PeriodStart,
      PeriodEnd: this.state.userProfile.PeriodEnd,
      SubmissionDeadline: this.state.userProfile.SubmissionDeadline,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.log("error", error));

    this.finalizeJourney();
  };
  getJourneys = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          tripsData: result,
        });
      });
  };
  reasonHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  dialogHandler = (param) => {
    this.setState({
      finishDialog: param,
      screen: null,
    });
  };
  participateContest = () => {
    this.setState({
      screen: "Contest",
    });
  };
  finishJourneyCompetition = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Close`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({
      Journeys: this.state.tripsData,
      Notes: null,
      Reason: this.state.reason,
      ReasonFreetext: "Andere",
      HeaderTitle: null,
      CopyOverride: null,
      PeriodStart: this.state.userProfile.PeriodStart,
      PeriodEnd: this.state.userProfile.PeriodEnd,
      SubmissionDeadline: this.state.userProfile.SubmissionDeadline,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.log("error", error));
  };
  render() {
    return this.state.screen === "Contest" ? (
      <Contest
        state={this.state}
        finishJourneyCompetition={this.finishJourneyCompetition}
      />
    ) : (
      <>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />

        <Container
          maxWidth="false"
          className="datumScreen"
          disableGutters="true"
        >
          {/*window.location.pathname.includes("/fr") ? (
            <Typography
              component="div"
              variant="h6"
              className="welcomeBanner questionsBanner"
            >
              Confirmation de non-utilisation de l'AG
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography
              component="div"
              variant="h6"
              className="welcomeBanner questionsBanner"
            >
              Conferma mancato utilizzo AG
            </Typography>
          ) : (
            <Typography
              component="div"
              variant="h6"
              className="welcomeBanner questionsBanner"
            >
              Bestätigung Nichtnutzung TK
            </Typography>
          )*/}
          <Container>
            {/* <Card
              raised="true"
              className={"errorDialog fade-" + this.state.fade}
            >
              <CardContent className="errorContent">
                <Typography align="center">{this.state.dialogError}</Typography>
              </CardContent>
            </Card> */}
            {window.location.pathname.includes("/fr") ? (
              <Typography component="div" variant="h6">
                <br />
                <br />
                Confirmation de non-utilisation de l'AG
                <br />
                <br />
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography component="div" variant="h6">
                <br />
                <br />
                Conferma mancato utilizzo AG
                <br />
                <br />
              </Typography>
            ) : window.location.pathname.includes("/en") ? (
              <Typography component="div" variant="h6">
                <br />
                <br />
                Confirmation of the non-use of the TK travelcard
                <br />
                <br />
              </Typography>
            ) : (
              <Typography component="div" variant="h6">
                <br />
                <br />
                Bestätigung Nichtnutzung TK
                <br />
                <br />
              </Typography>
            )}
          </Container>
          {!this.state.fetchingData ? (
            <Container>
              <FormControl fullWidth component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="reason"
                  onChange={this.reasonHandler}
                >
                  {window.location.pathname.includes("/fr") ? (
                    <FormControlLabel
                      value="Andere Gründe"
                      control={<Radio />}
                      label={`Je n'ai effectué aucun trajet avec mon abonnement général du ${moment(
                        this.state.userProfile.PeriodStart
                      ).format("DD.MM.YYYY")} au ${moment(
                        this.state.userProfile.PeriodEnd
                      ).format("DD.MM.YYYY")}.`}
                      // label="Je n'ai effectué aucun trajet avec mon abonnement général du #VON# au #BIS# (parce que je l'avais déposé, parce que j'étais en vacances, etc.)"
                    />
                  ) : window.location.pathname.includes("/it") ? (
                    <FormControlLabel
                      value="Andere Gründe"
                      control={<Radio />}
                      label={`Dal ${moment(
                        this.state.userProfile.PeriodStart
                      ).format("DD.MM.YYYY")} al ${moment(
                        this.state.userProfile.PeriodEnd
                      ).format(
                        "DD.MM.YYYY"
                      )} non ho effettuato alcun viaggio con il mio abbonamento generale.`}
                      // label="Dal #VON# al #BIS# non ho effettuato alcun viaggio con il mio abbonamento generale."
                    />
                  ) : window.location.pathname.includes("/en") ? (
                    <FormControlLabel
                      value="Andere Gründe"
                      control={<Radio />}
                      label={`I did not travel with my TK travelcard from ${moment(
                        this.state.userProfile.PeriodStart
                      ).format("DD.MM.YYYY")} until ${moment(
                        this.state.userProfile.PeriodEnd
                      ).format("DD.MM.YYYY")} `}
                      // label="Dal #VON# al #BIS# non ho effettuato alcun viaggio con il mio abbonamento generale."
                    />
                  ) : (
                    <FormControlLabel
                      value="Andere Gründe"
                      control={<Radio />}
                      label="Ich habe mit der Tageskarte keine Reisen unternommen."
                      // "Ich habe mit meinem Generalabonnement vom #VON# - #BIS# keine Reisen unternommen (beispielsweise aufgrund Hinterlegung, Ferien etc.). "
                    />
                  )}
                  {/* <FormControlLabel value="TK hinterlegt" control={<Radio />} label="TK hinterlegt" />
              <FormControlLabel value="Militärdienst / Zivildienst" control={<Radio />} label="Militärdienst / Zivildienst" />
              <FormControlLabel value="Krankheit/Unfall" control={<Radio />} label="Krankheit/Unfall" />
              <FormControlLabel value="Andere" control={<Radio />} label="Andere" /> */}
                </RadioGroup>
                {/* <TextField disabled={ this.props.state.reason === 'Andere' ? false : true } fullWidth onChange={this.props.reasonHandler} name="reasonFreeText" id="outlined-basic" label="Grund eingeben" value={this.props.state.reasonFreeText} variant="outlined" /> */}
              </FormControl>
            </Container>
          ) : null}

          {window.location.pathname.includes("/fr") ? (
            <Dialog
              open={this.state.finishDialog}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Souhaitez-vous participer au tirage au sort?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Parmi les participants, nous tirons au sort chaque mois 10
                  bons Reka Rail d’une valeur de 50 francs. Le tirage au sort
                  annuel a pour prix principal un AG de 1re classe ou un bon de
                  votre choix d’une valeur de 6300 francs. Pour que vous
                  participiez au tirage au sort, nous avons besoin de vos
                  coordonnées.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.finishJourney()}
                  autoFocus
                  color="primary"
                >
                  Non merci
                </Button>
                <Button
                  onClick={() => this.dialogYes()}
                  color="primary"
                  autoFocus
                >
                  Je souhaite participer
                </Button>
              </DialogActions>
            </Dialog>
          ) : window.location.pathname.includes("/it") ? (
            <Dialog
              open={this.state.finishDialog}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Desidera partecipare al nostro concorso?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Ogni mese sorteggiamo tra i partecipanti 10 buoni Reka Rail da
                  50 franchi ciascuno e come premio principale un AG di 1ª
                  classe o un buono a sua scelta del valore di 6300 franchi. A
                  tale scopo abbiamo bisogno dei suoi dati di contatto.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.finishJourney()}
                  autoFocus
                  color="primary"
                >
                  No, grazie
                </Button>
                <Button
                  onClick={() => this.dialogYes()}
                  color="primary"
                  autoFocus
                >
                  Desidero partecipare
                </Button>
              </DialogActions>
            </Dialog>
          ) : window.location.pathname.includes("/en") ? (
            <Dialog
              open={this.state.finishDialog}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                Would you like to enter our prize draw?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Participants will be entered into a monthly draw for 10
                  Reka-Check vouchers worth CHF 50 each and, as the main prize,
                  a 1st class TK travelcard or a voucher of your choice worth
                  CHF 6300. To participate, we need your contact details.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.finishJourney()}
                  autoFocus
                  color="primary"
                >
                  No, thank you
                </Button>
                <Button
                  onClick={() => this.dialogYes()}
                  color="primary"
                  autoFocus
                >
                  Yes, I'd like to enter
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={this.state.finishDialog}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {window.location.pathname.includes("/fr")
                  ? "Souhaitez-vous participer au tirage au sort?"
                  : window.location.pathname.includes("/it")
                  ? "Desidera partecipare al nostro concorso?"
                  : window.location.pathname.includes("/en")
                  ? "Would you like to enter our prize draw?"
                  : "Möchten Sie an unserem Wettbewerb teilnehmen?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {window.location.pathname.includes("/fr")
                    ? "Parmi les participants, nous tirons au sort chaque mois 10 bons Reka-Check d’une valeur de 50 francs. Le tirage au sort annuel a pour prix principal un AG de 1re classe ou un bon de votre choix d’une valeur de 5000 francs. Pour que vous participiez au tirage au sort, nous avons besoin de vos coordonnées."
                    : window.location.pathname.includes("/it")
                    ? "Ogni mese sorteggiamo tra i partecipanti 10 buoni Reka-Check da 50 franchi ciascuno e come premio principale un AG di 1ª classe o un buono a sua scelta del valore di 5000 franchi. A tale scopo abbiamo bisogno dei suoi dati di contatto."
                    : window.location.pathname.includes("/en")
                    ? "Participants will be entered into a monthly draw for 10 Reka-Check vouchers worth CHF 50 each and, as the main prize, a 1st class GA travelcard or a voucher of your choice worth CHF 5000. To participate, we need your contact details."
                    : "Unter den Teilnehmenden verlosen wir monatlich 10x Reka-Check Gutscheine à 50 Franken und als Hauptpreis ein GA 1. Klasse oder einen Gutschein Ihrer Wahl im Wert von 5000 Franken. Für die Teilnahme benötigen wir Ihre Kontaktdaten."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.startClosingProcess()}
                  autoFocus
                  color="primary"
                >
                  {window.location.pathname.includes("/fr")
                    ? "Non merci"
                    : window.location.pathname.includes("/it")
                    ? "No, grazie"
                    : window.location.pathname.includes("/en")
                    ? "No, thank you"
                    : "Nein danke"}
                </Button>
                <Button
                  onClick={() => this.participateContest()}
                  color="primary"
                  autoFocus
                >
                  {window.location.pathname.includes("/fr")
                    ? "Je souhaite participer "
                    : window.location.pathname.includes("/it")
                    ? "Desidero partecipare "
                    : window.location.pathname.includes("/en")
                    ? "Yes, I'd like to enter"
                    : "Ich möchte teilnehmen"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {/* <BottomNavigation
            onClick={() => this.dialogHandler(true)}
            className='homeCompleteButton connectingContinueButton'
            style={{ backgroundColor: "#EEEEEE" }}
          >
            {window.location.pathname.includes("/fr") ? (
              <Typography
                variant='body1'
                className='connectingContinueButtonContent'
              >
                Terminer définitivement l'enquête
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography
                variant='body1'
                className='connectingContinueButtonContent'
              >
                oncludere definitivamente il sondaggio
              </Typography>
            ) : (
              <Typography
                variant='body1'
                className='connectingContinueButtonContent'
              >
                Umfrage definitiv beenden
              </Typography>
            )}
            <ArrowForwardIosSharpIcon />
          </BottomNavigation> */}
        </Container>
        <BottomNav
          handleBtn={this.dialogHandler}
          btnParam={"true"}
          backBtnState={false}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Terminer définitivement l'enquête"
              : window.location.pathname.includes("/it")
              ? "Concludere definitivamente il sondaggio"
              : window.location.pathname.includes("/en")
              ? "Definitely end the survey"
              : "Umfrage definitiv beenden"
          }
        />

        {/* <NavBar state={this.state} /> */}
      </>
    );
  }
}

export default withRouter(EndSurvey);
