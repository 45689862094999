import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import addStation from "../../../assets/icons/addEclipse.svg";
import NewConnectingPoint from "./NewConnectingPoint";
import RemoveCircleSharpIcon from "@material-ui/icons/RemoveCircleSharp";
import TransportType from "./TransportType";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import InfoIcon from "@material-ui/icons/Info";
// import CloseIcon from "@material-ui/icons/Close";
import "./styles.css";
import BottomNav from "../../../Shared/BottomNav";
import Popup from "../../../Shared/Popup";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: window.localStorage.dialogMoreRoutes,
      tripsData: "",
      dD: null,
      DepartureStationName: null,
      DepartureStationId: null,
      ArrivalStationName: null,
      ArrivalStationId: null,
      MeansOfTravel: null,
      ExtraStops: null,
      screenWidth: null,
      help: "firstTimeOutChange",
      counter: 0,
      popupDisplay: false,
      popup: {
        text: window.location.pathname.includes("/fr")
          ? "Veuillez cliquer sur le + et saisir individuellement chaque lieu de correspondance."
          : window.location.pathname.includes("/it")
          ? "Clicchi sul simbolo «più» e registri ogni punto di cambio singolarmente."
          : window.location.pathname.includes("/en")
          ? "Please enter all the places where you changed."
          : "Bitte erfassen Sie alle Umsteigeorte mittels Plus-Zeichen.",
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.backButtonChecker();
    if (
      this.props.state.screenWidth < 620 &&
      window.localStorage.firstTimeLogin &&
      !window.localStorage.umstDialog
    ) {
      this.togglePopup();
    }
  }
  //Toggle popup
  togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("umstDialog", true);
    if (e) {
      if (e.target === e.currentTarget)
        this.setState({ popupDisplay: !this.state.popupDisplay });
      return;
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };

  dialogOpen = () => {
    this.togglePopup();
  };

  render() {
    // console.log('STATE: ', this.props);

    return (
      <React.Fragment>
        <CssBaseline />
        <Popup
          display={this.state.popupDisplay}
          content={this.state.popup}
          hidePopup={this.togglePopup}
        />

        {this.props.state.connectingPointScreen === "newPoint" ? (
          <NewConnectingPoint
            state={this.props.state}
            handleConnectingPoint={this.props.handleConnectingPoint}
            backButtonHandler={this.props.backButtonHandler}
          />
        ) : this.props.state.connectingPointScreen === "transportType" ? (
          <TransportType
            state={this.props.state}
            handleHomeScreen={this.props.handleHomeScreen}
            handleStartTravel={this.props.handleStartTravel}
            handleEndTravel={this.props.handleEndTravel}
            handleNewJourney={this.props.handleNewJourney}
            handleExtraStationsState={this.props.handleExtraStationsState}
            stationsPopper={this.props.stationsPopper}
            handleViaStationTravelType={this.props.handleViaStationTravelType}
            viaStationSubmit={this.props.viaStationSubmit}
            loadingOn={this.props.loadingOn}
            loadingOff={this.props.loadingOff}
            backButtonHandler={this.props.backButtonHandler}
          />
        ) : (
          <>
            <div className="_container">
              <Container
                maxWidth="false"
                className="StartortScreen"
                disableGutters="true"
              >
                {this.props.state.screenWidth < 620 ? (
                  <InfoIcon
                    onClick={this.dialogOpen}
                    className="helpModalButton"
                  />
                ) : (
                  <Container
                    maxWidth={false}
                    disableGutters
                    className="infoContainer"
                  >
                    {window.location.pathname.includes("/fr") ? (
                      <Typography
                        variant="h5"
                        className="firstTimeDialogContent"
                      >
                        Veuillez cliquer sur le + et saisir individuellement
                        chaque lieu de correspondance.
                      </Typography>
                    ) : window.location.pathname.includes("/it") ? (
                      <Typography
                        variant="h5"
                        className="firstTimeDialogContent"
                      >
                        Clicchi sul simbolo «più» e registri ogni punto di
                        cambio singolarmente.
                      </Typography>
                    ) : window.location.pathname.includes("/en") ? (
                      <Typography
                        variant="h5"
                        className="firstTimeDialogContent"
                      >
                        Please enter the place where you changed station.
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        className="firstTimeDialogContent"
                      >
                        Bitte erfassen Sie alle Umsteigeorte mittels
                        Plus-Zeichen.
                      </Typography>
                    )}
                  </Container>
                )}

                <Container maxWidth="false" className="connectingStation">
                  <Typography variant="h5">
                    {this.props.state.startPointName}
                  </Typography>
                  <Typography>
                    {this.props.state.ExtraStops === null ? (
                      <img
                        onClick={() =>
                          this.props.handleConnectingPointScreen("newPoint", 0)
                        }
                        src={addStation}
                        alt="Add Station"
                        className="addConnectingStation"
                      />
                    ) : (
                      <>
                        <img
                          onClick={() =>
                            this.props.handleConnectingPointScreen(
                              "newPoint",
                              0
                            )
                          }
                          src={addStation}
                          alt="Add Station"
                          className="addConnectingStation"
                          height="98px"
                        />
                        {this.props.state.initExtraStops.map((stop, index) => (
                          <Typography variant="h5" className="connectionStop">
                            {stop.ViaStationName}{" "}
                            <RemoveCircleSharpIcon
                              onClick={() =>
                                this.props.removeInitConnectingPoint(
                                  stop.ViaStationName
                                )
                              }
                            />
                            <br />
                            <img
                              onClick={() =>
                                this.props.handleConnectingPointScreen(
                                  "newPoint",
                                  index + 1
                                )
                              }
                              src={addStation}
                              alt="Add Station"
                              className="addConnectingStation"
                              height="98px"
                            />
                          </Typography>
                        ))}
                      </>
                    )}
                  </Typography>
                  <Typography variant="h5">
                    {this.props.state.endPointName}
                  </Typography>
                </Container>
                {/* <BottomNavigation
              onClick={() =>
                this.props.handleConnectingPointScreenButton("transportType")
              }
              className='homeCompleteButton connectingContinueButton'
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <Typography
                variant='body1'
                className='connectingContinueButtonContent'
              >
                {window.location.pathname.includes("/fr")
                  ? "Aller aux moyens de transport"
                  : window.location.pathname.includes("/it")
                  ? "Avanti ai mezzi di trasporto"
                  : "Weiter zu den Transportmitteln"}
              </Typography>
              <ArrowForwardIosSharpIcon />
            </BottomNavigation> */}
              </Container>
            </div>
            <BottomNav
              handleBtn={this.props.handleConnectingPointScreenButton}
              btnParam={"transportType"}
              btnText={
                window.location.pathname.includes("/fr")
                  ? "CONTINUER"
                  : window.location.pathname.includes("/it")
                  ? "AVANTI"
                  : window.location.pathname.includes("/en")
                  ? "CONTINUE"
                  : "WEITER"
              }
              backBtnState={this.props.state.back}
              backButtonHandler={this.props.backButtonHandler}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
