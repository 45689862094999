/* 

Custom React Hook for easy synchronization of react state and local storage 
Code taken from this site: https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
Caution: Not safe for server-side rendering (Not an issue in this project)


To use this hook in a component:
[value, setValue] = useLocalStorage("myKey", 0)

"myKey" is the key under which the value is stored in local storage
0 is the default value in this case

*/

import { useState, useEffect } from "react";

function useLocalStorage(key, defaultValue) {

  const [value, setValue] = useState(() => {
    const storageValue = window.localStorage.getItem(key);
    return storageValue !== null
      ? JSON.parse(storageValue)
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage