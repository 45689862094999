import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Terms and Conditions",
    };
  }

  render() {
    return (
      <div>
        <CssBaseline />
        <Container className='termsContainer'>
          {window.location.pathname.includes("/fr") ? (
            <Typography variant='h4' gutterBottom>
              Conditions de participation
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography variant='h4' gutterBottom>
              Condizioni di partecipazione
            </Typography>
          ) : window.location.pathname.includes("/en") ? (
            <Typography variant='h4' gutterBottom>
              Conditions of participation
            </Typography>
          ) : (
            <Typography variant='h4' gutterBottom>
              Wettbewerbsbedingungen
            </Typography>
          )}
          <Divider />

          {window.location.pathname.includes("/fr") ? (
            <Typography
              variant='body1'
              gutterBottom
              className='typographyTerms'
            >
              Les tirages au sort ne sont pas publics et ne s'effectuent pas en
              présence d'un notaire. Les gagnants sont informés directement. Les
              participants sont responsables de transmettre leurs coordonnées
              correctes. L'Alliance SwissPass n'assume aucune responsabilité
              pour la remise incorrecte de prix qui résulterait de coordonnées
              erronées. Les prix mensuels sont tirés au sort chaque mois. Le
              tirage au sort du prix principal s'effectue en juin après la fin
              du traitement des données. Aucune correspondance n'est échangée au
              sujet des tirages au sort. En participant au concours, vous
              acceptez ces conditions. La version allemande des conditions de
              participation fait foi.
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography
              variant='body1'
              gutterBottom
              className='typographyTerms'
            >
              I sorteggi tra i partecipanti avvengono a porte chiuse senza la
              supervisione di un notaio. I vincitori vengono informati
              direttamente. I partecipanti sono responsabili della corretta
              trasmissione dei loro dati di contatto. Non ci assumiamo alcuna
              responsabilità per il conseguente invio non corretto del premio. I
              premi mensili vengono sorteggiati mensilmente. L'estrazione del
              premio principale avverrà al termine dell’elaborazione dei dati,
              ogni volta a giugno. Sui sorteggi non si tiene alcuna
              corrispondenza. È escluso il ricorso alle vie legali. Con la
              partecipazione al concorso lei accetta le presenti condizioni. Fa
              stato il testo in lingua tedesca delle condizioni di
              partecipazione.
            </Typography>
          ) : window.location.pathname.includes("/en") ? (
            <Typography
              variant='body1'
              gutterBottom
              className='typographyTerms'
            >
              The prize draw will take place in camera. The draw will not be
              supervised by a notary. Winners will be notified directly.
              Participants are responsible for the correct submission of their
              contact details. No liability will be accepted for any resulting
              incorrect delivery of prizes. The monthly prizes will be drawn
              monthly. The main prize will be drawn each June after completion
              of the data processing. No correspondence will be entered into
              about the prize draws. The legal process is excluded. By
              participating in the competition, you accept these conditions. The
              german text of the conditions of participation is authoritative.
            </Typography>
          ) : (
            <Typography
              variant='body1'
              gutterBottom
              className='typographyTerms'
            >
              Die Verlosungen unter den Teilnehmenden finden unter Ausschluss
              der Öffentlichkeit statt. Die Verlosung wird nicht notariell
              beaufsichtigt. Die Gewinner werden direkt benachrichtigt. Die
              Teilnehmenden sind für die korrekte Übermittlung Ihrer
              Kontaktdaten verantwortlich. Für daraus entstehende nicht korrekte
              Gewinnzustellung wird keine Haftung übernommen. Die Monatspreise
              werden monatlich verlost. Die Ziehung des Hauptpreises erfolgt
              nach Abschluss der Datenverarbeitung jeweils im Juni. Über die
              Verlosungen wird keine Korrespondenz geführt. Der Rechtsweg ist
              ausgeschlossen. Mit der Teilnahme am Wettbewerb akzeptieren Sie
              diese Bedingungen. Massgebend ist der Deutsche Text der
              Teilnahmebedingungen.
            </Typography>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(Main);
