import React, { useState } from "react";
import { useRouteMatch, useLocation, Switch, Route, Redirect } from "react-router-dom";

import AppBar from "../../Shared/AppBar";
import LanguageBar from "../../Shared/LanguageBar";
import BottomNav from "../../Shared/BottomNav";

import AuthenticationForm from "./AuthenticationForm";
import PointOfSaleForm from "./PointOfSaleForm";
import TravelDateForm from "./TravelDateForm";

import { BOTTOM_NAV_BACK_BUTTON, BOTTOM_NAV_NEXT_BUTTON, COOP_CONTEST_NEXT_BUTTON } from "./Translations";
import CoopContestForm from "./CoopContestForm";

export default function CoopChannel() {
  const { url } = useRouteMatch();
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [backCallback, setBackCallback] = useState(() => () => {});
  const [nextCallback, setNextCallback] = useState(() => () => {});
  let error = false;

  function handleBackClick(e) {
    e.preventDefault();
    backCallback();
  }

  function handleNextClick(e) {
    e.preventDefault();
    nextCallback();
  }

  // AppBar and its child components is dependant on a state object with a lot of unrelated properties in order to work
  // I am faking this state object here (hopefully with all props they need)
  const fakeState = { page: "COOP", loading: loading };

  return (
    <>
      <AppBar state={fakeState} />
      <div className="pageTitle _container">
        <div className="pageTitle-language">
          <h2>{pageTitle}</h2>
          <LanguageBar />
        </div>
      </div>
      <div className="_container infoContainer">
        <Switch>
          <Route path={`${url}/authentication`}>
            <AuthenticationForm
              setPageTitle={setPageTitle}
              setLoading={setLoading}
              setShowBackButton={setShowBackButton}
              setBackCallback={setBackCallback}
              setNextCallback={setNextCallback}
            />
          </Route>
          <Route path={`${url}/point-of-sale`}>
            <PointOfSaleForm
              setPageTitle={setPageTitle}
              setLoading={setLoading}
              setShowBackButton={setShowBackButton}
              setBackCallback={setBackCallback}
              setNextCallback={setNextCallback}
            />
          </Route>
          <Route path={`${url}/travel-date`}>
            <TravelDateForm
              setPageTitle={setPageTitle}
              setLoading={setLoading}
              setShowBackButton={setShowBackButton}
              setBackCallback={setBackCallback}
              setNextCallback={setNextCallback}
            />
          </Route>
          <Route path={`${url}/contest`}>
            <CoopContestForm
              setPageTitle={setPageTitle}
              setLoading={setLoading}
              setShowBackButton={setShowBackButton}
              setBackCallback={setBackCallback}
              setNextCallback={setNextCallback}
            />
          </Route>
        </Switch>
        {(location.pathname.endsWith("coop") || location.pathname.endsWith("COOP")) && (
          <Redirect to={`${url}/authentication`} />
        )}

        {error && <p className="error-message">Validierungsfehler, bitte komplettieren Sie Ihre Daten.</p>}
      </div>

      <BottomNav
        isPOSForm={true}
        hideBbtnArror={false}
        backBtnState={showBackButton}
        backButtonHandler={(e) => handleBackClick(e)}
        handleBtn={(e) => handleNextClick(e)}
        backBtnText={BOTTOM_NAV_BACK_BUTTON}
        btnText={location.pathname.endsWith("contest") ? COOP_CONTEST_NEXT_BUTTON : BOTTOM_NAV_NEXT_BUTTON}
        flexJustify={"flex-end"}
      />
    </>
  );
}
