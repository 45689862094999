import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import AppBar from "../../Shared/AppBar";
// import NavBar from "../../Shared/NavBar";
import PageTitle from "../../Shared/PageTitle";
import Main from "./Main";
import BottomNav from "../../Shared/BottomNav";

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "FAQ",
      back: true,
      value: "information",
    };
  }

  componentDidMount() {
    this.isUserLogedIn();
  }

  isUserLogedIn = () => {
    var auth = localStorage.AccessToken;
    const areUserDataFilled = localStorage.getItem("userDataFilled")
      ? localStorage.getItem("userDataFilled")
      : false;
    if (auth === undefined && !areUserDataFilled) {
      localStorage.setItem("userDataFilled", false);
      window.location.reload();
    }
  };

  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken;
    if (auth === undefined) {
      localStorage.setItem("userDataFilled", false);
      localStorage.clear();
      this.props.history.push("/");
    }
  };

  backButtonHandler = () => {
    window.history.back()
  };

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} />
        <PageTitle state={this.state} />
        <Main />
        {/* <NavBar state={this.state} /> */}
        <BottomNav
          btnParam={"hide"}
          backBtnState={true}
          backButtonHandler={this.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Information);
