import {
  Radio,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import "./style.css";
import React, { Component } from "react";

class Produkt extends Component {
  constructor(props) {
    super(props);

    let savedPOSFormsStates =
      JSON.parse(localStorage.getItem("POSFormsState")) || {};

    let defaultProductState = savedPOSFormsStates
      ? savedPOSFormsStates["produkt"]
      : null;

    this.state = {
      produkt: defaultProductState,
    };
  }

  handleInputFormChange = (event) => {
    this.setState({ produkt: event.target.value });
    this.props.handleStateChange("produkt", event.target.value);
  };

  render() {
    return (
      <div>
        <FormControl className="radio-buttons-container">
          <Typography
            variant="h5"
            className="firstTimeDialogContent accordonMain"
          >
            {window.location.pathname.includes("/it")
              ? "La preghiamo di comunicarci che tipo di carta giornaliera ha acquistato."
              : window.location.pathname.includes("/fr")
              ? "Veuillez nous indiquer quel type de carte journalière vous avez acheté."
              : window.location.pathname.includes("/en")
              ? "Please let us know which type of day pass you purchased."
              : "Bitte teilen Sie uns mit, welche Art von Tageskarte Sie gekauft haben."}
          </Typography>
          <RadioGroup
            className="radio-buttons-group"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={this.state.produkt}
            name="radio-buttons-group"
          >
            <div className="radio-button-input">
              <FormControlLabel
                className="radio-input"
                value="TK HTA"
                control={<Radio />}
                label={
                  window.location.pathname.includes("/it")
                    ? "Carta giornaliera a metà-prezzo"
                    : window.location.pathname.includes("/fr")
                    ? "Carte journalière pour demi-tarif"
                    : window.location.pathname.includes("/en")
                    ? "Day Pass for the Half Fare travelcard"
                    : "Tageskarte zum Halbtax"
                }
                onChange={this.handleInputFormChange}
              />
            </div>
            <div className="radio-button-input">
              <FormControlLabel
                className="radio-input"
                value="TK FVP"
                control={<Radio />}
                label={
                  window.location.pathname.includes("/it")
                    ? "Carta giornaliera FVP"
                    : window.location.pathname.includes("/fr")
                    ? "Carte journalière FVP"
                    : window.location.pathname.includes("/en")
                    ? "FVP Day Pass"
                    : "FVP Tageskarte"
                }
                onChange={this.handleInputFormChange}
              />
            </div>
            <div className="radio-button-input">
              <FormControlLabel
                className="radio-input"
                value="TK Schule"
                control={<Radio />}
                label={
                  window.location.pathname.includes("/it")
                    ? "Carta giornaliera per le scuole"
                    : window.location.pathname.includes("/fr")
                    ? "Carte journalière École"
                    : window.location.pathname.includes("/en")
                    ? "Day Pass for schools"
                    : "Schultageskarte"
                }
                onChange={this.handleInputFormChange}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default Produkt;
