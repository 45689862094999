/* 
Centralized file with all translation strings for CoopChannel
*/

// CoopChannel index.js BottomNav

export const BOTTOM_NAV_BACK_BUTTON = window.location.pathname.includes("/fr")
  ? "RETOUR"
  : window.location.pathname.includes("/it")
  ? "RITORNO"
  : window.location.pathname.includes("/en")
  ? "RETURN"
  : "ZURÜCK";

export const BOTTOM_NAV_NEXT_BUTTON = window.location.pathname.includes("/fr")
  ? "CONTINUER"
  : window.location.pathname.includes("/it")
  ? "AVANTI"
  : window.location.pathname.includes("/en")
  ? "CONTINUE"
  : "WEITER";

// AuthenticationForm

export const AUTHENTICATION_FORM_TITLE = window.location.pathname.includes("/it")
  ? "Sondaggio sulla carta giornaliera"
  : window.location.pathname.includes("/fr")
  ? "Sondage sur la carte journalière"
  : window.location.pathname.includes("/en")
  ? "Survey on the day pass"
  : "Umfrage zur Tageskarte";

export const AUTHENTICATION_FORM_SUBTITLE = window.location.pathname.includes("/it")
  ? "La preghiamo di inserire il suo indirizzo e-mail."
  : window.location.pathname.includes("/fr")
  ? "Veuillez saisir votre adresse e-mail."
  : window.location.pathname.includes("/en")
  ? "Please enter your mail address."
  : "Bitte geben Sie Ihre Mailadresse ein.";

export const AUTHENTICATION_FORM_EMAIL_LABEL = window.location.pathname.includes("/it")
  ? "E-mail"
  : window.location.pathname.includes("/fr")
  ? "E-mail"
  : window.location.pathname.includes("/en")
  ? "E-Mail"
  : "E-Mail";

export const AUTHENTICATION_FORM_EMAIL_PLACEHOLDER = window.location.pathname.includes("/it")
  ? "La preghiamo di inserire il suo indirizzo e-mail."
  : window.location.pathname.includes("/fr")
  ? "Veuillez saisir votre adresse e-mail."
  : window.location.pathname.includes("/en")
  ? "Please enter your mail address."
  : "Bitte geben Sie Ihre Mailadresse ein.";

export const AUTHENTICATION_FORM_EMAIL_MISSING = window.location.pathname.includes("/fr")
  ? "Veuillez saisir votre adresse e-mail."
  : window.location.pathname.includes("/it")
  ? "Immetta il suo indirizzo e-mail."
  : window.location.pathname.includes("/en")
  ? "Email cannot be empty."
  : "Bitte geben Sie Ihre Mailadresse ein.";

export const AUTHENTICATION_FORM_EMAIL_INVALID = window.location.pathname.includes("/fr")
  ? "S'il vous plaît, mettez une adresse email valide"
  : window.location.pathname.includes("/it")
  ? "Si prega di inserire un indirizzo email valido"
  : window.location.pathname.includes("/en")
  ? "Please enter a valid email address"
  : "Bitte geben Sie eine gültige E-Mail Adresse an";

export const AUTHENTICATION_FORM_CONTEST_BUTTON = window.location.pathname.includes("/it")
  ? "Vorrei solo partecipare al concorso."
  : window.location.pathname.includes("/fr")
  ? "Je veux uniquement participer au concours."
  : window.location.pathname.includes("/en")
  ? "I only want to take part in the prize draw."
  : "Ich möchte nur am Wettbewerb teilnehmen";

// PointOfSale

export const POINT_OF_SALE_TITLE = window.location.pathname.includes("/it")
  ? "Punto vendita"
  : window.location.pathname.includes("/fr")
  ? "Point de vente"
  : window.location.pathname.includes("/en")
  ? "Point of sale"
  : "Verkaufsstelle";

export const POINT_OF_SALE_SUBTITLE = window.location.pathname.includes("/it")
  ? "Per favore, indicaci dove è stata acquistata la carta giornaliera."
  : window.location.pathname.includes("/fr")
  ? "Veuillez indiquer où la carte journalière a été achetée"
  : window.location.pathname.includes("/en")
  ? "Please let us know where the day pass was purchased."
  : "Bitte teilen Sie uns mit, wo die Tageskarte gekauft wurde.";

export const POINT_OF_SALE_STORE = window.location.pathname.includes("/it")
  ? "Filiale (Coop/Interdiscount)"
  : window.location.pathname.includes("/fr")
  ? "Filiale (Coop/Interdiscount)"
  : window.location.pathname.includes("/en")
  ? "Store (Coop/Interdiscount)"
  : "Filiale (Coop/Interdiscount)";

export const POINT_OF_SALE_ONLINE_SHOP = window.location.pathname.includes("/it")
  ? "Online-Shop"
  : window.location.pathname.includes("/fr")
  ? "Magasin en ligne"
  : window.location.pathname.includes("/en")
  ? "Online Shop"
  : "Online-Shop";

export const POINT_OF_SALE_UNKNOWN = window.location.pathname.includes("/it")
  ? "Sconosciuta"
  : window.location.pathname.includes("/fr")
  ? "Inconnue"
  : window.location.pathname.includes("/en")
  ? "Not known"
  : "Nicht bekannt";

export const POINT_OF_SALE_ERROR = window.location.pathname.includes("/fr")
  ? "Veuillez indiquer l'emplacement de la filiale"
  : window.location.pathname.includes("/it")
  ? "Inserisci l'ubicazione della filiale"
  : window.location.pathname.includes("/en")
  ? "Please enter the location of the  store (Coop/Interdiscount)"
  : "Bitte geben Sie den Standort der Filiale ein.";

// TravelDateForm
export const TRAVEL_DATE_FORM_TITLE = window.location.pathname.includes("/it")
  ? "Data del viaggio"
  : window.location.pathname.includes("/fr")
  ? "Date de voyage"
  : window.location.pathname.includes("/en")
  ? "Date of travel"
  : "Reisedatum";

export const TRAVEL_DATE_FORM_SUBTILE = window.location.pathname.includes("/it")
  ? "La preghiamo di comunicarci che giorno era in viaggio con la carta giornaliera."
  : window.location.pathname.includes("/fr")
  ? "Veuillez nous indiquer le jour pendant lequel vous avez utilisé la carte journalière."
  : window.location.pathname.includes("/en")
  ? "Please let us know on which day you were traveling with the day pass."
  : "Bitte teilen Sie uns mit, an welchem Tag Sie mit der Tageskarte unterwegs waren.";

export const TRAVEL_DATE_FORM_DATE_ERROR = window.location.pathname.includes("/fr")
  ? "Veuillez indiquer une date de voyage."
  : window.location.pathname.includes("/it")
  ? "Inserisca una data di viaggio."
  : window.location.pathname.includes("/en")
  ? "TravelDate cannot be empty."
  : "Geben Sie ein Reisedatum ein.";

export const TRAVEL_DATE_FORM_RECAPTCHA_ERROR = window.location.pathname.includes("/fr")
  ? "Veuillez activer le ReCAPTCHA."
  : window.location.pathname.includes("/it")
  ? "Si prega di attivare il ReCAPTCHA."
  : window.location.pathname.includes("/en")
  ? "Please activate the ReCAPTCHA."
  : "Aktivieren Sie das ReCAPTCHA.";

// CoopContestForm

export const COOP_CONTEST_TITLE = window.location.pathname.includes("/fr")
  ? "Concurrence"
  : window.location.pathname.includes("/it")
  ? "Concorrenza"
  : window.location.pathname.includes("/en")
  ? "Competition"
  : "Wettbewerb";

export const COOP_CONTEST_SEX_MR = window.location.pathname.includes("/fr")
  ? "Monsieur"
  : window.location.pathname.includes("/it")
  ? "Signor"
  : window.location.pathname.includes("/en")
  ? "Mr"
  : "Herr";

export const COOP_CONTEST_SEX_MRS = window.location.pathname.includes("/fr")
  ? "Madame"
  : window.location.pathname.includes("/it")
  ? "Signora"
  : window.location.pathname.includes("/en")
  ? "Mrs"
  : "Frau";

export const COOP_CONTEST_NEXT_BUTTON = window.location.pathname.includes("/fr")
  ? "Participer"
  : window.location.pathname.includes("/it")
  ? "Partecipare"
  : window.location.pathname.includes("/en")
  ? "Enter"
  : "Teilnehmen";

export const COOP_CONTEST_ERROR_FIELDS_MISSING = window.location.pathname.includes("/fr")
  ? "Veuillez remplir tous les champs obligatoires"
  : window.location.pathname.includes("/it")
  ? "Si prega di compilare tutti i campi obbligatori"
  : window.location.pathname.includes("/en")
  ? "Please fill out all required fields"
  : "Bitte füllen Sie alle erforderlichen Felder aus";

export const COOP_CONTEST_ERROR_MULTI_REGISTRATION = window.location.pathname.includes("/fr")
  ? "Vous ne pouvez participer qu'une seule fois. Votre adresse e-mail est déjà enregistrée dans le sondage."
  : window.location.pathname.includes("/it")
  ? "Puoi partecipare una sola volta. Il tuo indirizzo email è già registrato nel sondaggio."
  : window.location.pathname.includes("/en")
  ?  "You can only participate once. Your email address is already registered in the survey."
  : "Sie können nur einmal teilnehmen. Ihre Mailadresse ist bereits in der Umfrage registriert.";