import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import AppBar from "../../Shared/AppBar";
import NavBar from "../../Shared/NavBar";
import PageTitle from "../../Shared/PageTitle";
import Main from "./Main";
import "./styles.css";

class Settings extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Paramètres",
          back: true,
          value: "settings",
          loading: false,
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Impostazioni",
          back: true,
          value: "settings",
          loading: false,
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          page: "Settings",
          back: true,
          value: "settings",
          loading: false,
        })
      : (this.state = {
          page: "Einstellungen",
          back: true,
          value: "settings",
          loading: false,
        });
  }

  componentDidMount() {
    this.isUserAuthenticated();
  }

  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken;
    if (auth === undefined) {
      localStorage.clear();
      this.props.history.push("/");
    }
  };

  loadingScreen = () => {
    this.setState({
      loading: true,
    });
  };

  backButtonHandler = () => {
    if (
      this.state.page === "Einstellungen" ||
      this.state.page === "Il mio profilo" ||
      this.state.page === "Mon profil" ||
      this.state.page === "Settings"
    ) {
      this.props.history.push("home");
    }
  };

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} />
        <PageTitle state={this.state} />
        <Main
          loadingScreen={this.loadingScreen}
          backButtonHandler={this.backButtonHandler}
        />
        <NavBar state={this.state} />
      </React.Fragment>
    );
  }
}

export default withRouter(Settings);
