import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BottomNav from "../../../Shared/BottomNav";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import "./styles.css";
import Popup from "../../../Shared/Popup";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: window.localStorage.dialogStartort,
      stations: [],
      tripsData: [],
      uniqueFavs: null,
      unknownStation: true,
      searchField: "",
      selectedName: null,
      code: null,
      display: "hide",
      lat: "",
      lon: "",
      nearbyStation: "",
      popupDisplay: false,
      popup: {
        text: window.location.pathname.includes("/fr")
          ? "Veuillez sélectionner votre arrêt de départ."
          : window.location.pathname.includes("/it")
          ? "Selezioni la sua fermata di partenza."
          : window.location.pathname.includes("/en")
          ? "Please state where you started your journey."
          : "Bitte wählen Sie Ihre Start-Haltestelle.",
      },
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.handleUser();
    this.props.backButtonChecker();
  }

  componentWillUnmount() {
    this.setState({ code: null, selectedName: null });
  }

  componentDidMount() {
    // this.setState({
    //   searchField: this.props.state.startPointName,
    // });
    setTimeout(() => {
      this.keyNavigation();
      this.keyEnter();
    }, 500);
    if (
      this.props.state.screenWidth < 620 &&
      window.localStorage.firstTimeLogin &&
      !window.localStorage.startortDialog
    ) {
      this.togglePopup();
    }
  }

  keyEnter = () => {
    document.addEventListener("keydown", function (event) {
      if (event.which === 13) {
        const selectedItem = document.getElementsByClassName("selected")[0] || document.querySelector(".searchResults li");
        if(selectedItem) selectedItem.click();
      }
    });
  };

  keyNavigation = () => {
    var ul = document.getElementById("list");
    var liSelected;
    var index = -1;
    var next;

    document.addEventListener(
      "keydown",
      function (event) {
        var len = ul.getElementsByTagName("li").length - 1;
        if (event.which === 40) {
          index++;
          //down
          if (liSelected) {
            removeClass(liSelected, "selected");
            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index <= len) {
              liSelected = next;
            } else {
              index = 0;
              liSelected = ul.getElementsByTagName("li")[0];
            }
            addClass(liSelected, "selected");
            // console.log(index);
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[0];
            addClass(liSelected, "selected");
          }
        } else if (event.which === 38) {
          //up
          if (liSelected) {
            removeClass(liSelected, "selected");
            index--;
            // console.log(index);
            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index >= 0) {
              liSelected = next;
            } else {
              index = len;
              liSelected = ul.getElementsByTagName("li")[len];
            }
            addClass(liSelected, "selected");
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[len];
            addClass(liSelected, "selected");
          }
        }
      },
      false
    );

    function removeClass(el, className) {
      if(!el) return
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    }

    function addClass(el, className) {
      if(!el) return
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  };

  dynamicTextSize = () => {
    var button;
    var that;
    var textLength;
    this.state.uniqueFavs.slice(0, 8).forEach((station) => {
      if (station[0]) {
        // eslint-disable-next-line
        (button = document.getElementById("departure" + station[1])),
          (that = document.getElementById("departure" + station[1]).innerHTML),
          // console.log('THAT: ', that),
          (textLength = that.length),
          // console.log('TEXT LENGTH DEPARTURES: ', textLength),
          textLength > 30
            ? button.classList.add("font5")
            : textLength > 20
            ? button.classList.add("font10")
            : textLength > 10
            ? button.classList.add("font14")
            : null;
      }
    });
  };

  getCurrentLocation = () => {
    // console.log('GETTING LOCATION');
    if ("geolocation" in navigator) {
      // console.log("Location Available");
      this.initGeolocation();
    } else {
      console.log("Location Not Available");
    }
  };

  initGeolocation = () => {
    var latitude = "";
    var longitude = "";
    let currentComponent = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      window.localStorage.removeItem("Latitude");
      window.localStorage.removeItem("Longitude");
      // console.log("Latitude is :", latitude);
      // console.log("Longitude is :", longitude);
      window.localStorage.setItem("Latitude", latitude);
      window.localStorage.setItem("Longitude", longitude);
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Station/SearchStations?`;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", window.localStorage.AccessToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        proxyUrl +
          targetUrl +
          "longitude=" +
          window.localStorage.Longitude +
          "&latitude=" +
          window.localStorage.Latitude,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          result.Message ===
          "Stations are not found for the provided geo-location!"
            ? currentComponent.setState({ stations: [] })
            : currentComponent.setState({ stations: result });
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
    this.setState({
      lat: window.localStorage.Latitude,
      lon: window.localStorage.Longitude,
    });
  };

  //Capitalize first letter
  toUpperCaseFn(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  searchHandler(event) {
    let keyword = event.target.value;
    keyword = this.toUpperCaseFn(keyword);

    this.setState({
      [event.target.name]: keyword,
      selectedName: null,
      code: null,
      // unknownStation: true,
    });

    if (event.target.value.length > 0) {
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Station/GetStations`;

      axios
        .get(proxyUrl + targetUrl, {
          headers: { Authorization: window.localStorage.AccessToken },
          params: { query: " " + event.target.value },
        })
        .then((response) => {
          let newStations = response.data;
          if (
            newStations === undefined ||
            newStations.toString().trim().length === 0
          ) {
            newStations = [];
          }

          this.setState(
            {
              stations: newStations,
            },
            () => {
              this.checkStationExistence();
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            tripsData: result,
          },
          () => {
            this.uniqueFavHandler();
          }
        );
      })
      .catch((error) => console.log("error", error));
  };

  uniqueFavHandler = () => {
    if (this.state.tripsData === null) {
      // console.log('NO TRIPS');
      this.setState({
        uniqueFav: null,
      });
    } else {
      const arrivalName = [];
      const arrivalId = [];
      const departureName = [];
      const departureId = [];
      this.state.tripsData.map((station) =>
        arrivalName.push(station.ArrivalStationName)
      );
      this.state.tripsData.map((station) =>
        arrivalId.push(station.ArrivalStationId)
      );
      this.state.tripsData.map((station) =>
        departureName.push(station.DepartureStationName)
      );
      this.state.tripsData.map((station) =>
        departureId.push(station.DepartureStationId)
      );
      let uniqueArrName = [...new Set(arrivalName)];
      let uniqueArrId = [...new Set(arrivalId)];
      let uniqueDepName = [...new Set(departureName)];
      let uniqueDepId = [...new Set(departureId)];

      // console.log('uniqueArrName ON SET', uniqueArrName);
      // console.log('uniqueArrId ON SET', uniqueArrId);
      // console.log('uniqueDepName ON SET', uniqueDepName);
      // console.log('uniqueDepId ON SET', uniqueDepId);

      const joinAnDn = [];
      const joinAiDi = [];

      for (let index = 0; index < uniqueArrName.length; index++) {
        joinAnDn.push(uniqueArrName[index]);
        joinAnDn.push(uniqueDepName[index]);
        joinAiDi.push(uniqueArrId[index]);
        joinAiDi.push(uniqueDepId[index]);
      }

      // console.log('joinAnDn ON PUSH', joinAnDn);
      // console.log('joinAiDi ON PUSH', joinAiDi);

      const uniqueNames = [...new Set(joinAnDn)];
      const uniqueIds = [...new Set(joinAiDi)];

      // console.log('uniqueNames ON SET', uniqueNames);
      // console.log('uniqueIds ON SET', uniqueIds);

      const uniqueSuggestions = [];

      for (let index = 0; index < uniqueNames.length; index++) {
        uniqueSuggestions.push([uniqueNames[index], uniqueIds[index]]);
      }

      // console.log('uniqueSuggestions ON PUSH', uniqueSuggestions);

      this.setState(
        {
          uniqueFavs: uniqueSuggestions,
        },
        () => {
          this.dynamicTextSize();
        }
      );
    }
  };

  handleInputFocus = () => {
    this.setState({
      display: "block",
    });
  };

  handleInputBlur = () => {
    setTimeout(() => {
      this.setState({
        display: "hide",
      });
    }, 500);
  };

  dialogHandleOpen = () => {
    this.togglePopup();
  };

  //Toggle popup
  togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("startortDialog", true);
    if (e) {
      if (e.target === e.currentTarget)
        this.setState({ popupDisplay: !this.state.popupDisplay });
      return;
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };

  //Handle click on one of the searched Cities
  handleClickEvent = (e) => {
    let value = e.currentTarget.getAttribute("value");
    let code = e.currentTarget.getAttribute("code");
    value = this.toUpperCaseFn(value);
    this.setState({
      selectedName: value,
      searchField: value,
      stations: null,
      code: code,
    });
  };

  //Check whether the typed station exist in the db
  checkStationExistence = () => {
    this.setState({ unknownStation: true });

    if (this.state.stations?.length > 0) {
      this.state.stations.forEach((station) => {
        if (
          station.Name.toLowerCase().replace(/\s+/g, "").trim() ===
          this.state.searchField.toLowerCase().replace(/\s+/g, "").trim()
        ) {
          this.setState({ unknownStation: false });
          return;
        }
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Popup
          display={this.state.popupDisplay}
          content={this.state.popup}
          hidePopup={this.togglePopup}
        />
        {/* Info overlay */}
        <div className="_container">
          {this.props.state.screenWidth > 620 && (
            <Container
              maxWidth={false}
              disableGutters
              className="infoContainer"
            >
              {window.location.pathname.includes("/fr") ? (
                <Typography variant="h5" className="firstTimeDialogContent">
                  Veuillez sélectionner votre arrêt de départ.
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography variant="h5" className="firstTimeDialogContent">
                  Selezioni la sua fermata di partenza.
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography variant="h5" className="firstTimeDialogContent">
                  Please state where you started your journey.
                </Typography>
              ) : (
                <Typography variant="h5" className="firstTimeDialogContent">
                  Bitte wählen Sie Ihre Start-Haltestelle.
                </Typography>
              )}
            </Container>
          )}
          <Container
            maxWidth={false}
            className="StartortScreen"
            disableGutters={true}
          >
            <Container maxWidth="false" disableGutters>
              <TextField
                autoComplete="off"
                value={this.state.searchField}
                name="searchField"
                id="von"
                label={
                  window.location.pathname.includes("/fr")
                    ? "De / Départ:"
                    : window.location.pathname.includes("/it")
                    ? "Da / Partenza:"
                    : window.location.pathname.includes("/en")
                    ? "From / Start:"
                    : "Von / Start:"
                }
                fullWidth
                className="searchField"
                onChange={this.searchHandler}
              />
              <ul id="list" className="searchResults">
                {!this.state.selectedName &&
                this.state.searchField?.length > 2 &&
                this.state.unknownStation ? (
                  <li
                    onClick={(e) => this.handleClickEvent(e)}
                    // onClick={() =>
                    //   this.props.handleStartPoint(this.state.searchField, -1)
                    // }
                    value={this.state.searchField}
                    code={-1}
                  >
                    {this.state.searchField}
                  </li>
                ) : null}
                {this.state.selectedName === null &&
                  this.state.searchField?.length > 2 &&
                  this.state.stations?.length > 0 &&
                  this.state.stations.slice(0, 10).map((stations, index) => {
                    return (
                      <li
                        key={index}
                        value={stations.Name}
                        code={stations.Code}
                        onClick={(e) => this.handleClickEvent(e)}
                        // onClick={() =>
                        //   this.props.handleStartPoint(stations.Name, stations.Code)
                        // }
                      >
                        {stations.Name}
                      </li>
                    );
                  })}
              </ul>
              <Typography variant="body2" className="favouriteCopy">
                {window.location.pathname.includes("/fr")
                  ? "Propositions / Favoris"
                  : window.location.pathname.includes("/it")
                  ? "Proposte / Preferiti"
                  : window.location.pathname.includes("/en")
                  ? "Suggestions / Favorites:"
                  : "Vorschläge / Favoriten"}
              </Typography>
              <div className="connSuggestionsButton">
                {this.state.uniqueFavs === null ? null : this.state.uniqueFavs
                    .length > 0 ? (
                  this.state.uniqueFavs
                    .slice(0, 8)
                    .reverse()
                    .map((station) => {
                      if (station[0]) {
                        return (
                          <Button
                            onClick={() =>
                              this.props.handleStartPoint(
                                station[0],
                                station[1]
                              )
                            }
                            variant="contained"
                            className="stationButton"
                            disableElevation
                            size="large"
                          >
                            <span id={"departure" + station[1]}>
                              {station[0]}
                            </span>
                          </Button>
                        );
                      }
                      return "";
                    })
                ) : (
                  <Skeleton
                    animation="wave"
                    height={42}
                    className="stationSkeleton"
                  />
                )}
              </div>
            </Container>
            {this.props.state.screenWidth < 620 && (
              <InfoIcon
                onClick={this.dialogHandleOpen}
                className="helpModalButton startortInfo"
              />
            )}
          </Container>
        </div>
        <BottomNav
          handleBtn={this.state.selectedName && this.props.handleStartPoint}
          btnParam={{ name: this.state.selectedName, code: this.state.code }}
          btnText={
            window.location.pathname.includes("/fr")
              ? "CONTINUER"
              : window.location.pathname.includes("/it")
              ? "AVANTI"
              : window.location.pathname.includes("/en")
              ? "CONTINUE"
              : "WEITER"
          }
          backBtnState={this.props.state.back}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
