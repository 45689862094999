import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, TextField, Typography } from "@material-ui/core";

import { useHistory, useRouteMatch, Link } from "react-router-dom";
import useLocalStorage from "../useLocalStorage";
import "./styles.css";

import {
  AUTHENTICATION_FORM_CONTEST_BUTTON,
  AUTHENTICATION_FORM_EMAIL_INVALID,
  AUTHENTICATION_FORM_EMAIL_LABEL,
  AUTHENTICATION_FORM_EMAIL_MISSING,
  AUTHENTICATION_FORM_EMAIL_PLACEHOLDER,
  AUTHENTICATION_FORM_TITLE,
  AUTHENTICATION_FORM_SUBTITLE,
} from "../Translations";

export default function AuthenticationForm({
  setPageTitle,
  setLoading,
  setShowBackButton,
  setBackCallback,
  setNextCallback,
}) {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useLocalStorage("coopEmail", "");

  // Additionally store all form data of this form page in a Ref in order to be able to access it in handleNextClick
  // Accessing email directly in handleNextClick does not work due to the way JS binding works
  // (email would not update from default value because handleNextClick is called from the parent component and not from the inside)
  const formData = useRef({ email: email });

  // Set parent params on mount
  useEffect(() => {
    setPageTitle(AUTHENTICATION_FORM_TITLE);
    setLoading(false);
    setShowBackButton(false);
    setNextCallback(() => () => handleNextClick());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleNextClick() {
    const valid = validateForm();

    if (valid) {
      // Go to route point-of-sale
      const newPath = url.substring(0, url.lastIndexOf("/")) + "/point-of-sale";
      history.push(newPath);
    }
  }

  function handleContestClick() {
    // Go to contest route
    const newPath = url.substring(0, url.lastIndexOf("/")) + "/contest";
    history.push(newPath);
  }

  function validateForm() {
    if (isEmailValid(formData.current.email)) {
      setErrorMessage("");
      return true;
    } else if (formData.current.email !== "") {
      setErrorMessage(AUTHENTICATION_FORM_EMAIL_INVALID);
      return false;
    } else {
      setErrorMessage(AUTHENTICATION_FORM_EMAIL_MISSING);
      return false;
    }
  }

  function isEmailValid(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email.toLowerCase());
  }

  return (
    <>
      <FormControl className="radio-buttons-container">
        <Typography variant="h5" className="firstTimeDialogContent accordonMain">
          {AUTHENTICATION_FORM_SUBTITLE}
        </Typography>
        <FormControl>
          <TextField
            required
            type="email"
            label={AUTHENTICATION_FORM_EMAIL_LABEL}
            value={email}
            placeholder={AUTHENTICATION_FORM_EMAIL_PLACEHOLDER}
            onChange={(event) => {
              setEmail(event.target.value);
              formData.current.email = event.target.value;
            }}
          />
        </FormControl>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </FormControl>
      
      <div className="link-holder">
        <FormControl>
          <Link className="contest-shortcut-link" to={url.substring(0, url.lastIndexOf("/")) + "/contest"}>
            {AUTHENTICATION_FORM_CONTEST_BUTTON}
          </Link>
        </FormControl>
      </div>
    </>
  );
}
