import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "./styles.css";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import axios from "axios";
import querystring from "querystring";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogError: "",
      fade: "out",
      loginField: "Show",
      loginButton: "Show",
      emailNotifications: true,
      pushNotifications: false,
      frenchDomain: "carte-journaliere.allianceswisspass.ch",
      italianDomain: "carta-giornaliera.allianceswisspass.ch",
      englishDomain: "day-pass.allianceswisspass.ch",
    };
  }

  componentDidMount() {
    this.languageRouter();

    let params = querystring.parse(this.props.location.search);

    if (window.location.href.includes("?TNC")) {
      localStorage.clear();
      this.handleSubmitFromUrl(params["?TNC"]);
    } else {
      localStorage.clear();
    }
    if (localStorage.AccessToken === undefined) {
      localStorage.clear();
    }
  }
  //Check if user is locked
  checkLockSource = (value, language) => {
    if (value) {
      localStorage.clear();
      this.props.history.push(language + "?locked");
      window.location.pathname === "/fr"
        ? this.setState({
            dialogError:
              "Le questionnaire a déjà été finalisé, il n'est plus possible de le modifier.",
            fade: "in",
          })
        : window.location.pathname === "/it"
        ? this.setState({
            dialogError:
              "L'inchiesta è già stata conclusa. Non sono più consentite modifiche.",
            fade: "in",
          })
        : window.location.pathname === "/en"
        ? this.setState({
            dialogError:
              "You have already completed the survey. Alterations are therefore no longer possible.",
            fade: "in",
          })
        : this.setState({
            dialogError:
              "Sie haben die Umfrage bereits abgeschlossen. Änderungen sind daher nicht mehr möglich.",
            fade: "in",
          });
      setTimeout(() => {
        this.setState({ fade: "out" });
      }, 2500);
    } else {
      this.props.history.push(language + "/home");
    }
  };

  registerHandler = (b, c) => {
    this.props.loadingSetter(true)

    window.localStorage.setItem("firstTimeLogin", "true");
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("EnableDailyNotifications", b);
    urlencoded.append("EnablePushNotificationReminder", c);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then(() => {
        this.props.loadingSetter(false)
        this.customHandleSubmit(this.state.userProfile.TNC);
      })
      .catch((error) => {
        this.props.loadingSetter(false)
        console.log("error", error); 
      });
  };

  languageRouter = () => {
    const hostname = window.location.hostname.replace(/\s+/g, "");

    const defaultLanguage =
      hostname === this.state.frenchDomain ||
      hostname === this.state.frenchDomain + "/"
        ? "/fr"
        : hostname === this.state.italianDomain ||
          hostname === this.state.italianDomain + "/"
        ? "/it"
        : hostname === this.state.englishDomain ||
          hostname === this.state.englishDomain + "/"
        ? "/en"
        : "/de";
        
    if (window.location.pathname === "/") {
      this.props.history.push(defaultLanguage);
    }
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const defaultLanguage = window.location.pathname;

    this.props.loadingSetter(true)

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.props.loadingSetter(false)

        if (
          result &&
          result.Channel === "B2C/B2B" &&
          result.IsRegistered === false
        ) {
          this.registerHandler(true, true);
        }

        this.setState({
          userProfile: result.Preload,
          resultsData: result,
        });

        let language =
          defaultLanguage === "/de" || defaultLanguage === "/de/"
            ? "/de"
            : defaultLanguage === "/fr" || defaultLanguage === "/fr/"
            ? "/fr"
            : defaultLanguage === "/it" || defaultLanguage === "/it/"
            ? "/it"
            : defaultLanguage === "/en" || defaultLanguage === "/en/"
            ? "/en"
            : result.Preload.Language === 1
            ? "/de"
            : result.Preload.Language === 2
            ? "/fr"
            : result.Preload.Language === 3
            ? "/it"
            : result.Preload.Language === 4
            ? "/en"
            : "/de";

        //Check if the user has closed his survery, if not redirect /home
        this.checkLockSource(result.Preload.LockSource, language);
      })
      .catch((error) => {
        this.props.loadingSetter(false)
        console.log("error", error);
      });
  };

  registerEmailHandler = () => {
    this.setState({
      emailfield: "Show",
      emailButton: "Show",
      loginField: "Hide",
      loginButton: "Hide",
    });
  };

  customHandleSubmit = (param) => {
    this.props.loadingSetter(true)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.props.loadingSetter(false)
        this.handleUser();
      })
      .catch((error) => {
        this.props.loadingSetter(false)
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.loadingSetter(true)

    const value = event.target && event.target[0] && event.target[0].value;

    // format value to replace all characters
    // const formattedValue =
    //   value && value.replace(/[^\w\s]|_/gi, "").replace(/\s/g, "");

    const formattedValue = value.replace(/\s/g, "");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: formattedValue,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.props.loadingSetter(false)
        this.handleUser();
      })
      .catch((error) => {
        this.props.loadingSetter(false)
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Le code de participation n'est pas valable. Veuillez vérifier votre saisie puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Il codice di partecipazione non è valido; verifichi l'ingresso e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again. ",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };

  handleSubmitFromUrl = (tnc) => {
    this.props.loadingSetter(true)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: tnc,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.props.loadingSetter(false)
        this.handleUser();
      })
      .catch((error) => {
        this.props.loadingSetter(false)
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Le code de participation n'est pas valable. Veuillez vérifier votre saisie puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Il codice di partecipazione non è valido; verifichi l'ingresso e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again. ",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };
  
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div className="loginScreen">
          <Card raised={true} className={"errorDialog fade-" + this.state.fade}>
            <div className="errorContent">
              <p>{this.state.dialogError}</p>
            </div>
          </Card>
          <div className="login_screen">
            <div className="login_screen_inner">
              <div className="loginFields">
                {window.location.pathname === "/fr" ? (
                  <p>Veuillez vous connecter pour saisir vos trajets.</p>
                ) : window.location.pathname === "/it" ? (
                  <p>Acceda adesso per registrare i suoi viaggi.</p>
                ) : window.location.pathname === "/en" ? (
                  <p>Please log in to enter your journeys.</p>
                ) : (
                  <p>
                    Bitte melden Sie sich jetzt an, um Ihre Reisen zu erfassen.
                  </p>
                )}
                <form onSubmit={this.handleSubmit}>
                  <div
                    id={"loginField" + this.state.loginField}
                    className="field"
                  >
                    {window.location.pathname === "/fr" ? (
                      <TextField
                        fullWidth={true}
                        id="pin"
                        name="pin"
                        label="Code de participation"
                        variant="filled"
                      />
                    ) : window.location.pathname === "/it" ? (
                      <TextField
                        fullWidth={true}
                        id="pin"
                        name="pin"
                        label="Codice di partecipazione"
                        variant="filled"
                      />
                    ) : window.location.pathname === "/en" ? (
                      <TextField
                        fullWidth={true}
                        id="pin"
                        name="pin"
                        label="Participation code"
                        variant="filled"
                      />
                    ) : (
                      <TextField
                        fullWidth={true}
                        id="pin"
                        name="pin"
                        label="Teilnahmecode"
                        variant="filled"
                      />
                    )}
                  </div>
                  {window.location.pathname === "/fr" ? (
                    <Link className="faqLink" to="fr/information">
                      FAQ
                    </Link>
                  ) : window.location.pathname === "/it" ? (
                    <Link className="faqLink" to="it/information">
                      FAQ
                    </Link>
                  ) : window.location.pathname === "/en" ? (
                    <Link className="faqLink" to="en/information">
                      FAQ
                    </Link>
                  ) : (
                    <Link className="faqLink" to="de/information">
                      FAQ
                    </Link>
                  )}
                  <Container
                    id={"loginButton" + this.state.loginButton}
                    className="loginButtonSection"
                  >
                    {window.location.pathname === "/fr" ? (
                      <Button
                        type="submit"
                        className="loginButton"
                        variant="contained"
                      >
                        Connexion
                      </Button>
                    ) : window.location.pathname === "/it" ? (
                      <Button
                        type="submit"
                        className="loginButton"
                        variant="contained"
                      >
                        Login
                      </Button>
                    ) : window.location.pathname === "/en" ? (
                      <Button
                        type="submit"
                        className="loginButton"
                        variant="contained"
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="loginButton"
                        variant="contained"
                      >
                        Login
                      </Button>
                    )}
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
