import React, { useEffect, useState, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import useLocalStorage from "../useLocalStorage";

import { Radio, RadioGroup, Typography, FormControl, FormControlLabel } from "@material-ui/core";

import StoreDropdown from "../StoreDropdown";

import {
  POINT_OF_SALE_TITLE,
  POINT_OF_SALE_SUBTITLE,
  POINT_OF_SALE_STORE,
  POINT_OF_SALE_UNKNOWN,
  POINT_OF_SALE_ONLINE_SHOP,
  POINT_OF_SALE_ERROR,
} from "../Translations";

export default function PointOfSaleForm({
  setPageTitle,
  setLoading,
  setShowBackButton,
  setBackCallback,
  setNextCallback,
}) {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [pointOfSale, setPointOfSale] = useLocalStorage("coopPointOfSale", "");
  const [pointOfSaleStore, setPointOfSaleStore] = useLocalStorage("coopPointOfSaleStore", "");
  const [showError, setShowError] = useState(false);

  // Additionally store all form data of this form page in a Ref in order to be able to access it in handleNextClick
  // Accessing properties directly in handleNextClick does not work due to the way JS binding works
  // (properties would not update from default value because handleNextClick is called from the parent component and not from the inside)
  const formData = useRef({});

  useEffect(() => {
    formData.current.pointOfSale = pointOfSale;
    formData.current.pointOfSaleStore = pointOfSaleStore;
  }, [pointOfSale, pointOfSaleStore]);

  // Set parent params on mount
  useEffect(() => {
    setPageTitle(POINT_OF_SALE_TITLE);
    setLoading(false);
    setShowBackButton(true);
    setBackCallback(() => () => handleBackClick());
    setNextCallback(() => () => handleNextClick());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleBackClick() {
    // Go to route authentication
    const newPath = url.substring(0, url.lastIndexOf("/")) + "/authentication";
    history.push(newPath);
  }

  function handleNextClick() {
    const valid = validateForm();

    if (valid) {
      // Go to route travel-date
      const newPath = url.substring(0, url.lastIndexOf("/")) + "/travel-date";
      history.push(newPath);
    }
  }

  function validateForm() {
    const data = formData.current;
    
    if (data.pointOfSale === "store" && data.pointOfSaleStore !== "") {
      setShowError(false);
      return true;
    } else if (data.pointOfSale === "online" || data.pointOfSale === "unknown") {
      setShowError(false);
      return true;
    }
    setShowError(true);
    return false;
  }

  function handleSelect(value) {
    setPointOfSale(value);
    if (value !== "store") setPointOfSaleStore("");
  }

  return (
    <>
      <FormControl className="radio-buttons-container">
        <Typography variant="h5" className="firstTimeDialogContent accordonMain">
          {POINT_OF_SALE_SUBTITLE}
        </Typography>
        <RadioGroup
          className="radio-buttons-group"
          aria-labelledby="demo-radio-buttons-group-label"
          value={pointOfSale}
          name="radio-buttons-group"
          onChange={(event) => handleSelect(event.target.value)}
        >
          <div className="radio-button-input">
            <FormControlLabel value="unknown" control={<Radio />} label={POINT_OF_SALE_UNKNOWN} />
          </div>
          <div className="radio-button-input">
            <FormControlLabel value="online" control={<Radio />} label={POINT_OF_SALE_ONLINE_SHOP} />
          </div>
          <div className="radio-button-input">
            <FormControlLabel value="store" control={<Radio />} label={POINT_OF_SALE_STORE} />
          </div>
        </RadioGroup>
        {showError && <p className="error-message"> {POINT_OF_SALE_ERROR} </p>}
        {pointOfSale === "store" && <StoreDropdown updateSelectedValue={(value) => setPointOfSaleStore(value)} />}
      </FormControl>
    </>
  );
}
