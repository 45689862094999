import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles.css";
import { Toolbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IOSSwitch from "./IOSSwitch";
import querystring from "querystring";
import BottomNav from "../../../Shared/BottomNav";
import de from "../../../countries/de.json";
import en from "../../../countries/en.json";
import fr from "../../../countries/fr.json";
import it from "../../../countries/it.json";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: [],
      editting: false,
      firstname: "",
      lastname: "",
      sex: "",
      address1: " ",
      address2: " ",
      zip: "",
      place: "",
      country: "",
      email: "",
      fade: "out",
      emailNotifications: true,
      pushNotifications: false,
    };
    console.log("🚀 ~ Main ~ constructor ~ this.state:", this.state);
  }

  componentDidMount() {
    this.handleUser();
    console.log("🚀 ~ Main ~ componentDidMount ~ this.state:", this.state);
    // setTimeout(() => {
    //   this.setStates();
    // }, 1000);
    // setTimeout(() => {
    //   this.setStates();
    // }, 2000);
    // setTimeout(() => {
    //   this.setStates();
    // }, 3000);
  }

  setStates = () => {
    this.setState({
      firstname: this.state.userProfile.Firstname,
      lastname: this.state.userProfile.Name,
      sex: this.state.userProfile.Sex,
      address1: this.state.userProfile.Address1,
      address2: this.state.userProfile.Address2,
      zip: this.state.userProfile.ZipCode,
      place: this.state.userProfile.Place,
      country: this.state.userProfile.Country,
      email: this.state.userProfile.Email,
      emailNotifications: this.state.user.EnableDailyMailReminder,
      pushNotifications: this.state.user.EnablePushNotificationReminder,
    });
    console.log("🚀 ~ Main ~ setStates ~ this.state:", this.state);
  };

  handleChangeEmail = (e) => {
    if (this.state.emailNotifications === false) {
      this.setState({
        emailNotifications: true,
      });
      //Set language and notification
      this.updateLanguage(
        this.state.userProfile.Language,
        true,
        this.state.user.EnablePushNotificationReminder
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      this.setState({
        emailNotifications: false,
      });
      this.updateLanguage(
        this.state.userProfile.Language,
        false,
        this.state.user.EnablePushNotificationReminder
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            userProfile: result.Preload,
            user: result,
          },
          () => this.setStates()
        );
      })
      .catch((error) => console.log("error", error));
  };

  handleSubmit = (param, e) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
      })
      .then(() => {
        if (e === 1) {
          window.location.href = "/de/settings";
        }
        if (e === 2) {
          window.location.href = "/fr/settings";
        }
        if (e === 3) {
          window.location.href = "/it/settings";
        }
        if (e === 4) {
          window.location.href = "/en/settings";
        }
      })
      .catch((error) => console.log("error", error));
  };

  editStateHandler = () => {
    this.setState({
      editting: !this.state.editting,
    });
  };

  onChangeHandler = (e) => {
    const key = e.target.name; 
    let value = e.target.value; 
 
    this.setState({ [key]: value });
  };

  //Check for empty fields
  //The functions is never called, since client request to remove the validations and checkFields
  checkFields = () => {
    if (
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.sex === "" ||
      this.state.address1 === "" ||
      this.state.zip === "" ||
      this.state.place === "" ||
      this.state.country === "" ||
      this.state.email === ""
    ) {
      this.errorDialog();
      return false;
    }
    if (this.state.email && this.validateEmail(this.state.email)) {
      return true;
    }
    this.errorDialog();
    return false;
  };

  //Validate email
  validateEmail = (mail) => {
    let mailformat = /.@./;
    if (mail.match(mailformat)) return true;
    return false;
  };

  //Show error
  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: "in",
    });
    setTimeout(() => {
      this.setState({
        fade: "out",
      });
    }, 2500);
  };

  apiCall = () => {

    console.log("Setting Api Call")
    console.log("🚀 ~ Main ~ apiCall ~ this.state:", this.state);
    
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/EditUser`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "lang=de");

    var raw = JSON.stringify({
      Id: this.state.user.Id,
      EnableDailyMailReminder: this.state.user.EnableDailyMailReminder,
      EnablePushNotificationReminder:
        this.state.user.EnablePushNotificationReminder,
      Name: this.state.lastname,
      IsAdmin: false,
      IsRegistered: true,
      Preload: {
        UserEmail: this.state.userProfile.Email,
        Salutation: this.state.userProfile.Salutation,
        Pin: this.state.userProfile.Pin,
        PeriodStart: this.state.userProfile.PeriodStart,
        PeriodEnd: this.state.userProfile.PeriodEnd,
        SubmissionDeadline: this.state.userProfile.SubmissionDeadline,
        Email: this.state.email,
        ChangedBy:
          this.state.userProfile.Firstname + " " + this.state.userProfile.Name,
        MailSalutation: this.state.userProfile.MailSalutation,
        NoTravelsReason: this.state.userProfile.NoTravelsReason,
        Notes: this.state.userProfile.Notes,
        UniqueId: this.state.userProfile.UniqueId,
        ContestParticipation: this.state.userProfile.ContestParticipation,
        ImportDate: this.state.userProfile.ImportDate,
        ActivationDate: this.state.userProfile.ActivationDate,
        LockDate: this.state.userProfile.LockDate,
        ReiseblattNr: this.state.userProfile.ReiseblattNr,
        Sex: this.state.sex,
        Firstname: this.state.firstname,
        Name: this.state.name,
        Address1: this.state.address1,
        Address2: this.state.address2,
        ZipCode: this.state.zip,
        Place: this.state.place,
        Country: this.state.country,
        Language: this.state.userProfile.Language,
        ProjectCode: this.state.userProfile.ProjectCode,
        AboType: this.state.userProfile.AboType,
        LockSource: this.state.userProfile.LockSource,
        Id: this.state.userProfile.Id,
        Created: this.state.userProfile.Created,
        Changed: new Date(),
      },
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(localStorage.clear())
      .then(this.handleSubmit(this.state.userProfile.TNC))
      .catch((error) => console.log("error", error));
  };

  apiCallLanguage = (e, n, p) => {

    console.log("Setting Api Call Language")
    console.log("🚀 ~ Main ~ apiCallLanguage ~ this.state:", this.state);

    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/EditUser`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "lang=de");

    var raw = JSON.stringify({
      Id: this.state.user.Id,
      Name: this.state.user.Name,
      IsAdmin: false,
      IsRegistered: true,
      EnableDailyMailReminder: n,
      EnablePushNotificationReminder: p,
      Preload: {
        UserEmail: this.state.userProfile.Email,
        Salutation: this.state.userProfile.Salutation,
        Pin: this.state.userProfile.Pin,
        PeriodStart: this.state.userProfile.PeriodStart,
        PeriodEnd: this.state.userProfile.PeriodEnd,
        SubmissionDeadline: this.state.userProfile.SubmissionDeadline,
        Email: this.state.userProfile.Email,
        ChangedBy:
          this.state.userProfile.Firstname + " " + this.state.userProfile.Name,
        MailSalutation: this.state.userProfile.MailSalutation,
        NoTravelsReason: this.state.userProfile.NoTravelsReason,
        Notes: this.state.userProfile.Notes,
        UniqueId: this.state.userProfile.UniqueId,
        ContestParticipation: this.state.userProfile.ContestParticipation,
        ImportDate: this.state.userProfile.ImportDate,
        ActivationDate: this.state.userProfile.ActivationDate,
        LockDate: this.state.userProfile.LockDate,
        ReiseblattNr: this.state.userProfile.ReiseblattNr,
        Sex: this.state.userProfile.Sex,
        Firstname: this.state.userProfile.Firstname,
        Name: this.state.userProfile.Name,
        Address1: this.state.userProfile.Address1,
        Address2: this.state.userProfile.Address2,
        ZipCode: this.state.userProfile.ZipCode,
        Place: this.state.userProfile.Place,
        Country: this.state.userProfile.Country,
        Language: e,
        ProjectCode: this.state.userProfile.ProjectCode,
        AboType: this.state.userProfile.AboType,
        LockSource: this.state.userProfile.LockSource,
        Id: this.state.userProfile.Id,
        Created: this.state.userProfile.Created,
        Changed: new Date(),
      },
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(localStorage.clear())
      .then(() => this.handleSubmit(this.state.userProfile.TNC, e))
      .catch((error) => console.log("error", error));
  };

  callUpdatePersonalInfo() {
    this.editStateHandler();
    this.props.loadingScreen();
    this.apiCall();
    setTimeout(() => {
      this.apiCall();
    }, 2000);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  updatePersonal = () => {
    //The validations inactive with a client request.
    // if (this.checkFields()) {
    if (!this.state.email) {
      this.callUpdatePersonalInfo();
    } else if (this.state.email && this.validateEmail(this.state.email)) {
      this.callUpdatePersonalInfo();
    } else {
      this.errorDialog();
    }
    // }
  };

  updateLanguage = (e, n, p) => {
    this.props.loadingScreen();
    this.apiCallLanguage(e, n, p);
  };

  logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  render() {
    let countries = window.location.pathname.includes("/fr")
      ? fr
      : window.location.pathname.includes("/it")
      ? it
      : window.location.pathname.includes("/en")
      ? en
      : de;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className="_container-three-borders">
          <Card
            raised={true}
            className={
              "errorDialog transportDialogContent fade-" + this.state.fade
            }
          >
            <CardContent className="errorContent">
              <Typography align="center">
                {window.location.pathname.includes("/fr")
                  ? "Veuillez remplir tous les champs obligatoires"
                  : window.location.pathname.includes("/it")
                  ? "Si prega di compilare tutti i campi obbligatori"
                  : window.location.pathname.includes("/en")
                  ? "Please fill out all required fields"
                  : "Bitte füllen Sie alle erforderlichen Felder aus"}
              </Typography>
            </CardContent>
          </Card>
          <Container
            className="settingsScreen"
            maxWidth={false}
            disableGutters={true}
          >
            {this.state.editting === true ? (
              <div>
                <Container className="settingsTopSection">
                  <Toolbar disableGutters={true} className="settingsToolbar">
                    <Container disableGutters={true}>
                      <FormControl variant="outlined" className="editFields100">
                        <InputLabel>
                          {window.location.pathname.includes("/fr")
                            ? "Civilité"
                            : window.location.pathname.includes("/it")
                            ? "Appellativo"
                            : window.location.pathname.includes("/en")
                            ? "Salutation"
                            : "Anrede"}
                        </InputLabel>
                        <Select
                          name="sex"
                          value={this.state.sex}
                          onChange={this.onChangeHandler}
                          label={
                            window.location.pathname.includes("/fr")
                              ? "Civilité"
                              : window.location.pathname.includes("/it")
                              ? "Appellativo"
                              : window.location.pathname.includes("/en")
                              ? "Salutation"
                              : "Anrede"
                          }
                        >
                          <MenuItem value={1}>
                            {window.location.pathname.includes("/fr")
                              ? "Monsieur"
                              : window.location.pathname.includes("/it")
                              ? "Signor"
                              : window.location.pathname.includes("/en")
                              ? "Mr"
                              : "Herr"}
                          </MenuItem>
                          <MenuItem value={2}>
                            {window.location.pathname.includes("/fr")
                              ? "Madame"
                              : window.location.pathname.includes("/it")
                              ? "Signora"
                              : window.location.pathname.includes("/en")
                              ? "Ms"
                              : "Frau"}
                          </MenuItem>
                          <MenuItem value={0}>
                            {"   "}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        onChange={this.onChangeHandler}
                        name="firstname"
                        className="editFields50"
                        // id='outlined-basic'
                        label={
                          window.location.pathname.includes("/fr")
                            ? "Prénom"
                            : window.location.pathname.includes("/it")
                            ? "Nome"
                            : window.location.pathname.includes("/en")
                            ? "First name"
                            : "Vorname"
                        }
                        value={this.state.firstname}
                        variant="outlined"
                      />
                      <TextField
                        className="editFields50"
                        onChange={this.onChangeHandler}
                        name="lastname"
                        // id='outlined-basic'
                        label={
                          window.location.pathname.includes("/fr")
                            ? "Nom"
                            : window.location.pathname.includes("/it")
                            ? "Cognome"
                            : window.location.pathname.includes("/en")
                            ? "Last name"
                            : "Nachname"
                        }
                        value={this.state.lastname}
                        variant="outlined"
                      />

                      {this.state.userProfile.Address1 &&
                      this.state.userProfile.Address1.length > 0 ? (
                        <TextField
                          className="editFields100"
                          onChange={this.onChangeHandler}
                          name="address1"
                          // id='outlined-basic'
                          label={
                            window.location.pathname.includes("/fr")
                              ? "Adresse"
                              : window.location.pathname.includes("/it")
                              ? "Indirizzo"
                              : window.location.pathname.includes("/en")
                              ? "Address"
                              : "Adresse"
                          }
                          value={this.state.address1}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          className="editFields100"
                          onChange={this.onChangeHandler}
                          name="address1"
                          // id='outlined-basic'
                          label={
                            window.location.pathname.includes("/fr")
                              ? "Adresse"
                              : window.location.pathname.includes("/it")
                              ? "Indirizzo"
                              : window.location.pathname.includes("/en")
                              ? "Address"
                              : "Adresse"
                          }
                          variant="outlined"
                        />
                      )}
                      <TextField
                        className="editFields30"
                        onChange={this.onChangeHandler}
                        name="zip"
                        // id='outlined-basic'
                        label={
                          window.location.pathname.includes("/fr")
                            ? "NPA"
                            : window.location.pathname.includes("/it")
                            ? "NPA"
                            : window.location.pathname.includes("/en")
                            ? "Postcode"
                            : "PLZ"
                        }
                        value={this.state.zip}
                        variant="outlined"
                      />
                      <TextField
                        className="editFields50"
                        onChange={this.onChangeHandler}
                        name="place"
                        // id='outlined-basic'
                        label={
                          window.location.pathname.includes("/fr")
                            ? "Lieu"
                            : window.location.pathname.includes("/it")
                            ? "Località"
                            : window.location.pathname.includes("/en")
                            ? "Town"
                            : "Ort"
                        }
                        value={this.state.place}
                        variant="outlined"
                      />
                      <TextField
                        className="editFields20"
                        onChange={this.onChangeHandler}
                        name="country"
                        // id='outlined-basic'
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label={
                          window.location.pathname.includes("/fr")
                            ? "Pays"
                            : window.location.pathname.includes("/it")
                            ? "Paese"
                            : window.location.pathname.includes("/en")
                            ? "Country"
                            : "Land"
                        }
                        value={this.state.country}
                        variant="outlined"
                      >
                        {countries.map((country) => (
                          <option value={country.code} key={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </TextField>
                    </Container>
                  </Toolbar>
                </Container>
                <Container>
                  <TextField
                    type="email"
                    className="settingsTextField editFields100"
                    onChange={this.onChangeHandler}
                    name="email"
                    // id='outlined-basic'
                    placeholder="Please enter email"
                    label="E-Mail"
                    value={this.state.email}
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    onClick={() => this.updatePersonal()}
                    className="settingsButton"
                    disableElevation
                    variant="contained"
                  >
                    {window.location.pathname.includes("/fr")
                      ? "Mettre à jour"
                      : window.location.pathname.includes("/it")
                      ? "Aggiornare"
                      : window.location.pathname.includes("/en")
                      ? "Update"
                      : "Aktualisieren"}
                  </Button>
                </Container>
              </div>
            ) : (
              <div>
                <Container className="settingsTopSection">
                  <Toolbar>
                    <Typography component="div">
                      <Avatar className="settingsAvatar"></Avatar>
                    </Typography>
                    <Container className="topDetailsSection">
                      {this.state.userProfile.Sex === 1 ? (
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Monsieur"
                            : window.location.pathname.includes("/it")
                            ? "Signor"
                            : window.location.pathname.includes("/en")
                            ? "Mr"
                            : "Herr"}
                        </Typography>
                      ) : this.state.userProfile.Sex === 2 ? (
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Madame"
                            : window.location.pathname.includes("/it")
                            ? "Signora"
                            : window.location.pathname.includes("/en")
                            ? "Ms"
                            : "Frau"}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography className="userInformation">
                        {this.state.userProfile.Firstname
                          ? this.state.userProfile.Firstname
                          : "" + " " + this.state.userProfile.Name
                          ? this.state.userProfile.Name
                          : ""}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.Address1
                          ? this.state.userProfile.Address1
                          : ""}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.Address2
                          ? this.state.userProfile.Address2
                          : ""}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.ZipCode
                          ? this.state.userProfile.ZipCode
                          : "" + " " + this.state.userProfile.Place
                          ? this.state.userProfile.Place
                          : "" + " " + this.state.userProfile.Country
                          ? this.state.userProfile.Country
                          : ""}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.Email
                          ? this.state.userProfile.Email
                          : ""}
                      </Typography>
                      <Button
                        onClick={() => this.editStateHandler()}
                        className="settingsButton"
                        disableElevation
                        variant="contained"
                      >
                        {window.location.pathname.includes("/fr")
                          ? "Modifier"
                          : window.location.pathname.includes("/it")
                          ? "Modificare"
                          : window.location.pathname.includes("/en")
                          ? "Edit"
                          : "Bearbeiten"}
                      </Button>
                    </Container>
                  </Toolbar>
                </Container>
                <Container>
                  {this.state.userProfile.Language === 1 ? (
                    <Toolbar disableGutters className="languagesToolbar">
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            1,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                        disabled
                      >
                        DE
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            2,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        FR
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            3,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        IT
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            4,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        EN
                      </Button>
                    </Toolbar>
                  ) : this.state.userProfile.Language === 2 ? (
                    <Toolbar disableGutters className="languagesToolbar">
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            1,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        DE
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            2,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                        disabled
                      >
                        FR
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            3,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        IT
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            4,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        EN
                      </Button>
                    </Toolbar>
                  ) : this.state.userProfile.Language === 3 ? (
                    <Toolbar disableGutters className="languagesToolbar">
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            1,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        DE
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            2,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        FR
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            3,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                        disabled
                      >
                        IT
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            4,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        EN
                      </Button>
                    </Toolbar>
                  ) : this.state.userProfile.Language === 4 ? (
                    <Toolbar disableGutters className="languagesToolbar">
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            1,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        DE
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            2,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        FR
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            3,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        IT
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            4,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                        disabled
                      >
                        EN
                      </Button>
                    </Toolbar>
                  ) : (
                    <Toolbar disableGutters className="languagesToolbar">
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            1,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        DE
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            2,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        FR
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            3,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        IT
                      </Button>
                      <Button
                        onClick={() =>
                          this.updateLanguage(
                            4,
                            this.state.user.EnableDailyMailReminder,
                            this.state.user.EnablePushNotificationReminder
                          )
                        }
                        className="languageButton"
                        variant="outlined"
                      >
                        EN
                      </Button>
                    </Toolbar>
                  )}
                </Container>
              </div>
            )}
          </Container>
        </div>
        <BottomNav
          backBtnText={
            window.location.pathname.includes("/fr")
              ? "RETOUR"
              : window.location.pathname.includes("/it")
              ? "INDIETRO"
              : window.location.pathname.includes("/en")
              ? "BACK"
              : "ZURÜCK"
          }
          handleBtn={this.logOut}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Déconnexion"
              : window.location.pathname.includes("/it")
              ? "Effettuare il logout"
              : window.location.pathname.includes("/en")
              ? "Logout"
              : "Abmelden"
          }
          nxtBtnColor={"#EEEEEE"}
          nxtTxtColor={"#000"}
          backBtnState={true}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Settings);
