import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import AppBar from "../../Shared/AppBar";
import NavBar from "../../Shared/NavBar";
import PageTitle from "../../Shared/PageTitle";
import ContactMain from "./ContactMain";
import "./styles.css";
import BottomNav from "../../Shared/BottomNav";

class Contact extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Contact",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Contatto",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          page: "Contact",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : (this.state = {
          page: "Kontakt",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        });
  }

  loadingScreen = () => {
    this.setState({
      loading: true,
    });
  };

  backButtonHandler = () => {
    if (
      (this.state.page === "Kontakt" || this.state.page === "Contatto" || this.state.page === "Contact") &&
      this.state.contactType === null
    ) {
      window.history.back()
    } else {
      this.setState({
        contactType: null,
        back: false,
      });
    }
  };

  backTrueHandler = () => {
    this.setState({
      back: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} />
        <PageTitle state={this.state} />
        <ContactMain/>
        <NavBar state={this.state} />
        <BottomNav
          btnParam={"hide"}
          backBtnText={
            window.location.pathname.includes("/fr")
              ? "RETOUR"
              : window.location.pathname.includes("/it")
              ? "INDIETRO"
              : window.location.pathname.includes("/en")
              ? "BACK"
              : "ZURÜCK"
          }
          backBtnState={true}
          backButtonHandler={this.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Contact);
