import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import HttpsRedirect from "react-https-redirect";

import * as Sentry from "@sentry/react";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://468f6992ead7406897afb3cea9d8af85@o865358.ingest.sentry.io/4505437736730624",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#C51416",
      main: "#C51416",
      dark: "#AF1602",
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <MuiThemeProvider theme={theme}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </MuiThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
