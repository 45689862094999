import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
// import { makeStyles, Typography } from "@material-ui/core";
import * as moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
// import "./styles.css";

// export const useStyles = makeStyles({
//   toolbar: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//   },
// });

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: [],
      datesRange: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.handleUser();
  }

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userProfile: result.Preload,
          loading: true,
        });
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    //   console.log('DATUM PROPS: ', this.state.userProfile);
    //   console.log('DATUM PROPS: ', this.state.userProfile);
    // eslint-disable-next-line no-lone-blocks
    {
      window.location.pathname.includes("/fr")
        ? moment.locale("fr")
        : window.location.pathname.includes("/it")
        ? moment.locale("it")
        : moment.locale("de");
    }
    var date = moment(this.state.userProfile.PeriodEnd);
    // console.log("DATE END: =====", this.state.userProfile.PeriodEnd);
    var dateStart = moment(this.state.userProfile.PeriodStart);
    // console.log("DATE START", this.state.userProfile.PeriodStart);
    var difference = dateStart.diff(date, "days");
    difference = 0;
    var diffDays = [];

    if (new Date(this.state.userProfile.PeriodEnd) > new Date()) {
      difference = dateStart.diff(date, "days");
    } else {
      difference = dateStart.diff(date, "days");
    }

    while (difference < 0) {
      // console.log("WHILE difference", difference);
      // console.log("WHILE diffDays", diffDays);
      diffDays.push(difference);
      difference++;
    }

    // console.log("date: ", date);
    // console.log("dateStart", dateStart);
    // console.log("difference", difference);
    // console.log("diffDays", diffDays);
    // diffDays.map((eachDate) => {
    //   console.log(
    //     "Each date from the array:======",
    //     moment(this.props.state.userProfile.PeriodEnd)
    //       .subtract(+Math.abs(eachDate), "day")
    //       .format("dddd, D. MMM YYYY")
    //   );
    // });
    return this.state.loading ? (
      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth='false'
          className='datumScreen'
          disableGutters='true'
        >
          <Container>
            <div className='oppositeDates'>
              {diffDays.map((i) => (
                <Button
                  onClick={() => {
                    this.props.handleDateUpdate(
                      new Date(
                        moment(this.state.userProfile.PeriodEnd).subtract(
                          +Math.abs(i),
                          "day"
                        )
                      )
                    );
                    this.props.closeDateSelection();
                  }}
                  variant='contained'
                  className='dateButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  {moment(this.state.userProfile.PeriodEnd)
                    .subtract(+Math.abs(i), "day")
                    .format("dddd, D. MMM YYYY")}
                </Button>
              ))}
              <Button
                onClick={() => {
                  this.props.handleDateUpdate(
                    new Date(moment(this.state.userProfile.PeriodEnd))
                  );
                  this.props.closeDateSelection();
                }}
                variant='contained'
                className='dateButton'
                disableElevation
                size='large'
                fullWidth
              >
                {moment(this.state.userProfile.PeriodEnd).format(
                  "dddd, D. MMM YYYY"
                )}
              </Button>
            </div>
          </Container>
        </Container>
      </React.Fragment>
    ) : null;
  }
}
export default withRouter(Main);
