import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BottomNav from "../../Shared/BottomNav";
import GroupSize from "../Home/GroupSize";
import Klasse from "../Home/Klasse";
import Produkt from "../Home/Produkt";
import TravelDate from "../Home/TravelDatePOS";

import "./style.css";

class MainPosChannel extends Component {
  constructor(props) {
    super(props);

    // TODO validate whether that step is actually possible based on the rest of the local storage data, goto produkt otherwise
    let currentForm = localStorage.getItem("POSFormsStep") || "produkt";
    let savedPOSFormsStates =
      JSON.parse(localStorage.getItem("POSFormsState")) || {};

    let TNC =
      this.props.userInfo && this.props.userInfo.TNC && this.props.userInfo.TNC;

    this.state = {
      POSFormsState: savedPOSFormsStates,
      previousBtn: currentForm === "produkt" ? false : true,
      step: currentForm,
      isFormValid: false,
      TNC,
    };
  }

  validateFrom = () => {
    this.setState({ isFormValid: true });
  };

  isFormFilled = () => {
    let savedPOSFormsStates =
      JSON.parse(localStorage.getItem("POSFormsState")) || {};

    if (savedPOSFormsStates && savedPOSFormsStates[this.state.step]) {
      this.setState({ error: false });
      return true;
    }

    return false;
  };

  getNextStepForm = (currentForm) => {
    var produktRequiresKlasse =
      this.state.POSFormsState.produkt !== "TK Schule";
    var produktRequiresGroupSize =
      this.state.POSFormsState.produkt === "TK Schule";

    switch (currentForm) {
      case "produkt":
        const a = produktRequiresKlasse
          ? "klasse"
          : produktRequiresGroupSize
          ? "groupSize"
          : "travelDate";
        localStorage.setItem("POSFormsStep", a);
        this.props.updateTitle(a);
        this.setState({ previousBtn: true });
        return a;
      case "klasse":
        const i = produktRequiresGroupSize ? "groupSize" : "travelDate";
        localStorage.setItem("POSFormsStep", i);
        this.props.updateTitle(i);
        return i;
      case "groupSize":
        localStorage.setItem("POSFormsStep", "travelDate");
        this.props.updateTitle("travelDate");
        return "travelDate";
      default:
        console.log("unhandled case!");
        localStorage.setItem("POSFormsStep", "produkt");
        this.props.updateTitle("produkt");
        return "produkt";
    }
  };

  getPrevStepForm = (currentForm) => {
    var produktRequiresKlasse =
      this.state.POSFormsState.produkt !== "TK Schule";
    var produktRequiresGroupSize =
      this.state.POSFormsState.produkt === "TK Schule";
    switch (currentForm) {
      case "produkt":
        return "produkt";
      case "travelDate":
        const a = produktRequiresGroupSize
          ? "groupSize"
          : produktRequiresKlasse
          ? "klasse"
          : "produkt";
        localStorage.setItem("POSFormsStep", a);
        this.props.updateTitle(a);
        return a;
      case "groupSize":
        const i = produktRequiresKlasse ? "klasse" : "produkt";
        localStorage.setItem("POSFormsStep", i);
        this.props.updateTitle(i);
        return i;
      case "klasse":
        localStorage.setItem("POSFormsStep", "produkt");
        this.props.updateTitle("produkt");
        this.setState({ previousBtn: false });
        return "produkt";
      default:
        console.log("unhandled case!");
        localStorage.setItem("POSFormsStep", "travelDate");
        this.props.updateTitle("travelDate");
        return "travelDate";
    }
  };

  savePOSFormsStates() {
    localStorage.setItem(
      "POSFormsState",
      JSON.stringify(this.state.POSFormsState)
    );
  }

  updatePrevStateBtn() {
    const currentStep = this.state.forms.indexOf(this.state.step);
    if (currentStep > 0) {
      this.setState({ previousBtn: true });
    } else {
      this.setState({ previousBtn: false });
    }
  }

  handleGlobalStateChange = (key, newValue) => {
    if (key === "groupSize" && newValue === 0) {
      this.setState((prevState) => ({
        POSFormsState: {
          ...prevState.POSFormsState,
          groupSize: "",
        },
      }));
    } else {
      this.setState((prevState) => ({
        POSFormsState: {
          ...prevState.POSFormsState,
          [key]: newValue,
        },
      }));
    }
  };

  setFormValidationError() {
    this.setState({ error: true });
  }

  handleNextPrevStep = (e, processKey) => {
    e.preventDefault();
    if (processKey === "next") {
      if (this.isFormFilled()) {
        this.setState((prevState) => ({
          step: this.getNextStepForm(prevState.step),
        }));
      } else {
        this.setFormValidationError();
      }
    } else if (processKey === "prev") {
      this.setState((prevState) => ({
        step: this.getPrevStepForm(prevState.step),
      }));
    } else if (processKey === "finishJourney") {
      if (this.isFormFilled()) {
        this.handleFinishJourney();
      } else {
        this.setFormValidationError();
      }
      return;
    }
    localStorage.setItem("POSFormsStep", this.state.step);
  };

  handleFinishJourney = () => {
    let currentLocalStorate = JSON.parse(localStorage.getItem("POSFormsState"));
    let isDateFilled =
      currentLocalStorate && currentLocalStorate.hasOwnProperty("travelDate");
    let isFormValid = this.state.isFormValid;

    if (isDateFilled && isFormValid) {
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/preload/finalizePOS`;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", window.localStorage.AccessToken);
      myHeaders.append("Content-Type", "application/json");

      let localStorageData =
        JSON.parse(localStorage.getItem("POSFormsState")) &&
        JSON.parse(localStorage.getItem("POSFormsState"));

      var Product =
        localStorageData && localStorageData["produkt"]
          ? localStorageData["produkt"]
          : "";

      var TicketClass =
        localStorageData && localStorageData["klasse"]
          ? localStorageData["klasse"]
          : "";

      var GroupSize =
        localStorageData && localStorageData["groupSize"]
          ? localStorageData["groupSize"]
          : "";

      var TravelDate =
        localStorageData && localStorageData["travelDate"]
          ? localStorageData["travelDate"]
          : "";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          TNC: this.state.TNC,
          Product: Product ? Product : "",
          TicketClass: TicketClass
            ? TicketClass
            : Product === "TK Schule"
            ? 2
            : "",
          GroupSize: GroupSize
            ? GroupSize
            : Product === "TK HTA" || Product === "TK FVP"
            ? 1
            : "",
          TravelDate: TravelDate ? TravelDate : "",
        }),
      };

      fetch(proxyUrl + targetUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (!result.Token) {
            localStorage.removeItem("POSFormsState");
            localStorage.removeItem("POSFormsStep");
            window.location.reload(false);
          } else {
            this.activateUser();
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  activateUser = () => {


    window.localStorage.setItem("firstTimeLogin", "true");
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("EnableDailyNotifications", true);
    urlencoded.append("EnablePushNotificationReminder", true);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        localStorage.setItem("userDataFilled", true);
        this.props.finishForm();
      })
      .catch((error) => console.log("error", error));
  };

  componentDidUpdate() {
    this.savePOSFormsStates();
  }

  componentDidMount() {
    this.props.updateTitle(this.state.step);
  }

  render() {
    const form = {
      produkt: <Produkt handleStateChange={this.handleGlobalStateChange} />,
      klasse: <Klasse handleStateChange={this.handleGlobalStateChange} />,
      groupSize: <GroupSize handleStateChange={this.handleGlobalStateChange} />,
      travelDate: (
        <TravelDate
          handleStateChange={this.handleGlobalStateChange}
          validateFrom={this.validateFrom}
          errorStatus={this.state.error}
        />
      ),
    };

    return (
      <React.Fragment>
        <div className="_container infoContainer">
          {form[this.state.step]}
          {this.state.error && !this.state.step === "travelDate" && (
            <p className="error-message">
              Please fill out all required fields.
            </p>
          )}
        </div>

        {this.state.step === "travelDate" ? (
          <BottomNav
            isPOSForm={true}
            hideBbtnArror={false}
            backBtnState={this.state.previousBtn}
            backButtonHandler={(e) => this.handleNextPrevStep(e, "prev")}
            handleBtn={(e) => this.handleNextPrevStep(e, "finishJourney")}
            backBtnText={
              window.location.pathname.includes("/fr")
                ? "RETOUR"
                : window.location.pathname.includes("/it")
                ? "INDIETRO"
                : window.location.pathname.includes("/en")
                ? "BACK"
                : "ZURÜCK"
            }
            btnText={
              window.location.pathname.includes("/fr")
                ? "CONTINUER"
                : window.location.pathname.includes("/it")
                ? "AVANTI"
                : window.location.pathname.includes("/en")
                ? "CONTINUE"
                : "WEITER"
            }
            flexJustify={"flex-end"}
          />
        ) : (
          <BottomNav
            isPOSForm={true}
            hideBbtnArror={false}
            backBtnState={this.state.previousBtn}
            nextBtnHandler={(e) => this.handleNextPrevStep(e, "next")}
            backButtonHandler={(e) => this.handleNextPrevStep(e, "prev")}
            btnParam={
              window.location.pathname.includes("/fr")
                ? "Conclusion"
                : window.location.pathname.includes("/it")
                ? "Conclusione"
                : window.location.pathname.includes("/en")
                ? "Completion"
                : "Abschluss"
            }
            backBtnText={
              window.location.pathname.includes("/fr")
                ? "RETOUR"
                : window.location.pathname.includes("/it")
                ? "INDIETRO"
                : window.location.pathname.includes("/en")
                ? "BACK"
                : "ZURÜCK"
            }
            btnText={
              window.location.pathname.includes("/fr")
                ? "CONTINUER"
                : window.location.pathname.includes("/it")
                ? "AVANTI"
                : window.location.pathname.includes("/en")
                ? "CONTINUE"
                : "WEITER"
            }
            flexJustify={"flex-end"}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(MainPosChannel);
