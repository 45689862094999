import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import line from "../../../../assets/icons/vLines.svg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import train from "../../../../assets/icons/train.svg";
import tram from "../../../../assets/icons/tram.svg";
import bus from "../../../../assets/icons/bus.svg";
import boat from "../../../../assets/icons/boat.svg";
import andere from "../../../../assets/icons/andere.svg";
import InfoIcon from "@material-ui/icons/Info";
// import CloseIcon from "@material-ui/icons/Close";
import "./styles.css";
import BottomNav from "../../../../Shared/BottomNav";
import Popup from "../../../../Shared/Popup";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          dialogError: "Veuillez sélectionner le moyen de transport utilisé",
          fade: "out",
          eligible: false,
          dialog: window.localStorage.dialogTransportType,
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          dialogError: "Selezioni il mezzo di trasporto utilizzato",
          fade: "out",
          eligible: false,
          dialog: window.localStorage.dialogTransportType,
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          dialogError: "Please select the type of transport",
          fade: "out",
          eligible: false,
          dialog: window.localStorage.dialogTransportType,
        })
      : (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          dialogError: "Bitte wählen Sie das benutzte Transportmittel aus",
          fade: "out",
          eligible: false,
          dialog: window.localStorage.dialogTransportType,
        });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.activeStationsHandler();
    this.setState(
      {
        popupDisplay: false,
        popup: {
          text: window.location.pathname.includes("/fr")
            ? "Veuillez sélectionner le moyen de transport utilisé pour chaque partie du trajet. Les parcours à pied et les courses effectuées en dehors des transports publics ne doivent pas être saisis."
            : window.location.pathname.includes("/it")
            ? "Selezioni il mezzo di trasporto utilizzato per ogni tratta parziale. I percorsi a piedi e le corse al di fuori dei trasporti pubblici non devono essere registrati."
            : window.location.pathname.includes("/en")
            ? "Please select the type of transport you used for each part of the journey. You don't need to enter any information about parts of the journey you made on foot or using vehicles that are not part of the public transport network."
            : "Bitte wählen Sie das benutzte Transportmittel für jede Teilstrecke aus. Fusswege und Fahrten ausserhalb des öffentlichen Verkehrs sind generell nicht zu erfassen.",
        },
      },
      () => {
        if (
          this.props.state.screenWidth < 620 &&
          window.localStorage.firstTimeLogin &&
          !window.localStorage.trtypeDialog
        ) {
          this.togglePopup();
        }
      }
    );
  }
  //Toggle popup
  togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("trtypeDialog", true);
    if (e) {
      if (e.target === e.currentTarget)
        this.setState({ popupDisplay: !this.state.popupDisplay });
      return;
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };
  fromBahnHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: "active",
      fromTram: "",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  fromTramHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: "",
      fromTram: "active",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  fromBusHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: "",
      fromTram: "",
      fromBus: "active",
      fromSchiff: "",
      fromAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  fromSchiffHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: "",
      fromTram: "",
      fromBus: "",
      fromSchiff: "active",
      fromAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  fromAndereHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: "",
      fromTram: "",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "active",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  toBahnHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: "active",
      toTram: "",
      toBus: "",
      toSchiff: "",
      toAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  toTramHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: "",
      toTram: "active",
      toBus: "",
      toSchiff: "",
      toAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  toBusHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: "",
      toTram: "",
      toBus: "active",
      toSchiff: "",
      toAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  toSchiffHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: "",
      toTram: "",
      toBus: "",
      toSchiff: "active",
      toAndere: "",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  toAndereHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: "",
      toTram: "",
      toBus: "",
      toSchiff: "",
      toAndere: "active",
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: "in",
    });
    setTimeout(() => {
      this.setState({
        fade: "out",
      });
    }, 2500);
  };

  continueClickHandler = () => {
    // this.props.loadingOn();
    if (this.state.eligible === false) {
      // this.props.loadingOff();
      this.errorDialog();
    } else {
      const newArr = this.props.state.initExtraStops.map((stop) => {
        return this.props.handleViaStationTravelType(
          stop.ViaStationId,
          stop.ViaStationName,
          this.state[stop.ViaStationName + "TravelType"]
        );
      });
      this.props.viaStationSubmit(newArr);
      // setTimeout(() => {
      //   this.props.handleNewJourney("splist");
      // }, 2000);
    }
  };

  activeStationsHandler = () => {
    this.props.state.initExtraStops.map((stop) =>
      this.setState({
        [stop.ViaStationName + "Bahn"]: "",
        [stop.ViaStationName + "Tram"]: "",
        [stop.ViaStationName + "Bus"]: "",
        [stop.ViaStationName + "Schiff"]: "",
        [stop.ViaStationName + "Andere"]: "",
        [stop.ViaStationName + "Eligibility"]: false,
      })
    );
  };

  viaBahnStatHandler = (e, b) => {
    this.setState({
      [e + "Bahn"]: "active",
      [e + "Tram"]: "",
      [e + "Bus"]: "",
      [e + "Schiff"]: "",
      [e + "Andere"]: "",
      [e + "TravelType"]: "Bahn",
      [e + "Eligibility"]: true,
      // eligible: true,
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  viaTramStatHandler = (e, b) => {
    this.setState({
      [e + "Bahn"]: "",
      [e + "Tram"]: "active",
      [e + "Bus"]: "",
      [e + "Schiff"]: "",
      [e + "Andere"]: "",
      [e + "TravelType"]: "Tram",
      [e + "Eligibility"]: true,
      // eligible: true,
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  viaBusStatHandler = (e, b) => {
    this.setState({
      [e + "Bahn"]: "",
      [e + "Tram"]: "",
      [e + "Bus"]: "active",
      [e + "Schiff"]: "",
      [e + "Andere"]: "",
      [e + "TravelType"]: "Bus",
      [e + "Eligibility"]: true,
      // eligible: true,
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  viaSchiffStatHandler = (e, b) => {
    this.setState({
      [e + "Bahn"]: "",
      [e + "Tram"]: "",
      [e + "Bus"]: "",
      [e + "Schiff"]: "active",
      [e + "Andere"]: "",
      [e + "TravelType"]: "Schiff",
      [e + "Eligibility"]: true,
      // eligible: true,
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  viaAndereStatHandler = (e, b) => {
    this.setState({
      [e + "Bahn"]: "",
      [e + "Tram"]: "",
      [e + "Bus"]: "",
      [e + "Schiff"]: "",
      [e + "Andere"]: "active",
      [e + "TravelType"]: "Andere",
      [e + "Eligibility"]: true,
      // eligible: true,
    });
    setTimeout(() => {
      this.elegibilityChecker();
    }, 200);
  };

  elegibilityChecker = () => {
    // eslint-disable-next-line
    let activeFrom = false;
    let eligible = true;
    if (
      this.state.fromAndere === "active" ||
      this.state.fromBahn === "active" ||
      this.state.fromBus === "active" ||
      this.state.fromSchiff === "active" ||
      this.state.fromTram === "active"
    ) {
      activeFrom = true;
    }
    Object.keys(this.state).forEach((el) => {
      if (el.includes("Eligibility")) {
        // console.log("el :", this.state[el]);
        if (!this.state[el]) {
          eligible = false;
          return;
        }
      }
    });
    if (activeFrom && eligible) {
      this.setState({ eligible: true });
      return;
    }
    this.setState({ eligible: false });

    // var activeFrom;
    // this.state.fromAndere === "active"
    //   ? (activeFrom = true)
    //   : this.state.fromBahn === "active"
    //   ? (activeFrom = true)
    //   : this.state.fromBus === "active"
    //   ? (activeFrom = true)
    //   : this.state.fromSchiff === "active"
    //   ? (activeFrom = true)
    //   : this.state.fromTram === "active"
    //   ? (activeFrom = true)
    //   : (activeFrom = false);
    // console.log("CHECKING ELEGIBILITY: ", activeFrom);
    // this.props.state.initExtraStops.forEach((stop) => {
    //   this.state[stop.ViaStationName + "Eligibility"] === false &&
    //   activeFrom === false
    //     ? this.setState({ eligible: false })
    //     : this.state[stop.ViaStationName + "Eligibility"] === true &&
    //       activeFrom === false
    //     ? this.setState({ eligible: false })
    //     : this.state[stop.ViaStationName + "Eligibility"] === false &&
    //       activeFrom === true
    //     ? this.setState({ eligible: false })
    //     : this.setState({ eligible: true });
    // });
    // console.log("STATE:", this.state.eligible);
  };

  dialogHandle = () => {
    this.setState({ dialog: "firstTimeOut" });
  };

  dialogOpen = () => {
    // this.setState({ dialog: "firstTimeIn" });
    this.togglePopup();
  };

  render() {
    // console.log('PROPS TRANSPORT TYPE: ', this.props.state);
    // console.log('STATE TRANSPORT TYPE: ', this.state);

    return (
      <React.Fragment>
        <CssBaseline />
        <Popup
          display={this.state.popupDisplay}
          content={this.state.popup}
          hidePopup={this.togglePopup}
        />
        <div className="_container">
          <Container
            maxWidth="false"
            className="StartortScreen"
            disableGutters="true"
          >
            {this.props.state.screenWidth < 620 ? (
              <InfoIcon onClick={this.dialogOpen} className="helpModalButton" />
            ) : (
              <Container
                maxWidth={false}
                disableGutters
                className="infoContainer"
              >
                <Typography
                  variant="h5"
                  className="finishDialogContent transportHelp"
                >
                  <span>
                    {window.location.pathname.includes("/fr")
                      ? "Veuillez sélectionner le moyen de transport utilisé pour chaque partie du trajet. Les parcours à pied et les courses effectuées en dehors des transports publics ne doivent pas être saisis."
                      : window.location.pathname.includes("/it")
                      ? "Selezioni il mezzo di trasporto utilizzato per ogni tratta parziale. I percorsi a piedi e le corse al di fuori dei trasporti pubblici non devono essere registrati."
                      : window.location.pathname.includes("/en")
                      ? "Please select the type of transport you used for each part of the journey. You don't need to enter any information about parts of the journey you made on foot or using vehicles that are not part of the public transport network."
                      : "Bitte wählen Sie das benutzte Transportmittel für jede Teilstrecke aus. Fusswege und Fahrten ausserhalb des öffentlichen Verkehrs sind generell nicht zu erfassen."}
                  </span>
                </Typography>
              </Container>
            )}

            <Card
              raised="true"
              className={"errorDialog transportDialog fade-" + this.state.fade}
            >
              <CardContent className="errorContent">
                <Typography align="center">{this.state.dialogError}</Typography>
              </CardContent>
            </Card>
            <Container maxWidth="false" className="transportType">
              <Typography variant="h5" className="transportationType">
                {this.props.state.startPointName}
              </Typography>
              <div>
                {this.props.state.connectingPointName === null ? (
                  <div>
                    <Typography className="transportationType">
                      <img
                        src={line}
                        alt="Add Station"
                        className="noConnectionStop"
                      />
                    </Typography>
                    <div className="transportationType">
                      <Typography className="transportTypeInner">
                        <img
                          onClick={() => this.fromBahnHandler("Bahn")}
                          className={
                            "transportTypeOverlay" + this.state.fromBahn
                          }
                          src={train}
                          alt="Train"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Train"
                            : window.location.pathname.includes("/it")
                            ? "Treno"
                            : window.location.pathname.includes("/en")
                            ? "Rail"
                            : "Bahn"}
                        </Typography>
                      </Typography>
                      <Typography className="transportTypeInner">
                        <img
                          onClick={() => this.fromTramHandler("Tram")}
                          className={
                            "transportTypeOverlay" + this.state.fromTram
                          }
                          src={tram}
                          alt={
                            window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"
                          }
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Tram, métro"
                            : window.location.pathname.includes("/it")
                            ? "Tram, metropolitana"
                            : window.location.pathname.includes("/en")
                            ? "Tram, metro"
                            : "Tram, Metro"}
                        </Typography>
                      </Typography>
                      <Typography className="transportTypeInner">
                        <img
                          onClick={() => this.fromBusHandler("Bus")}
                          className={
                            "transportTypeOverlay" + this.state.fromBus
                          }
                          src={bus}
                          alt={
                            window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"
                          }
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Bus, car postal"
                            : window.location.pathname.includes("/it")
                            ? "Autobus, AutoPostale"
                            : window.location.pathname.includes("/en")
                            ? "Bus, PostBus"
                            : "Bus, Postauto"}
                        </Typography>
                      </Typography>
                      <Typography className="transportTypeInner">
                        <img
                          onClick={() => this.fromSchiffHandler("Schiff")}
                          className={
                            "transportTypeOverlay" + this.state.fromSchiff
                          }
                          src={boat}
                          alt="Schiff"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Bateau"
                            : window.location.pathname.includes("/it")
                            ? "Battello"
                            : window.location.pathname.includes("/en")
                            ? "Boat"
                            : "Schiff"}
                        </Typography>
                      </Typography>
                      <Typography className="transportTypeInner">
                        <img
                          onClick={() => this.fromAndereHandler("Andere")}
                          className={
                            "anderetype transportTypeOverlay" +
                            this.state.fromAndere
                          }
                          src={andere}
                          alt="Bergbahnen, Andere"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Remontées mécaniques, autre"
                            : window.location.pathname.includes("/it")
                            ? "Funivia, altro"
                            : window.location.pathname.includes("/en")
                            ? "Cable car, other"
                            : "Bergbahnen, Andere"}
                        </Typography>
                      </Typography>
                    </div>
                    <Typography className="transportationType">
                      <img
                        src={line}
                        alt="Add Station"
                        className="noConnectionStop"
                      />
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography
                      variant="h5"
                      className="connectionStop transportTypeConnectionStop"
                    >
                      <img
                        src={line}
                        alt="line"
                        className="addConnectingStation"
                        height="50px"
                      />
                    </Typography>
                    <div className="transportationType">
                      <div className="transportTypeInner _types">
                        <img
                          onClick={() => this.fromBahnHandler("Bahn")}
                          className={
                            "transportTypeOverlay" + this.state.fromBahn
                          }
                          src={train}
                          alt="Train"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Train"
                            : window.location.pathname.includes("/it")
                            ? "Treno"
                            : window.location.pathname.includes("/en")
                            ? "Rail"
                            : "Bahn"}
                        </Typography>
                      </div>
                      <div className="transportTypeInner _types">
                        <img
                          onClick={() => this.fromTramHandler("Tram")}
                          className={
                            "transportTypeOverlay" + this.state.fromTram
                          }
                          src={tram}
                          alt="Tram"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Tram, métro"
                            : window.location.pathname.includes("/it")
                            ? "Tram, metropolitana"
                            : window.location.pathname.includes("/en")
                            ? "Tram, metro"
                            : "Tram, Metro"}
                        </Typography>
                      </div>
                      <div className="transportTypeInner _types">
                        <img
                          onClick={() => this.fromBusHandler("Bus")}
                          className={
                            "transportTypeOverlay" + this.state.fromBus
                          }
                          src={bus}
                          alt="Bus"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Bus, car postal"
                            : window.location.pathname.includes("/it")
                            ? "Autobus, AutoPostale"
                            : window.location.pathname.includes("/en")
                            ? "Bus, PostBus"
                            : "Bus, Postauto"}
                        </Typography>
                      </div>
                      <div className="transportTypeInner _types">
                        <img
                          onClick={() => this.fromSchiffHandler("Schiff")}
                          className={
                            "transportTypeOverlay" + this.state.fromSchiff
                          }
                          src={boat}
                          alt="Boat"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Bateau"
                            : window.location.pathname.includes("/it")
                            ? "Battello"
                            : window.location.pathname.includes("/en")
                            ? "Boat"
                            : "Schiff"}
                        </Typography>
                      </div>
                      <div className="transportTypeInner _types">
                        <img
                          onClick={() => this.fromAndereHandler("Andere")}
                          className={
                            "anderetype transportTypeOverlay" +
                            this.state.fromAndere
                          }
                          src={andere}
                          alt="andere"
                        />
                        <Typography>
                          {window.location.pathname.includes("/fr")
                            ? "Remontées mécaniques, autre"
                            : window.location.pathname.includes("/it")
                            ? "Funivia, altro"
                            : window.location.pathname.includes("/en")
                            ? "Cable car, other"
                            : "Bergbahnen, Andere"}
                        </Typography>
                      </div>
                    </div>
                    <div className="transportationType moretransports">
                      {this.props.state.initExtraStops.map((stop) => (
                        <div className="connectingStationsType">
                          <Typography
                            variant="h5"
                            className="connectionStop transportTypeConnectionStop"
                          >
                            <img
                              src={line}
                              alt="line"
                              className="addConnectingStation"
                              height="50px"
                            />
                          </Typography>
                          <Typography
                            variant="h5"
                            className="transportationType"
                          >
                            {stop.ViaStationName}
                          </Typography>
                          <Typography
                            variant="h5"
                            className="connectionStop transportTypeConnectionStop"
                          >
                            <img
                              src={line}
                              alt="line"
                              className="addConnectingStation"
                              height="50px"
                            />
                          </Typography>
                          <div className="transportationType">
                            <div className="transportTypeInner _types">
                              <img
                                onClick={() =>
                                  this.viaBahnStatHandler(stop.ViaStationName)
                                }
                                className={
                                  "transportTypeOverlay" +
                                  this.state[stop.ViaStationName + "Bahn"]
                                }
                                src={train}
                                alt="Train"
                              />
                              <Typography>
                                {window.location.pathname.includes("/fr")
                                  ? "Train"
                                  : window.location.pathname.includes("/it")
                                  ? "Treno"
                                  : window.location.pathname.includes("/en")
                                  ? "Rail"
                                  : "Bahn"}
                              </Typography>
                            </div>
                            <div className="transportTypeInner _types">
                              <img
                                onClick={() =>
                                  this.viaTramStatHandler(stop.ViaStationName)
                                }
                                className={
                                  "transportTypeOverlay" +
                                  this.state[stop.ViaStationName + "Tram"]
                                }
                                src={tram}
                                alt="Tram"
                              />
                              <Typography>
                                {window.location.pathname.includes("/fr")
                                  ? "Tram, métro"
                                  : window.location.pathname.includes("/it")
                                  ? "Tram, metropolitana"
                                  : window.location.pathname.includes("/en")
                                  ? "Tram, metro"
                                  : "Tram, Metro"}
                              </Typography>
                            </div>
                            <div className="transportTypeInner _types">
                              <img
                                onClick={() =>
                                  this.viaBusStatHandler(stop.ViaStationName)
                                }
                                className={
                                  "transportTypeOverlay" +
                                  this.state[stop.ViaStationName + "Bus"]
                                }
                                src={bus}
                                alt="Bus"
                              />
                              <Typography>
                                {window.location.pathname.includes("/fr")
                                  ? "Bus, car postal"
                                  : window.location.pathname.includes("/it")
                                  ? "Autobus, AutoPostale"
                                  : window.location.pathname.includes("/en")
                                  ? "Bus, PostBus"
                                  : "Bus, Postauto"}
                              </Typography>
                            </div>
                            <div className="transportTypeInner _types">
                              <img
                                onClick={() =>
                                  this.viaSchiffStatHandler(stop.ViaStationName)
                                }
                                className={
                                  "transportTypeOverlay" +
                                  this.state[stop.ViaStationName + "Schiff"]
                                }
                                src={boat}
                                alt="Boat"
                              />
                              <Typography>
                                {window.location.pathname.includes("/fr")
                                  ? "Bateau"
                                  : window.location.pathname.includes("/it")
                                  ? "Battello"
                                  : window.location.pathname.includes("/en")
                                  ? "Boat"
                                  : "Schiff"}
                              </Typography>
                            </div>
                            <div className="transportTypeInner _types">
                              <img
                                onClick={() =>
                                  this.viaAndereStatHandler(stop.ViaStationName)
                                }
                                className={
                                  "anderetype transportTypeOverlay" +
                                  this.state[stop.ViaStationName + "Andere"]
                                }
                                src={andere}
                                alt="andere"
                              />
                              <Typography>
                                {window.location.pathname.includes("/fr")
                                  ? "Remontées mécaniques, autre"
                                  : window.location.pathname.includes("/it")
                                  ? "Funivia, altro"
                                  : window.location.pathname.includes("/en")
                                  ? "Cable car, other"
                                  : "Bergbahnen, Andere"}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Typography
                      variant="h5"
                      className="connectionStop transportTypeConnectionStop"
                    >
                      <img
                        src={line}
                        alt="line"
                        className="addConnectingStation"
                        height="50px"
                      />
                    </Typography>
                  </div>
                )}
                <Typography variant="h5" className="transportationType">
                  {this.props.state.endPointName}
                </Typography>
              </div>
              {/* <Typography variant='h5'>{this.props.state.endPoint}</Typography> */}
            </Container>
            {/* <BottomNavigation
            onClick={() => this.continueClickHandler()}
            className='homeCompleteButton connectingContinueButton'
            style={{ backgroundColor: "#EEEEEE" }}
          >
            <Typography
              variant='body1'
              className='connectingContinueButtonContent'
            >
              {window.location.pathname.includes("/fr")
                ? "Continuer"
                : window.location.pathname.includes("/it")
                ? "Avanti"
                : "Weiter"}
            </Typography>
            <ArrowForwardIosSharpIcon />
          </BottomNavigation> */}
          </Container>
        </div>

        <BottomNav
          handleBtn={this.continueClickHandler}
          btnText={
            window.location.pathname.includes("/fr")
              ? "CONTINUER"
              : window.location.pathname.includes("/it")
              ? "AVANTI"
              : window.location.pathname.includes("/en")
              ? "CONTINUE"
              : "WEITER"
          }
          backBtnState={this.props.state.back}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
