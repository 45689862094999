import React, { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import axios from "axios";
import moment from "moment/moment";
import "./styles.css";

import useLocalStorage from "../useLocalStorage";

import {
  TRAVEL_DATE_FORM_DATE_ERROR,
  TRAVEL_DATE_FORM_RECAPTCHA_ERROR,
  TRAVEL_DATE_FORM_SUBTILE,
  TRAVEL_DATE_FORM_TITLE,
} from "../Translations";

export default function TravelDateForm({
  setPageTitle,
  setLoading,
  setShowBackButton,
  setBackCallback,
  setNextCallback,
}) {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [email] = useLocalStorage("coopEmail", "");
  const [pointOfSale] = useLocalStorage("coopPointOfSale", "");
  const [pointOfSaleStore] = useLocalStorage("coopPointOfSaleStore", "");
  const [travelDate, setTravelDate] = useLocalStorage("coopTravelDate", "");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  // Additionally store all form data of this form page in a Ref in order to be able to access it in handleNextClick
  // Accessing properties directly in handleNextClick does not work due to the way JS binding works
  // (properties would not update from default value because handleNextClick is called from the parent component and not from the inside)
  const formData = useRef({});

  useEffect(() => {
    formData.current.email = email;
    formData.current.pointOfSale = pointOfSale;
    formData.current.pointOfSaleStore = pointOfSaleStore;
    formData.current.travelDate = travelDate;
    formData.current.recaptchaToken = recaptchaToken;
  }, [email, pointOfSale, pointOfSaleStore, travelDate, recaptchaToken]);

  const [showDateError, setShowDateError] = useState(false);
  const [showRecaptchaError, setShowRecaptchaError] = useState(false);

  // Set parent params on mount
  useEffect(() => {
    setPageTitle(TRAVEL_DATE_FORM_TITLE);
    setLoading(false);
    setShowBackButton(true);
    setBackCallback(() => () => handleBackClick());
    setNextCallback(() => () => handleNextClick());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleBackClick() {
    // Go to route point-of-sale
    const newPath = url.substring(0, url.lastIndexOf("/")) + "/point-of-sale";
    history.push(newPath);
  }

  async function handleNextClick() {
    const validationSuccess = validateForm();
    if (!validationSuccess) return;

    setLoading(true);
    const finalizeSuccess = await finalizeCoop();
    if (!finalizeSuccess) {
      goToStart();
      return;
    }

    const activationSucess = await activateUser();
    if (!activationSucess) {
      goToStart();
      return;
    }

    // If everything worked go to route /home
    history.push(`/${getCurrentLanguage()}/home`);
  }

  function validateForm() {
    const data = formData.current;

    setShowDateError(!data.travelDate);
    setShowRecaptchaError(!data.recaptchaToken);

    return Boolean(data.travelDate && data.recaptchaToken);
  }

  // Method for calling the /finalizeCOOP API endpoint which creates an user entry in the database
  // Promise returns true if everything worked successfull 
  async function finalizeCoop() {
    const data = formData.current;
    const formattedDate = moment(new Date(data.travelDate)).format("DD/MM/YYYY");

    const url = process.env.REACT_APP_PROXY_URL + process.env.REACT_APP_BASE_URL + "/api/preload/finalizeCOOP";

    const payload = {
      email: data.email,
      salesPoint: data.pointOfSale === "store" ? data.pointOfSaleStore : data.pointOfSale,
      travelDate: formattedDate,
      language: getLanguageCode(),
      recaptchaToken: formData.current.recaptchaToken,
    };

    try {
      const response = await axios.post(url, payload);
      const { Token, Message } = response.data; // (ReiseblattNr also in response.data)

      if (Token) {
        console.log("Successfully received auth token");
        window.localStorage.setItem("AccessToken", `Bearer ${Token}`);
        return true;
      } else {
        console.error(Message);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // Method for calling the /activate API endpoint
  // This endpoint must always be called after the finalizeCopp function
  // without activation the user would not able to proceed
  // Promise returns true if everything worked successfull
  async function activateUser() {
    window.localStorage.setItem("firstTimeLogin", "true");

    const url = process.env.REACT_APP_PROXY_URL + process.env.REACT_APP_BASE_URL + "/api/authentication/activate";

    var urlencodedPayload = new URLSearchParams();
    urlencodedPayload.append("EnableDailyNotifications", true);
    urlencodedPayload.append("EnablePushNotificationReminder", true);

    const config = {
      headers: {
        Authorization: window.localStorage.AccessToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.post(url, urlencodedPayload, config);
      console.log("Activation:", response.data);
      return true;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  function goToStart() {
    // Go to route authentication
    const newPath = url.substring(0, url.lastIndexOf("/")) + "/authentication";
    history.push(newPath);
  }

  function getCurrentLanguage() {
    return window.location.pathname.includes("/fr")
      ? "fr"
      : window.location.pathname.includes("/it")
      ? "it"
      : window.location.pathname.includes("/en")
      ? "en"
      : "de";
  }

  function getLanguageCode() {
    const languageCodes = { de: 1, fr: 2, it: 3, en: 4 };
    return languageCodes[getCurrentLanguage()]
  }

  return (
    <>
      <>
        <Typography variant="h5" className="firstTimeDialogContent accordonMain">
          {TRAVEL_DATE_FORM_SUBTILE}
        </Typography>
        <div className="radio-buttons-container date-input">
          <div className="date-picker" onClick={() => {}}>
            <input
              type="date"
              id="date-input"
              className="datepicker-input"
              value={travelDate}
              onChange={(event) => setTravelDate(event.target.value)}
            />
          </div>

          {showDateError && <p className="error-message">{TRAVEL_DATE_FORM_DATE_ERROR}</p>}
        </div>
      </>

      <div className="recaptcha-holder">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          onChange={(token) => setRecaptchaToken(token)}
          hl={getCurrentLanguage()}
          className="recaptcha"
        />
        {showRecaptchaError && <p className="error-message">{TRAVEL_DATE_FORM_RECAPTCHA_ERROR}</p>}
      </div>
    </>
  );
}
