import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MenuIcon from "@material-ui/icons/Menu";
import "./styles.css";

class NavBar extends Component {
  constructor(props) {
    super(props);

    const isUserDataFilled =
      localStorage.getItem("userDataFilled") &&
      localStorage.getItem("userDataFilled");

    this.state = {
      burgerLogic: "hideBurger",
      open: false,
      isUserDataFilled,
    };
  }

  logOut = () => {
    localStorage.clear();
    this.props.history.push("goodbye");
  };

  handleRouteChange = (event, site) => {
    const newRoute = `/${this.getCurrentLanguage()}/${site}`
    this.props.history.push(newRoute);
  };

  getCurrentLanguage() {
    return window.location.pathname.includes("/fr")
      ? "fr"
      : window.location.pathname.includes("/it")
      ? "it"
      : window.location.pathname.includes("/en")
      ? "en"
      : "de";
  }

  burgerHandler = () => {
    if (this.state.burgerLogic === "hideBurger" && !this.state.open) {
      this.setState({ burgerLogic: "showBurger", open: true });
      document.body.addEventListener("click", this.handleEventClick);
    } else {
      this.setState({ burgerLogic: "hideBurger", open: false });
    }
  };

  burgerMenuEvent = (e) => {
    this.setState({ burgerLogic: "showBurger" });
  };

  handleEventClick = (e) => {
    if (this.state.burgerLogic === "showBurger") {
      this.setState({ burgerLogic: "hideBurger" });
      document.body.removeEventListener("click", this.handleEventClick);
    }
  };

  //Go to main-home screen on click
  homepageClick = () => {
    if (this.props.toMainScreen) {
      this.props.toMainScreen();
    }
  };

  render() {
    const currentPropsPage = this.props.state.page;
    const currentPropsScreen = this.props.state.screen;

    return (
      <React.Fragment>
        <MenuIcon
          onClick={() => {
            this.burgerHandler();
          }}
          className="menuBurger"
        />
        <BottomNavigation
          showLabels
          value={this.props.state.value}
          onChange={this.handleRouteChange}
          className={"navBar " + this.state.burgerLogic}
        >
          {!this.state.isUserDataFilled &&
            currentPropsPage !== "POS" &&
            currentPropsPage !== "COOP" && (
              <BottomNavigationAction
                label={
                  window.location.pathname.includes("/fr")
                    ? "Mes trajets"
                    : window.location.pathname.includes("/it")
                    ? "La mia panoramica dei viaggi"
                    : window.location.pathname.includes("/en")
                    ? "My trips overview"
                    : "Meine Reiseübersicht"
                }
                onClick={this.homepageClick}
                value="home"
              />
            )}
          <BottomNavigationAction value="information" label="FAQ" />
          <BottomNavigationAction
            value="help"
            label={
              window.location.pathname.includes("/fr")
                ? "Contact"
                : window.location.pathname.includes("/it")
                ? "Contatto"
                : window.location.pathname.includes("/en")
                ? "Contact"
                : "Kontakt"
            }
          />
          {!this.state.isUserDataFilled &&
            currentPropsPage !== "POS" &&
            currentPropsPage !== "COOP" && (
              <BottomNavigationAction
                value="settings"
                label={
                  window.location.pathname.includes("/fr")
                    ? "Paramètres"
                    : window.location.pathname.includes("/it")
                    ? "Impostazioni"
                    : window.location.pathname.includes("/en")
                    ? "Settings"
                    : "Einstellungen"
                }
              />
            )}
          {currentPropsPage !== "COOP" && (
              <BottomNavigationAction
                value=""
                onClick={() => this.logOut()}
                label={
                  window.location.pathname.includes("/fr")
                    ? "Se déconnecter"
                    : window.location.pathname.includes("/it")
                    ? "Logout"
                    : window.location.pathname.includes("/en")
                    ? "Logout"
                    : "Abmelden"
                }
              />
            )}
        </BottomNavigation>
      </React.Fragment>
    );
  }
}

export default withRouter(NavBar);
