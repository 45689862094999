import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "../../Shared/AppBar";
import axios from "axios";
import querystring from "querystring";
import ThankYou from "../../assets/icons/thankyou.png";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Merci",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Grazie",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          page: "Thank you",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : (this.state = {
          page: "Vielen Dank",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        });
  }

  backButtonHandler = () => {
    if (this.state.page === "Vielen Dank") {
      this.props.history.push("/de");
    }
    if (this.state.page === "Merci") {
      this.props.history.push("/fr");
    }
    if (this.state.page === "Grazie") {
      this.props.history.push("/it");
    }
    if (this.state.page === "Thank you") {
      this.props.history.push("/en");
    }
  };

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container className="finishSection" maxWidth="false">
          <div>
            <div className="checkMark">
              <img src={ThankYou} alt="Vielen Dank!" />
            </div>
            {window.location.pathname.includes("/fr") ? (
              <p>
                Nous vous remercions pour votre participation et vous souhaitons
                beaucoup de plaisir dans les transports publics .
              </p>
            ) : window.location.pathname.includes("/it") ? (
              <p>
                Grazie per la sua partecipazione. Le auguriamo un ulteriore buon
                viaggio con i trasporti pubblici.
              </p>
            ) : window.location.pathname.includes("/en") ? (
              <p>
                Thank you for taking part. We hope you continue to enjoy
                travelling by public transport in the future.
              </p>
            ) : (
              <p>
                Herzlichen Dank für Ihre Teilnahme. Wir wünschen Ihnen weiterhin
                gute Fahrt mit dem öffentlichen Verkehr.
              </p>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
