import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Toolbar } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import BottomNav from "../../../../Shared/BottomNav";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IOSSwitch from "../../../Settings/Main/IOSSwitch";
import de from "../../../../countries/de.json";
import en from "../../../../countries/en.json";
import fr from "../../../../countries/fr.json";
import it from "../../../../countries/it.json";
import "moment/locale/de";
import "./styles.css";

class FinishJourneyContest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      reason: null,
      userProfile: [],
      editting: false,
      firstname: "",
      lastname: "",
      sex: "",
      address1: "",
      address2: "",
      zip: "",
      place: "",
      country: "CH",
      email: "",
      fade: "out",
      dialogError: "Bitte wählen Sie das benutzte Transportmittel aus",
      agreeTerms: false,
      markErrorFields: false
    };
  }

  componentDidMount() {
      this.handleUser();
      setTimeout(() => {
        this.setStates();
      }, 2000);
  }

  setStates = () => {
    this.setState({
      firstname: this.state.userProfile.Firstname,
      lastname: this.state.userProfile.Name,
      sex: this.state.userProfile.Sex,
      address1: this.state.userProfile.Address1,
      address2: this.state.userProfile.Address2,
      zip: this.state.userProfile.ZipCode,
      place: this.state.userProfile.Place,
      country: this.state.userProfile.Country || "CH",
      email: this.state.userProfile.Email,
    });
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userProfile: result.Preload,
          user: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  apiCall = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Competition`;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "lang=de");

    var raw = JSON.stringify({
      Gender: this.state.sex,
      Firstname: this.state.firstname,
      Lastname: this.state.lastname,
      Email: this.state.email,
      Address: this.state.address1,
      AddressAddition: this.state.address2,
      ZipCode: this.state.zip,
      City: this.state.place,
      Country: this.state.country,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(localStorage.clear())
      .catch((error) => console.log("error", error));
  };

  updatePersonal = () => {
    if (this.isFormValid()) {
      this.props.finishJourneyCompetition();
      setTimeout(() => {
        this.apiCall();
      }, 2000);
      window.location.pathname.includes("/fr")
        ? this.props.history.push("/fr/vielendank")
        : window.location.pathname.includes("/it")
        ? this.props.history.push("/it/vielendank")
        : window.location.pathname.includes("/en")
        ? this.props.history.push("/en/vielendank")
        : this.props.history.push("/de/vielendank");
    } else {
      this.errorDialog();
      this.setState({ markErrorFields: true })
    }
  };

  isFormValid = () => {
    return Boolean(
      this.state.firstname &&
      this.state.lastname &&
      this.isEmailValid() &&
      this.state.address1 &&
      this.state.zip &&
      this.state.place &&
      this.state.country &&
      this.state.agreeTerms 
    )
  }

  isEmailValid() {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return this.state.email && regex.test(this.state.email.toLowerCase());
  }

  dialogHandle = () => {
    this.setState({ dialog: "firstTimeOut" });
  };

  dialogOpen = () => {
    this.setState({ dialog: "firstTimeIn" });
  };

  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: "in",
    });
    setTimeout(() => {
      this.setState({
        fade: "out",
      });
    }, 2500);
  };

  handleAgree = () => {
    // console.log("AGREE:", this.state.agreeTerms);
    if (this.state.agreeTerms === true) {
      this.setState({ agreeTerms: false });
    } else {
      this.setState({ agreeTerms: true });
    }
  };

  render() {
    let countries = window.location.pathname.includes("/fr")
      ? fr
      : window.location.pathname.includes("/it")
      ? it
      : window.location.pathname.includes("/en")
      ? en
      : de;
    return (
      <React.Fragment>
        <CssBaseline />
        <Card
          raised="true"
          className={
            "errorDialog transportDialogContent fade-" + this.state.fade
          }
        >
          <CardContent className="errorContent">
            <Typography align="center">
              {window.location.pathname.includes("/fr")
                ? "Veuillez remplir tous les champs obligatoires"
                : window.location.pathname.includes("/it")
                ? "Si prega di compilare tutti i campi obbligatori"
                : window.location.pathname.includes("/en")
                ? "Please fill out all required fields"
                : "Bitte füllen Sie alle erforderlichen Felder aus"}
            </Typography>
          </CardContent>
        </Card>
        <div className="_container-three-borders">
          <Container
            maxWidth="false"
            className="datumScreen"
            disableGutters="true"
          >
            {window.location.pathname.includes("/fr") ? (
              <Typography
                component="div"
                variant="h6"
                className="welcomeBanner questionsBanner"
              >
                Veuillez vérifier vos coordonnées.
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography
                component="div"
                variant="h6"
                className="welcomeBanner questionsBanner"
              >
                Verifichi i suoi dati di contatto.
              </Typography>
            ) : window.location.pathname.includes("/en") ? (
              <Typography
                component="div"
                variant="h6"
                className="welcomeBanner questionsBanner"
              >
                Please check your contact details
              </Typography>
            ) : (
              <Typography
                component="div"
                variant="h6"
                className="welcomeBanner questionsBanner"
              >
                {window.location.pathname.includes("/fr")
                  ? "Veuillez vérifier vos coordonnées."
                  : window.location.pathname.includes("/it")
                  ? "Verifichi i suoi dati di contatto."
                  : window.location.pathname.includes("/en")
                  ? "Please check your contact details"
                  : "Bitte überprüfen Sie ihre Kontaktdaten"}
              </Typography>
            )}
            <Container className="finishJourneyContainer">
              <Toolbar disableGutters="true" className="settingsToolbar">
                <Container disableGutters="true">
                  <FormControl variant="outlined" className="editFields100">
                    <InputLabel>
                      {window.location.pathname.includes("/fr")
                        ? "Civilité"
                        : window.location.pathname.includes("/it")
                        ? "Appellativo"
                        : window.location.pathname.includes("/en")
                        ? "Salutation"
                        : "Anrede"}
                    </InputLabel>
                    <Select
                      name="sex"
                      value={this.state.sex}
                      required
                      onChange={this.onChangeHandler}
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Civilité"
                          : window.location.pathname.includes("/it")
                          ? "Appellativo"
                          : window.location.pathname.includes("/en")
                          ? "Salutation"
                          : "Anrede"
                      }
                    >
                      <MenuItem value={1}>
                        {window.location.pathname.includes("/fr")
                          ? "Monsieur"
                          : window.location.pathname.includes("/it")
                          ? "Signor"
                          : window.location.pathname.includes("/en")
                          ? "Mr"
                          : "Herr"}
                      </MenuItem>
                      <MenuItem value={2}>
                        {window.location.pathname.includes("/fr")
                          ? "Madame"
                          : window.location.pathname.includes("/it")
                          ? "Signora"
                          : window.location.pathname.includes("/en")
                          ? "Mrs"
                          : "Frau"}
                      </MenuItem>
                      <MenuItem value={0}>{"   "}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    onChange={this.onChangeHandler}
                    name="firstname"
                    error={this.state.firstname ? false : true && this.state.markErrorFields}
                    required
                    className="editFields50"
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Prénom"
                        : window.location.pathname.includes("/it")
                        ? "Nome"
                        : window.location.pathname.includes("/en")
                        ? "Lastname"
                        : "Vorname"
                    }
                    value={this.state.firstname}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields50"
                    onChange={this.onChangeHandler}
                    name="lastname"
                    error={this.state.lastname ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Nom"
                        : window.location.pathname.includes("/it")
                        ? "Cognome"
                        : window.location.pathname.includes("/en")
                        ? "Name"
                        : "Name"
                    }
                    value={this.state.lastname}
                    variant="outlined"
                  />
                  <TextField
                    onChange={this.onChangeHandler}
                    name="email"
                    error={this.isEmailValid() ? false : true && this.state.markErrorFields}
                    required
                    className="editFields100"
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "E-Mail"
                        : window.location.pathname.includes("/it")
                        ? "E-mail"
                        : window.location.pathname.includes("/en")
                        ? "E-mail"
                        : "E-mail"
                    }
                    value={this.state.email}
                    variant="outlined"
                  />
                  {this.state.address1 && this.state.address1.length > 0 ? (
                    // eslint-disable-next-line
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address1"
                      error={this.state.address1 ? false : true && this.state.markErrorFields}
                      required
                      id="outlined-basic-error"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Adresse"
                          : window.location.pathname.includes("/it")
                          ? "Indirizzo"
                          : window.location.pathname.includes("/en")
                          ? "Address"
                          : "Adresse"
                      }
                      value={this.state.address1}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address1"
                      error={this.state.address1 ? false : true && this.state.markErrorFields}
                      required
                      id="outlined-basic-error"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Adresse"
                          : window.location.pathname.includes("/it")
                          ? "Indirizzo"
                          : window.location.pathname.includes("/en")
                          ? "Address"
                          : "Adresse"
                      }
                      variant="outlined"
                    />
                  )}
                  {this.state.address2 && this.state.address2.length > 0 ? (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address2"
                      id="outlined-basic"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Complément d'adresse"
                          : window.location.pathname.includes("/it")
                          ? "Supplemento"
                          : window.location.pathname.includes("/en")
                          ? "Additional address details"
                          : "Adresszusatz"
                      }
                      value={this.state.address2}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address2"
                      id="outlined-basic"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Complément d'adresse"
                          : window.location.pathname.includes("/it")
                          ? "Supplemento"
                          : window.location.pathname.includes("/en")
                          ? "Additional address details"
                          : "Adresszusatz"
                      }
                      variant="outlined"
                    />
                  )}
                  <TextField
                    className="editFields30"
                    onChange={this.onChangeHandler}
                    name="zip"
                    error={this.state.zip ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "NPA"
                        : window.location.pathname.includes("/it")
                        ? "NPA "
                        : window.location.pathname.includes("/en")
                        ? "Postcode"
                        : "PLZ"
                    }
                    value={this.state.zip}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields50"
                    onChange={this.onChangeHandler}
                    name="place"
                    error={this.state.place ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Lieu"
                        : window.location.pathname.includes("/it")
                        ? "Località"
                        : window.location.pathname.includes("/en")
                        ? "Town"
                        : "Ort"
                    }
                    value={this.state.place}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields20"
                    onChange={this.onChangeHandler}
                    name="country"
                    error={this.state.country ? false : true && this.state.markErrorFields}
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Pays"
                        : window.location.pathname.includes("/it")
                        ? "Paese "
                        : window.location.pathname.includes("/en")
                        ? "Country"
                        : "Land"
                    }
                    value={this.state.country}
                    variant="outlined"
                  >
                    {countries.map((country) => (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                  <Container className="contestTerms">
                    <Container disableGutters>
                      <Typography align="center">
                        <p>&nbsp;</p>
                      </Typography>
                    </Container>
                    <Container disableGutters>
                      {window.location.pathname.includes("/fr") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          J'accepte les{" "}
                          <Link
                            to="/fr/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            conditions de participation au tirage au sort.
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : window.location.pathname.includes("/it") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          Accetto le{" "}
                          <Link
                            to="/it/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            condizioni del concorso.
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : window.location.pathname.includes("/en") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          I accept{" "}
                          <Link
                            to="/en/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            terms and conditions
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          Ich akzeptiere die{" "}
                          <Link
                            to="/de/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            Wettbewerbsbedingungen
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      )}
                    </Container>
                  </Container>
                </Container>
              </Toolbar>
            </Container>
          </Container>
        </div>
        <BottomNav
          handleBtn={this.updatePersonal}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Participer"
              : window.location.pathname.includes("/it")
              ? "Partecipare"
              : window.location.pathname.includes("/en")
              ? "Enter"
              : "Teilnehmen"
          }
          backBtnState={this.props.backBtnState}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(FinishJourneyContest);
