import React from "react";
import { TextField } from "@material-ui/core";
import "./style.css";
class GroupSize extends React.Component {
  constructor(props) {
    super(props);

    let savedPOSFormsStates =
      JSON.parse(localStorage.getItem("POSFormsState")) || {};

    let defaultGroupSize = savedPOSFormsStates
      ? savedPOSFormsStates["groupSize"]
      : "";

    this.state = {
      value: defaultGroupSize,
      error: "",
      size: 0,
    };
  }

  handleChange = (event) => {
    const newValue = event.target.value;
    this.setState({ value: newValue, size: event.target.value });

    // Regular expression to check if value is a number and less than 1000
    const numberRegex = /^[0-9]{1,3}$/;
    if (
      !numberRegex.test(newValue) ||
      newValue > 1000 ||
      newValue < 1 ||
      newValue === null ||
      newValue === 0
    ) {
      this.props.handleStateChange("groupSize", 0);
      this.setState({
        error: window.location.pathname.includes("/it")
          ? "Numero non valido. Inserire un numero compreso in un intervallo da 1 a 1000."
          : window.location.pathname.includes("/fr")
          ? "Nombre invalide. Veuillez saisir un nombre compris entre 1 et 1000."
          : window.location.pathname.includes("/en")
          ? "Invalid number. Please enter a number in a range of 1 to 1000."
          : "Ungültige Zahl. Bitte geben Sie eine Zahl in einem Bereich von 1 bis 1000 ein.",
      });
    } else {
      this.setState({ error: "" });
      this.props.handleStateChange("groupSize", newValue);
    }
  };

  render() {
    return (
      <div className="radio-buttons-container date-input">
        <TextField
          id="outlined-number"
          label={
            window.location.pathname.includes("/it")
              ? "Grandezza del gruppo"
              : window.location.pathname.includes("/fr")
              ? "Taille du groupe"
              : window.location.pathname.includes("/en")
              ? "Size of group"
              : "Gruppengrösse"
          }
          type="number"
          value={this.state.value}
          placeholder={
            window.location.pathname.includes("/it")
              ? "La preghiamo di inserire la grandezza del suo gruppo."
              : window.location.pathname.includes("/fr")
              ? "Veuillez indiquer la taille de votre groupe."
              : window.location.pathname.includes("/en")
              ? "Please indicate the size of your group."
              : "Bitte geben Sie die Grösse Ihrer Gruppe ein."
          }
          onChange={this.handleChange}
          error={Boolean(this.state.error)}
          helperText={this.state.error}
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus
          className="group-size"
          inputProps={{
            style: { width: "100%" },
          }}
        />
      </div>
    );
  }
}

export default GroupSize;
