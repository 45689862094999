import React from "react";
import LanguageBar from "../LanguageBar";
import "./styling.css";

function PageTitle({ state }) {
  const { mainTitle = false, page = false, screen = false } = state;

  const StepsTitle = {
    POSTitle: window.location.pathname.includes("/it")
      ? "Sondaggio sulla carta giornaliera"
      : window.location.pathname.includes("/fr")
      ? "Enquête sur la carte journalière"
      : window.location.pathname.includes("/en")
      ? "Survey for the day pass"
      : "Erhebung zur Tageskarte",

    klasse: window.location.pathname.includes("/it")
      ? "Classe"
      : window.location.pathname.includes("/fr")
      ? "Classe"
      : window.location.pathname.includes("/en")
      ? "Class"
      : "Klasse",

    produkt: window.location.pathname.includes("/it")
      ? "Prodotto"
      : window.location.pathname.includes("/fr")
      ? "Produit"
      : window.location.pathname.includes("/en")
      ? "Product"
      : "Produkt",

    travelDate: window.location.pathname.includes("/it")
      ? "Data del viaggio"
      : window.location.pathname.includes("/fr")
      ? "Date de voyage"
      : window.location.pathname.includes("/en")
      ? "Date of travel"
      : "Reisedatum",

    groupSize: window.location.pathname.includes("/it")
      ? "Grandezza del gruppo"
      : window.location.pathname.includes("/fr")
      ? "Taille du groupe"
      : window.location.pathname.includes("/en")
      ? "Size of group"
      : "Gruppengrösse",
  };

  return (
    <div className="pageTitle _container">
      { page === "POS" ? (
        <div className="pageTitle-language">
          {page === "POS" ? (
            <h2>
              {StepsTitle[mainTitle]
                ? StepsTitle[mainTitle]
                : StepsTitle["POSTitle"]}
            </h2>
          ) : (
            <h2>{page}</h2>
          )}
          <LanguageBar />
        </div>
      ) : (
        <h2>{page}</h2>
      )}
    </div>
  );
}

export default PageTitle;
