import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "./containers/Main";
import Home from "./containers/Home";
import Information from "./components/Information";
import Contact from "./components/Contact";
import Settings from "./components/Settings";
import ThankYou from "./components/ThankYou";
import EndSurvey from "./components/EndSurvey";
import Terms from "./components/Home/FinishJourney/TermsCondition";
import CoopChannel from "./components/CoopChannel";

export default () => (
  <BrowserRouter>
    <Switch>
      <Redirect from="/coop" to="/de/coop" />
      <Route path='/de/coop'  component={CoopChannel}/>
      <Route path='/fr/coop'  component={CoopChannel}/>
      <Route path='/it/coop'  component={CoopChannel}/>
      <Route path='/en/coop'  component={CoopChannel}/>

      <Route path='/' exact component={Main} />
      <Route path='/home' exact component={Home} />
      <Route path='/terms' exact component={Terms} />
      <Route path='/de/terms' exact component={Terms} />
      <Route path='/fr/terms' exact component={Terms} />
      <Route path='/it/terms' exact component={Terms} />
      <Route path='/information' exact component={Information} />
      <Route path='/help' exact component={Contact} />
      <Route path='/settings' exact component={Settings} />
      <Route path='/vielendank' exact component={ThankYou} />
      <Route path='/de' exact component={Main} />
      <Route path='/de/home' exact component={Home} />
      <Route path='/de/information' exact component={Information} />
      <Route path='/de/help' exact component={Contact} />
      <Route path='/de/settings' exact component={Settings} />
      <Route path='/de/vielendank' exact component={ThankYou} />
      <Route path='/de/notrip/' exact component={EndSurvey} />
      <Route path='/fr' exact component={Main} />
      <Route path='/fr/home' exact component={Home} />
      <Route path='/fr/information' exact component={Information} />
      <Route path='/fr/help' exact component={Contact} />
      <Route path='/fr/settings' exact component={Settings} />
      <Route path='/fr/vielendank' exact component={ThankYou} />
      <Route path='/fr/notrip/' exact component={EndSurvey} />
      <Route path='/it' exact component={Main} />
      <Route path='/it/home' exact component={Home} />
      <Route path='/it/information' exact component={Information} />
      <Route path='/it/help' exact component={Contact} />
      <Route path='/it/settings' exact component={Settings} />
      <Route path='/it/vielendank' exact component={ThankYou} />
      <Route path='/it/notrip/' exact component={EndSurvey} />
      <Route path='/en' exact component={Main} />
      <Route path='/en/home' exact component={Home} />
      <Route path='/en/information' exact component={Information} />
      <Route path='/en/help' exact component={Contact} />
      <Route path='/en/settings' exact component={Settings} />
      <Route path='/en/vielendank' exact component={ThankYou} />
      <Route path='/en/terms' exact component={Terms} />
      <Route path='/en/notrip/' exact component={EndSurvey} />

      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      <Redirect from='*' to='/' />
    </Switch>
  </BrowserRouter>
);
