import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import "./style.css";
import moment from "moment/moment";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker, { registerLocale } from "react-datepicker";
// import { de, enUS, fr, it } from "date-fns/locale";
// registerLocale("de", de);
// registerLocale("it", it);
// registerLocale("enUS", enUS);
// registerLocale("fr", fr);
// import { ContactsOutlined } from "@material-ui/icons";

class TravelDate extends Component {
  constructor(props) {
    super(props);
    let currentLocalStorage = JSON.parse(localStorage.getItem("POSFormsState"));
    let getTravelDateValue =
      currentLocalStorage.hasOwnProperty("travelDate") &&
      currentLocalStorage["travelDate"];

    let formattedTravelDate = getTravelDateValue
      ? moment(getTravelDateValue, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";

    const ReisdatumText = window.location.pathname.includes("/it")
      ? "Data del viaggio"
      : window.location.pathname.includes("/fr")
      ? "Date de voyage"
      : window.location.pathname.includes("/en")
      ? "Date of travel"
      : "Reisedatum";

    const currentLanguage = window.location.pathname.includes("/fr")
      ? "fr"
      : window.location.pathname.includes("/it")
      ? "it"
      : window.location.pathname.includes("/en")
      ? "en"
      : "de";

    this.state = {
      locale: currentLanguage ? currentLanguage : "de",
      travelDate: formattedTravelDate,
      ReisdatumText: ReisdatumText,
    };
  }

  handleValidation = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/validation/validatePOS`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    let localStorageData =
      JSON.parse(localStorage.getItem("POSFormsState")) &&
      JSON.parse(localStorage.getItem("POSFormsState"));

    var Product =
      localStorageData && localStorageData["produkt"]
        ? localStorageData["produkt"]
        : "";

    var TicketClass =
      localStorageData && localStorageData["klasse"]
        ? localStorageData["klasse"]
        : "";

    var GroupSize =
      localStorageData && localStorageData["groupSize"]
        ? localStorageData["groupSize"]
        : "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        TNC: "no tnc", // TODO remove from server
        Product: Product ? Product : "",
        TicketClass: TicketClass
          ? TicketClass
          : Product === "TK Schule"
          ? 2
          : "",
        GroupSize: GroupSize
          ? GroupSize
          : Product === "TK HTA" || Product === "TK FVP"
          ? 1
          : "",
      }),
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.Valid) {
          localStorage.removeItem("POSFormsState");
          localStorage.removeItem("POSFormsStep");
          window.location.reload(false);
        } else {
          this.props.validateFrom();
        }
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    this.handleValidation();
  }

  handleDateChange = (event) => {
    // We create ISO-8601 date in case we change the date or the function of the custom datepicker
    const date = event.target.value;
    const newDate = new Date(date);

    if (newDate) {
      const formattedDate = newDate
        ? moment(newDate).format("DD/MM/YYYY")
        : date;
      this.setState({ travelDate: date });
      this.props.handleStateChange("travelDate", formattedDate);
    } else {
      return;
    }
  };

  // formatDate = (date) => {
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const year = date.getFullYear().toString();
  //   return `${day}/${month}/${year}`;
  // };

  // moveToCalendar = () => {
  //   const getReactDatePicker = document.querySelector(
  //     ".react-datepicker__tab-loop"
  //   );

  //   if (window.innerWidth < 768 && getReactDatePicker) {
  //     var elementPosition = getReactDatePicker.getBoundingClientRect().top;
  //     var currentPosition =
  //       window.pageYOffset || document.documentElement.scrollTop;

  //     window.scroll({
  //       top: currentPosition + elementPosition - 65,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  render() {
    return (
      <>
        <Typography
          variant="h5"
          className="firstTimeDialogContent accordonMain"
        >
          {window.location.pathname.includes("/it")
            ? "La preghiamo di comunicarci che giorno era in viaggio con la carta giornaliera."
            : window.location.pathname.includes("/fr")
            ? "Veuillez nous indiquer le jour pendant lequel vous avez utilisé la carte journalière. "
            : window.location.pathname.includes("/en")
            ? "Please let us know on which day you were traveling with the day pass."
            : "Bitte teilen Sie uns mit, an welchem Tag Sie mit der Tageskarte unterwegs waren."}
        </Typography>
        <div className="radio-buttons-container date-input">
          <div className="date-picker" onClick={this.moveToCalendar}>
            <input
              type="date"
              id="date-input"
              className="datepicker-input"
              value={this.state.travelDate}
              onChange={this.handleDateChange}
            />

            {/* <div className="date-picker" onClick={this.moveToCalendar}> */}

            {/* <DatePicker
              className="datepicker-input"
              selected={this.state.selectedDate}
              onChange={this.handleDateChange}
              dateFormat="dd.MM.yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              locale={this.state.locale}
              placeholderText={
                this.state.travelDate
                  ? this.state.travelDate
                  : this.state.ReisdatumText
              }
            />
            <svg fill="#000000" width="25px" height="25px" viewBox="0 0 16 16">
              <path d="M14.25 2.5h-.75v-1h-1.25v1h-8.5v-1H2.5v1h-.75A1.25 1.25 0 0 0 .5 3.75v9.5a1.25 1.25 0 0 0 1.25 1.25h12.5a1.25 1.25 0 0 0 1.25-1.25v-9.5a1.25 1.25 0 0 0-1.25-1.25zM1.75 3.75h12.5V5H1.75V3.75zm0 9.5v-7h12.5v7z" />
              <path d="M3 8h1.1v1.2H3zm0 2.4h1.1v1.2H3zM11.8 8h1.1v1.2h-1.1zm0 2.4h1.1v1.2h-1.1zM9.6 8h1.1v1.2H9.6zm0 2.4h1.1v1.2H9.6zM7.4 8h1.1v1.2H7.4zm0 2.4h1.1v1.2H7.4zM5.2 8h1.1v1.2H5.2zm0 2.4h1.1v1.2H5.2z" />
            </svg> */}
          </div>

          {this.props.errorStatus && (
            <p className="error-message">
              {window.location.pathname.includes("/fr")
                ? "Veuillez indiquer une date de voyage."
                : window.location.pathname.includes("/it")
                ? "Inserisca una data di viaggio."
                : window.location.pathname.includes("/en")
                ? "TravelDate cannot be empty."
                : "Geben Sie ein Reisedatum ein"}
            </p>
          )}
        </div>
      </>
    );
  }
}

export default TravelDate;
