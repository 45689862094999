import {
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import React, { Component } from "react";

class Klasse extends Component {
  constructor(props) {
    super(props);

    let savedPOSFormsStates =
      JSON.parse(localStorage.getItem("POSFormsState")) || {};

    let defaultKlasseState = savedPOSFormsStates
      ? savedPOSFormsStates["klasse"]
      : null;

    this.state = {
      klasse: defaultKlasseState,
    };
  }

  handleInputFormChange = (event) => {
    this.setState({ klasse: event.target.value });
    this.props.handleStateChange("klasse", event.target.value);
  };

  render() {
    return (
      <div>
        <FormControl className="radio-buttons-container">
          <Typography
            variant="h5"
            className="firstTimeDialogContent accordonMain"
          >
            {window.location.pathname.includes("/it")
              ? "La preghiamo di comunicarci con quale classe ha viaggiato con la carta giornaliera."
              : window.location.pathname.includes("/fr")
              ? "Veuillez nous indiquer dans quelle classe vous avez circulé avec la carte journalière."
              : window.location.pathname.includes("/en")
              ? "Please let us know in which class you were traveling with the day pass."
              : "Bitte teilen Sie uns mit, in welcher Klasse Sie mit der Tageskarte unterwegs waren."}
          </Typography>
          <RadioGroup
            className="radio-buttons-group"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={this.state.klasse}
            name="radio-buttons-group"
          >
            <div className="radio-button-input">
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={
                  window.location.pathname.includes("/it")
                    ? "2ª classe"
                    : window.location.pathname.includes("/fr")
                    ? "2e classe"
                    : window.location.pathname.includes("/en")
                    ? "2nd class"
                    : "2. Klasse"
                }
                onChange={this.handleInputFormChange}
              />
            </div>
            <div className="radio-button-input">
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={
                  window.location.pathname.includes("/it")
                    ? "1ª classe"
                    : window.location.pathname.includes("/fr")
                    ? "1re classe"
                    : window.location.pathname.includes("/en")
                    ? "1st class"
                    : "1. Klasse"
                }
                onChange={this.handleInputFormChange}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default Klasse;
