/* 
Attention Code Duplication: 
This code is an adjusted version of the FinishJourney/Contest component. 
This component is only used if somebody takes the shortcut and clicks on the "Ich möchte nur am Wettbewerb teilnehmen" Button.

As the user does not have an access token when taking the shortcut, this component calls a different 
endpoint than the other contest component ("/api/Contest/AddContest" instead of "/api/Periodapi/Competition")
*/

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Toolbar } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IOSSwitch from "../../Settings/Main/IOSSwitch";
import ReCAPTCHA from "react-google-recaptcha";
import de from "../../../countries/de.json";
import en from "../../../countries/en.json";
import fr from "../../../countries/fr.json";
import it from "../../../countries/it.json";
import axios from "axios";
import Cookies from "js-cookie";
import "moment/locale/de";
import "./styles.css";
import {
  COOP_CONTEST_ERROR_FIELDS_MISSING,
  COOP_CONTEST_ERROR_MULTI_REGISTRATION,
  COOP_CONTEST_SEX_MR,
  COOP_CONTEST_SEX_MRS,
  COOP_CONTEST_TITLE,
} from "../Translations";

class ContestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      reason: null,
      userProfile: [],
      editting: false,
      firstname: "",
      lastname: "",
      sex: "",
      address1: "",
      address2: "",
      zip: "",
      place: "",
      country: "CH",
      email: "",
      fade: "out",
      agreeTerms: false,
      markErrorFields: false,
      recaptchaToken: null,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.props.setPageTitle(COOP_CONTEST_TITLE);
    this.props.setLoading(false);
    this.props.setShowBackButton(true);
    this.props.setBackCallback(() => () => this.handleBackClick());
    this.props.setNextCallback(() => () => this.handleNextClick());

    this.initFormState();
  }

  initFormState = () => {
    const emailFromLocalStorage = JSON.parse(window.localStorage.getItem("coopEmail"));

    this.setState({
      firstname: "",
      lastname: "",
      sex: 0,
      address1: "",
      address2: "",
      zip: "",
      place: "",
      country: "CH",
      email: emailFromLocalStorage,
    });
  };

  handleBackClick() {
    const newPath = this.props.match.url.substring(0, this.props.match.url.lastIndexOf("/")) + "/authentication";
    this.props.history.push(newPath);
  }

  async handleNextClick() {

    // Show error and abort if fields are not valid
    if (!this.isFormValid()) {
      this.showErrorDialog(COOP_CONTEST_ERROR_FIELDS_MISSING);
      this.setState({ markErrorFields: true });
      return;
    }

    // Show error and abort when this browser has already sucessfully participated
    if (Cookies.get("coopContestSuccess")) {
      this.showErrorDialog(COOP_CONTEST_ERROR_MULTI_REGISTRATION);
      return;
    }

    // Call API
    this.props.setLoading(true);
    const success = await this.sendFormData();

    // Show error and abort when API call was not successful
    if (!success) {
      this.showErrorDialog(COOP_CONTEST_ERROR_FIELDS_MISSING);
      this.props.setLoading(false);
      return;
    }

    // Everything worked

    // Set cookie with an expiration of 12 hours (multi-registration prevention)
    // Data is stored as a cookie, because other part of the application might delete all localStorage in certain circumstances
    Cookies.set("coopContestSuccess", true, { expires: 0.5 });

    // Go to to route /vielendank
    window.location.pathname.includes("/fr")
      ? this.props.history.push("/fr/vielendank")
      : window.location.pathname.includes("/it")
      ? this.props.history.push("/it/vielendank")
      : window.location.pathname.includes("/en")
      ? this.props.history.push("/en/vielendank")
      : this.props.history.push("/de/vielendank");
  }

  async sendFormData() {
    const url = process.env.REACT_APP_PROXY_URL + process.env.REACT_APP_BASE_URL + "/api/Contest/AddContest";

    const data = {
      salutation: this.state.sex === 1 ? COOP_CONTEST_SEX_MR : this.state.sex === 2 ? COOP_CONTEST_SEX_MRS : "",
      firstname: this.state.firstname,
      name: this.state.lastname,
      email: this.state.email,
      address1: this.state.address1,
      address2: this.state.address2,
      zipCode: this.state.zip,
      place: this.state.place,
      country: this.state.country,
      language: this.getLanguageCode(),
      recaptchaToken: this.state.recaptchaToken,
    };

    try {
      const response = await axios.post(url, data);
      const {Valid, Message} = response.data
      if(!Valid) console.warn("AddContest request not successful:", Message)
      return Valid;
    } catch (error) {
      console.error("Error sending AddContest request:", error.message, error.response?.data);
      return false;
    }
  }

  isFormValid = () => {
    return Boolean(
      this.state.firstname &&
        this.state.lastname &&
        this.isEmailValid() &&
        this.state.address1 &&
        this.state.zip &&
        this.state.place &&
        this.state.country &&
        this.state.agreeTerms &&
        this.state.recaptchaToken
    );
  };

  isEmailValid() {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return this.state.email && regex.test(this.state.email.toLowerCase());
  }

  getLanguageCode() {
    const languageString = window.location.pathname.includes("/fr")
      ? "fr"
      : window.location.pathname.includes("/it")
      ? "it"
      : window.location.pathname.includes("/en")
      ? "en"
      : "de";

    const languageCodes = { de: 1, fr: 2, it: 3, en: 4 };
    return languageCodes[languageString];
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showErrorDialog = (errorMessage) => {
    window.scrollTo(0, 0);
    this.setState({
      fade: "in",
      errorMessage: errorMessage,
    });
    setTimeout(() => {
      this.setState({
        fade: "out",
      });
    }, 3500);
  };

  handleAgree = () => {
    if (this.state.agreeTerms === true) {
      this.setState({ agreeTerms: false });
    } else {
      this.setState({ agreeTerms: true });
    }
  };

  getCurrentLanguage() {
    return window.location.pathname.includes("/fr")
      ? "fr"
      : window.location.pathname.includes("/it")
      ? "it"
      : window.location.pathname.includes("/en")
      ? "en"
      : "de";
  }

  render() {
    let countries = window.location.pathname.includes("/fr")
      ? fr
      : window.location.pathname.includes("/it")
      ? it
      : window.location.pathname.includes("/en")
      ? en
      : de;
    return (
      <React.Fragment>
        <CssBaseline />
        <Card raised="true" className={"errorDialog transportDialogContent fade-" + this.state.fade}>
          <CardContent className="errorContent">
            <Typography align="center">{this.state.errorMessage}</Typography>
          </CardContent>
        </Card>
        <div className="_container-three-borders">
          <Container maxWidth="false" className="datumScreen" disableGutters="true">
            {window.location.pathname.includes("/fr") ? (
              <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                Veuillez vérifier vos coordonnées.
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                Verifichi i suoi dati di contatto.
              </Typography>
            ) : window.location.pathname.includes("/en") ? (
              <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                Please check your contact details
              </Typography>
            ) : (
              <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                {window.location.pathname.includes("/fr")
                  ? "Veuillez vérifier vos coordonnées."
                  : window.location.pathname.includes("/it")
                  ? "Verifichi i suoi dati di contatto."
                  : window.location.pathname.includes("/en")
                  ? "Please check your contact details"
                  : "Bitte überprüfen Sie ihre Kontaktdaten"}
              </Typography>
            )}
            <Container className="finishJourneyContainer">
              <Toolbar disableGutters="true" className="settingsToolbar">
                <Container disableGutters="true">
                  <FormControl variant="outlined" className="editFields100">
                    <InputLabel>
                      {window.location.pathname.includes("/fr")
                        ? "Civilité"
                        : window.location.pathname.includes("/it")
                        ? "Appellativo"
                        : window.location.pathname.includes("/en")
                        ? "Salutation"
                        : "Anrede"}
                    </InputLabel>
                    <Select
                      name="sex"
                      value={this.state.sex}
                      required
                      onChange={this.onChangeHandler}
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Civilité"
                          : window.location.pathname.includes("/it")
                          ? "Appellativo"
                          : window.location.pathname.includes("/en")
                          ? "Salutation"
                          : "Anrede"
                      }
                    >
                      <MenuItem value={1}>{COOP_CONTEST_SEX_MR}</MenuItem>
                      <MenuItem value={2}>{COOP_CONTEST_SEX_MRS}</MenuItem>
                      <MenuItem value={0}>{"   "}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    onChange={this.onChangeHandler}
                    name="firstname"
                    error={this.state.firstname ? false : true && this.state.markErrorFields}
                    required
                    className="editFields50"
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Prénom"
                        : window.location.pathname.includes("/it")
                        ? "Nome"
                        : window.location.pathname.includes("/en")
                        ? "Lastname"
                        : "Vorname"
                    }
                    value={this.state.firstname}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields50"
                    onChange={this.onChangeHandler}
                    name="lastname"
                    error={this.state.lastname ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Nom"
                        : window.location.pathname.includes("/it")
                        ? "Cognome"
                        : window.location.pathname.includes("/en")
                        ? "Name"
                        : "Name"
                    }
                    value={this.state.lastname}
                    variant="outlined"
                  />
                  <TextField
                    onChange={this.onChangeHandler}
                    name="email"
                    error={this.isEmailValid() ? false : true && this.state.markErrorFields}
                    required
                    className="editFields100"
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "E-Mail"
                        : window.location.pathname.includes("/it")
                        ? "E-mail"
                        : window.location.pathname.includes("/en")
                        ? "E-mail"
                        : "E-mail"
                    }
                    value={this.state.email}
                    variant="outlined"
                  />
                  {this.state.address1 && this.state.address1.length > 0 ? (
                    // eslint-disable-next-line
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address1"
                      error={this.state.address1 ? false : true && this.state.markErrorFields}
                      required
                      id="outlined-basic-error"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Adresse"
                          : window.location.pathname.includes("/it")
                          ? "Indirizzo"
                          : window.location.pathname.includes("/en")
                          ? "Address"
                          : "Adresse"
                      }
                      value={this.state.address1}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address1"
                      error={this.state.address1 ? false : true && this.state.markErrorFields}
                      required
                      id="outlined-basic-error"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Adresse"
                          : window.location.pathname.includes("/it")
                          ? "Indirizzo"
                          : window.location.pathname.includes("/en")
                          ? "Address"
                          : "Adresse"
                      }
                      variant="outlined"
                    />
                  )}
                  {this.state.address2 && this.state.address2.length > 0 ? (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address2"
                      id="outlined-basic"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Complément d'adresse"
                          : window.location.pathname.includes("/it")
                          ? "Supplemento"
                          : window.location.pathname.includes("/en")
                          ? "Additional address details"
                          : "Adresszusatz"
                      }
                      value={this.state.address2}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      className="editFields100"
                      onChange={this.onChangeHandler}
                      name="address2"
                      id="outlined-basic"
                      label={
                        window.location.pathname.includes("/fr")
                          ? "Complément d'adresse"
                          : window.location.pathname.includes("/it")
                          ? "Supplemento"
                          : window.location.pathname.includes("/en")
                          ? "Additional address details"
                          : "Adresszusatz"
                      }
                      variant="outlined"
                    />
                  )}
                  <TextField
                    className="editFields30"
                    onChange={this.onChangeHandler}
                    name="zip"
                    error={this.state.zip ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "NPA"
                        : window.location.pathname.includes("/it")
                        ? "NPA "
                        : window.location.pathname.includes("/en")
                        ? "Postcode"
                        : "PLZ"
                    }
                    value={this.state.zip}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields50"
                    onChange={this.onChangeHandler}
                    name="place"
                    error={this.state.place ? false : true && this.state.markErrorFields}
                    required
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Lieu"
                        : window.location.pathname.includes("/it")
                        ? "Località"
                        : window.location.pathname.includes("/en")
                        ? "Town"
                        : "Ort"
                    }
                    value={this.state.place}
                    variant="outlined"
                  />
                  <TextField
                    className="editFields20"
                    onChange={this.onChangeHandler}
                    name="country"
                    error={this.state.country ? false : true && this.state.markErrorFields}
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="outlined-basic-error"
                    label={
                      window.location.pathname.includes("/fr")
                        ? "Pays"
                        : window.location.pathname.includes("/it")
                        ? "Paese "
                        : window.location.pathname.includes("/en")
                        ? "Country"
                        : "Land"
                    }
                    value={this.state.country}
                    variant="outlined"
                  >
                    {countries.map((country) => (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                  <Container className="contestTerms">
                    <Container disableGutters>
                      <Typography align="center">
                        <p>&nbsp;</p>
                      </Typography>
                    </Container>
                    <Container disableGutters>
                      {window.location.pathname.includes("/fr") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          J'accepte les{" "}
                          <Link
                            to="/fr/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            conditions de participation au tirage au sort.
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : window.location.pathname.includes("/it") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          Accetto le{" "}
                          <Link
                            to="/it/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            condizioni del concorso.
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : window.location.pathname.includes("/en") ? (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          I accept{" "}
                          <Link
                            to="/en/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            terms and conditions
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      ) : (
                        <Typography
                          component="div"
                          className={`notificationTogglers termsAcceptInner ${
                            !this.state.agreeTerms && this.state.markErrorFields && "notificationTogglersError"
                          }`}
                        >
                          Ich akzeptiere die{" "}
                          <Link
                            to="/de/terms"
                            target="_blank"
                            disableElevation
                            size="large"
                            fullWidth
                            className="contestLink policyButton"
                          >
                            Wettbewerbsbedingungen
                          </Link>
                          <IOSSwitch
                            checked={this.state.agreeTerms}
                            onChange={this.handleAgree}
                            name="emailNotifications"
                            inputProps={{ "aria-label": "email notifications" }}
                          />
                        </Typography>
                      )}
                    </Container>
                  </Container>
                  <div className="recaptcha-wrapper">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                      onChange={(token) => this.setState({ recaptchaToken: token })}
                      hl={this.getCurrentLanguage()}
                      className={`recaptcha ${
                        this.state.markErrorFields && !this.state.recaptchaToken && "recaptcha-error"
                      }`}
                    />
                  </div>
                </Container>
              </Toolbar>
            </Container>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ContestForm);
